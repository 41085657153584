import React, {useState} from "react";
import {apiSendRecoveryEmail} from "../../../services/Auth/login"
import MsgError from "../../../components/MsgError";
import {TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {LoadingButton} from "@mui/lab";
import MsgSuccess from "../../../components/MsgSuccess";
import {RouteAuth} from "../../../routes/RouteAuth";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo from "../../../assets/images/logo-scaled.png";
import MenuLanguage from "../../../components/Layouts/Admin/MenuLanguage";

const theme = createTheme();

export default function ForgottenPasswordForm() {

    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(false)
    const {i18n, t} = useTranslation()
    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        setMsgSuccess([])
        setMsgError([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return false;
        }

        apiSendRecoveryEmail(state, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    return (
        <>
            <ThemeProvider theme={theme}>

                <div style={{minHeight: '100vh', alignItems: 'center'}}>
                    <Container component="main" maxWidth="xs" style={{
                        padding: '48px 40px 36px',
                        border: '1px solid #dadce0',
                        borderRadius: '8px',
                        marginTop: '27vh'
                    }}>
                        <CssBaseline/>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <img style={{width: 150}} src={logo}/>
                            <br/>
                            <Typography component="h1" variant="h5">
                                {t('PAGE_LOGIN.Recover password')}
                            </Typography>

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>

                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <MsgError msg={msgError}/>
                                        <MsgSuccess msg={msgSuccess}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            id="username"
                                            label={t('PAGE_LOGIN.Email Address')}
                                            value={state.username || ''}
                                            name="username"
                                            autoComplete="email"
                                            onChange={handleChange}
                                            autoFocus
                                            InputLabelProps={{shrink: true}}
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: color,
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: color,
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover fieldset': {
                                                        borderColor: color,
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: color,
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <LoadingButton
                                            type="submit"
                                            loading={loading}
                                            loadingPosition="start"
                                            variant="contained"
                                            fullWidth
                                            sx={{mt: 2, mb: 2}}
                                        >
                                            {t('PAGE_LOGIN.Send email')}
                                        </LoadingButton>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Link
                                            to={RouteAuth.LOGIN.path}
                                            style={{
                                                textDecoration: 'none',
                                                fontFamily: 'AdminFontBold',
                                                fontSize: '14px',
                                                color: color,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                display: 'table',
                                            }}
                                        >
                                            {t('general.Go back')}
                                        </Link>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </div>

                <div style={{
                    color: "gray",
                    position: 'absolute',
                    bottom: 20,
                    left: 20,
                    fontSize: 15,
                }}><MenuLanguage showLang={true} /></div>
            </ThemeProvider>
        </>
    )
}

function initialValues() {
    return {
        username: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.username) {
        error.push("El correo electrónico es obligatorio")
    }

    return error
}
