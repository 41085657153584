import Login from "../pages/Auth/Login"
import Register from "../pages/Auth/Register"
import ForgottenPassword from "../pages/Auth/ForgottenPassword"
import RecoverPassword from "../pages/Auth/RecoverPassword"
import Maintenance from '../pages/Auth/Maintenance'
import Logout from "../pages/Auth/Logout";
import LoginThanks from "../pages/Auth/Register/thanks";

const baseTitle = "Rosinox - "

export const RouteAuth = {
    LOGIN: {
        path: "/login",
        page: Login,
        title: `${baseTitle}Login`,
        roles: ["ROLE_PUBLIC"]
    },
    LOGIN_THANKS: {
        path: "/login-thanks",
        page: LoginThanks,
        title: `${baseTitle}Login thanks`,
        roles: ["ROLE_PUBLIC"]
    },
    LOGOUT: {
        path: "/logout",
        page: Logout,
        title: `${baseTitle} Cerrar sesión`,
    },
    SIGNUP: {
        path: "/register",
        page: Register,
        title: `${baseTitle}Register`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD: {
        path: "/recover-password",
        page: ForgottenPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD_TOKEN: {
        path: "/change-password",
        params: "/:token",
        page: RecoverPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    },
    MAINTENANCE: {
        path: "/maintenance",
        page: Maintenance,
        title: `${baseTitle}Maintenance`,
        roles: ["ROLE_PUBLIC"]
    }
}
