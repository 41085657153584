import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import InputButton from "../../../../../components/Inputs/InputButton";
import {VscChromeClose} from "react-icons/vsc";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {apiCreate} from "../../../../../services/User/transaction";
import MsgSuccess from "../../../../../components/MsgSuccess";
import MsgError from "../../../../../components/MsgError";
import KitchenModal from "../KitchenModal";
import Formulario from "./Formulario";
import ShowBntPdf from "./ShowBntPdf";
import InputButtonLoading from "../../../../../components/Inputs/InputButtonLoading";
import LoadingSpinner from "../../../../../components/LoadingSpinner";

export default function ModalQuotation(props) {
    const {
        open,
        setOpen,
        kitchenElements,
        setKitchenElements,
        kitchenElementsSecondarySide,
        setKitchenElementsSecondarySide,
        side,
        catalogueProducts,
        customProducts,
        totalIncomplete,
        incompleteLoading,
        setShowMenu
    } = props

    const {i18n, t} = useTranslation()
    const [state, setState] = React.useState(initialState())
    const [loading, setLoading] = React.useState(false)
    const [msgError, setMsgError] = React.useState([])
    const [msgSuccess, setMsgSuccess] = React.useState([])
    const [showForm, setShowForm] = React.useState(true)
    const [urlPdf, setUrlPdf] = React.useState(null)
    const [urlExcel, setUrlExcel] = React.useState(null)
    const [modalKitchen, setModalKitchen] = React.useState(false)
    const [transactionCode, setTransactionCode] = React.useState(null)

    const handleClose = () => {
        setOpen(false)
        setShowForm(true)
        setModalKitchen(false)
        setMsgSuccess([])
        setMsgError([])
    }

    const closeModalKitchen = () => {
        setOpen(true)
        setModalKitchen(false)
    }

    const handleSubmit = (urlSlide1, urlSlide2) => {
        setMsgError([])
        setMsgSuccess([])
        setModalKitchen(false)
        setLoading(true)
        setOpen(true)
        setUrlPdf(null)
        setUrlExcel(null)
        setTransactionCode(null)
        setShowForm(false)

        const data = {
            ...state,
            discountRosinox: state.discountRosinox ? parseFloat(state.discountRosinox) : null,
            discountFriginox: state.discountFriginox ? parseFloat(state.discountFriginox) : null,
            kitchenImageSide1: urlSlide1,
            kitchenImageSide2: urlSlide2,
            kitchenElements: kitchenElements,
            kitchenElementsSecondarySide: kitchenElementsSecondarySide,
            catalogueProducts: catalogueProducts,
            customProducts: customProducts
        }
        apiCreate(data, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setUrlPdf(resp.data?.urlPdf)
            setUrlExcel(resp.data?.urlExcel)
            setTransactionCode(resp.data?.transactionCode)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            setShowForm(true)
        })
    }

    const openFrom = () => {
        setShowForm(true)
        setMsgError([])
        setMsgSuccess([])
    }

    const checkKitchen = () => {
        setMsgError([])
        let error = validationSchema(state, t)

        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        setModalKitchen(true)
        setOpen(false)
    }

    const handleClickIncompatibility = () => {
        setOpen(false)
        setShowForm(true)
        setShowMenu("INCOMPATIBILITY")
    }

    return (
        <div>
            <Modal
                open={open}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter: ' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={1} style={{margin: 0}}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>{t('quotation.Generate quotation')}</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>

                        {totalIncomplete > 0 && (
                            <>
                                <Grid item md={12}>
                                    <MsgError msg={[t('composer.Please correct the incompatibilities')]}/>
                                </Grid>

                                <Grid item md={6}>
                                    <InputButton
                                        style={{width: '100%'}}
                                        text={t('general.Cancel')}
                                        onClick={handleClose}
                                        color="secondary"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item md={6}>
                                    <InputButton
                                        text={t('composer.Show incompatibilities')}
                                        onClick={handleClickIncompatibility}
                                        style={{width: '100%'}}
                                    />
                                </Grid>
                            </>
                        )}



                        {incompleteLoading > 0 && (
                            <Grid item md={12}>
                                <LoadingSpinner show={incompleteLoading}/>
                            </Grid>
                        )}

                        {(!incompleteLoading && totalIncomplete === 0) && (
                            <>
                                <Grid item xs={12} md={12} lg={12}>
                                    {showForm && (
                                        <Formulario
                                            state={state}
                                            setState={setState}
                                            loading={loading}
                                        />
                                    )}
                                </Grid>

                                {!showForm && (
                                    <ShowBntPdf
                                        urlPdf={urlPdf}
                                        urlExcel={urlExcel}
                                        loading={loading}
                                        transactionCode={transactionCode}
                                    />
                                )}

                                <Grid item xs={12} md={12} lg={12}>
                                    <MsgError msg={msgError}/>
                                </Grid>

                                {showForm && (
                                    <>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <InputButton
                                                style={{width: '100%'}}
                                                text={t('general.Cancel')}
                                                onClick={handleClose}
                                                color="secondary"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <InputButtonLoading
                                                onClick={checkKitchen}
                                                loading={loading}
                                                disabled={totalIncomplete > 0 ? true : false}
                                                label={`${t('general.Continue')} →`}
                                                style={{minWidth: '100%'}}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </Box>
            </Modal>
            <KitchenModal
                handleClose={handleClose}
                open={modalKitchen}
                setOpen={setModalKitchen}
                kitchenElements={kitchenElements}
                kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                setKitchenElementsSecondarySide={setKitchenElementsSecondarySide}
                setKitchenElements={setKitchenElements}
                handleSubmit={handleSubmit}
                closeModalKitchen={closeModalKitchen}
                side={side}
            />
        </div>
    )
}

function initialState() {
    const dateNow = moment().format('X')
    return {
        serieId: 1,
        dateQuotation: dateNow,
        companyName: "",
        clientContact: "",
        clientAddress: "",
        clientLocation: "",
        name: "",
        text: "",
        gasType: "",
        discountRosinox: "",
        discountRosinoxType: "%",
        discountFriginox: "",
        discountFirginoxType: "%",
        vatActive: true,
    }
}

function validationSchema(schema, t) {

    let error = []

    if (!schema.dateQuotation) {
        error.push(t("QUOTATION_ERROR.dateQuotation is required"))
    }
    if (!schema.companyName) {
        error.push(t("QUOTATION_ERROR.companyName is required"))
    }
    if (!schema.clientContact) {
        error.push(t("QUOTATION_ERROR.clientContact is required"))
    }
    if (!schema.clientAddress) {
        error.push(t("QUOTATION_ERROR.clientAddress is required"))
    }
    if (!schema.clientLocation) {
        error.push(t("QUOTATION_ERROR.clientLocation is required"))
    }
    if (!schema.name) {
        error.push(t("QUOTATION_ERROR.name is required"))
    }
    if (!schema.text) {
        error.push(t("QUOTATION_ERROR.text is required"))
    }
    if (!schema.gasType) {
        error.push(t("QUOTATION_ERROR.gasType is required"))
    }
    if (schema.discountRosinox > 100) {
        error.push(t("QUOTATION_ERROR.discountRosinox is greater than 100"))
    }
    return error
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
