import React, { useEffect } from 'react';

export default function Cronometro(props) {

    const {time, setTime} = props

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(time => time > 0 ? time - 1 : 0);
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    }

    return (
        <div>
            <p style={{textAlign: 'center', fontSize: 20}}>{formatTime(time)}</p>
        </div>
    )
}
