import React, {useEffect} from "react";
import {Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

/**
 * Muestra un selector
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **options** *object*
 * - **onChange** *function*
 * - **multiple** *bool*
 * - **disabled** *bool*
 *
 * ## EJ
 * options: [{id: 1, name: "Aaaa"}, {id: 2, name: "Bbbbb"}]
 */
export default (props) => {
    const {
        label,
        name,
        value,
        options,
        onChange,
        required,
        multiple,
        disabled,
        className,
        style,
        selectText,
        onlyOptions
    } = props

    const {t} = useTranslation()

    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    const text = selectText ? selectText : t('Selecciona un elemento')
    const onlyOptionsIn = onlyOptions ? onlyOptions : false
    const disabledIn = disabled ? disabled : false

    const showName = (id) => {
        const option = options.find(option => option.id === id)
        return option ? option.name : ''
    }

    return (
        <>
            {!multiple && (
                <FormControl
                    fullWidth
                    size={"small"}
                    required={required}
                    sx={{
                        background: disabledIn ? '#f5f5f5' : 'white',
                        borderRadius: '5px',
                        '& label.Mui-focused': {
                            color: disabledIn ? 'none' : color,
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: disabledIn ? 'none' : color,
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: disabledIn ? 'none' : color,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: disabledIn ? 'none' : color,
                            },
                        },
                    }}
                >


                    <InputLabel shrink>{label}</InputLabel>
                    <Select
                        input={<OutlinedInput notched label={label} />}
                        value={value}
                        name={name}
                        required={required}
                        size="small"
                        disabled={disabledIn}
                        displayEmpty={value !== null && value !== ""}
                        multiple={false}
                        onChange={onChange}
                        className={className}
                        InputLabelProps={{ shrink: true }}
                        style={{ ...style }}
                        SelectDisplayProps={{ style: { padding: '7px 12px', fontSize: 14 } }}
                    >
                        <MenuItem value="" style={{display: onlyOptions ? 'none' : 'block'}}>
                            <span style={{color: "#828384", fontSize: 14}}>{text}</span>
                        </MenuItem>
                        {options.map((option, key) => {
                            return (
                                <MenuItem
                                    key={key}
                                    value={option.id}
                                    style={{fontSize: 14}}
                                >
                                    {option.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

            )}

            {multiple && (

                <FormControl
                    fullWidth
                    size={"small"}
                    required={required}
                    className="admin-input-select"
                    sx={{
                        background: disabledIn ? '#f5f5f5' : 'white',
                        borderRadius: '5px',
                        '& label.Mui-focused': {
                            color: disabledIn ? 'none' : color,
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: disabledIn ? 'none' : color,
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: disabledIn ? 'none' : color,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: disabledIn ? 'none' : color,
                            },
                        },
                    }}
                >
                    <InputLabel shrink>{label}</InputLabel>
                    <Select
                        input={<OutlinedInput notched label={label}/>}
                        name={name}
                        size="small"
                        disabled={disabledIn}
                        multiple={true}
                        labelId="select-label"
                        value={value}
                        onChange={onChange}
                        label={required ? `${label} * ` : {label}}
                        displayEmpty={value !== null && value !== ""}
                        input={<OutlinedInput label="Chip"/>}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((id) => (
                                    <Chip
                                        key={id} l
                                        label={showName(id)}
                                        style={{fontSize: 12}}
                                        size={"small"}
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        InputLabelProps={{ shrink: true }}
                        style={{ ...style }}
                        SelectDisplayProps={{ style: { padding: '7px 12px', fontSize: 14 } }}
                    >
                        {options.map((option, key) => {
                            return (
                                <MenuItem
                                    key={key}
                                    value={option.id}
                                    style={{fontSize: 14}}
                                >
                                    <Checkbox checked={value.includes(option.id)}/>
                                    <ListItemText primary={option.name}/>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            )}
        </>
    )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
