import * as React from "react";
import Typography from "@mui/material/Typography";
import {VscSymbolMisc} from "react-icons/vsc";
import {useTranslation} from "react-i18next";

export default function NoData(props) {

    const {show} = props
    const {t} = useTranslation()

    return (
        <>
            {show && (
                <>
                    <Typography variant="h6" component="div"
                                sx={{pt: 3, textAlign: 'center', color: 'rgba(0, 0, 0, 0.26)'}}>
                        <VscSymbolMisc size={70}/>
                    </Typography>

                    <Typography variant="h6" component="div" sx={{textAlign: 'center', color: 'rgba(0, 0, 0, 0.26)', textTransform: 'uppercase'}}>
                        {t('general.No data')}
                    </Typography>
                </>
            )}
        </>
    )
}
