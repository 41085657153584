import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import moment from "moment/moment";

export default (props) => {

    const {
        label,
        name,
        onChange,
        defaultValue
    } = props

    const [selectedOptions, setSelectedOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const [trimmedValue, setTrimmedValue] = useState('')

    useEffect(() => {
        setSelectedOptions(defaultValue)
        setOptions(defaultValue)
    }, [defaultValue])

    const handleSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
        setTrimmedValue('')
    }

    useEffect(() => {
        onChange({target: {name: name, value: selectedOptions}})
    }, [selectedOptions])

    const handleInputChange = (inputValue, { action }) => {
        let value = inputValue
        setTrimmedValue(value)

        // Eliminamos trimmedValue de las opciones
        const newOptions = options.filter(option => option.value !== trimmedValue)

        if (value) {
            setOptions([{value: value, label: value}, ...newOptions])
        }

        if (action === 'menu-close' && value.trim() !== '') {
        } else {
            setInputValue(inputValue)
        }
    };

    return (
        <>
            <label htmlFor="custom-selector">{label}</label>
            <Select
                options={options}
                isMulti
                value={selectedOptions}
                inputValue={inputValue}
                onChange={handleSelectChange}
                isCreatable
                onInputChange={handleInputChange}
            />
        </>

    )
}
