import * as React from 'react';
import {useEffect, useState} from "react";
import {apiGetComposer, apiSave} from "../../../../services/User/composer";
import {useTranslation} from "react-i18next";
import Modal from "../../../../components/Modal"
import Grid from "@mui/material/Grid";
import pjson from "../../../../../package.json";
import {COMPOSER_MIN_VERSION} from "../../../../utils/constants";

export default function AutoSave(props) {
    const {
        loadingAutoSave,
        setLoadingAutoSave,

        setResetView,
        serie,

        kitchenElements,
        setKitchenElements,

        kitchenElementsSecondarySide,
        setKitchenElementsSecondarySide,

        catalogueProducts,
        setCatalogueProducts,

        customProducts,
        setCustomProducts,

        side,
        setSide
    } = props

    const {t, i18n} = useTranslation()

    const [openModal, setOpenModal] = useState(false)
    const [activeAutoSave, setActiveAutoSave] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getComposer()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setLoadingAutoSave(true)
        }, 10000)
    }, [kitchenElements, kitchenElementsSecondarySide, catalogueProducts, customProducts, side])

    useEffect(() => {
        if (loadingAutoSave) {
            save()
        }
    }, [loadingAutoSave])

    const save = () => {
        if (activeAutoSave) {

            // Eliminamos el viewHtml de kitchenElements
            let kitchenElementsAux = kitchenElements.map((element) => {
                const { viewHtml, selected, ...rest } = element
                return rest
            })

            // Eliminamos el viewHtml de kitchenElementsSecondarySide
            let kitchenElementsSecondarySideAux = kitchenElementsSecondarySide.map((element) => {
                const { viewHtml, selected, ...rest } = element
                return rest
            })

            apiSave({
                kitchenElements: kitchenElementsAux,
                kitchenElementsSecondarySide: kitchenElementsSecondarySideAux,
                catalogueProducts,
                customProducts,
                side,
                versionComposer: pjson.version,
                serieId: serie
            }, i18n.resolvedLanguage)
                .then(resp => {
                    setLoadingAutoSave(false)
                }).catch(err => {
                setLoadingAutoSave(false)
            })
        } else {
            setLoadingAutoSave(false)
        }
    }

    const getComposer = () => {
        apiGetComposer(serie, i18n.resolvedLanguage).then(resp => {
            if (resp.data.side && resp.data.versionComposer >= COMPOSER_MIN_VERSION) {
                setOpenModal(true)
            }
        }) .catch(err => {
            setActiveAutoSave(true)
        })
    }

    const importComposer = () => {
        setLoading(true)
        apiGetComposer(serie, i18n.resolvedLanguage).then(resp => {
            setSide(resp.data.side)
            setKitchenElements(resp.data.kitchenElements)
            setKitchenElementsSecondarySide(resp.data.kitchenElementsSecondarySide)
            setCatalogueProducts(resp.data.catalogueProducts)
            setCustomProducts(resp.data.customProducts)
            setOpenModal(false)
            setActiveAutoSave(true)
            setLoading(false)

            setResetView(Math.random())
        })
    }

    return (
        <>
            <Modal
                title={t('composer.Restore previous kitchen')}
                btnOk={t('general.Yes')}
                btnCancel={t('general.No')}
                open={openModal}
                setOpen={setOpenModal}
                loading={loading}
                handleClick={importComposer}
                handleClose={() => setActiveAutoSave(true)}
            >
                <Grid item xs={12} md={12} lg={12}>
                    <p>{t('composer.Do you want to restore the previous kitchen?')}</p>
                </Grid>
            </Modal>


        </>
    )
}
