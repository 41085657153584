import * as React from 'react';
import ReactJson from "react-json-view";
import Header from "./Header";

export default function Debugger(props) {
    const {
        showMenu,
        kitchenElements,
        elementSelected,
        lastElementAdd,
        menuRightSelected,
        openModal,
        categoriesElementLower,
        state,
        elementLowerTemp,
        availableLength,
        accessories,
        kitchenElementsSecondarySide,
        catalogueProducts,
        customProducts
    } = props
    return (
        <div style={{maxWidth: 400}}>
            {showMenu === 'DEBUGGER' && (
                <>
                    <Header title="Debugger"/>

                    <div className="content">
                        <ReactJson
                            src={{
                                "elementSelected": elementSelected,
                                "lastElementAdd": lastElementAdd,
                                "availableLength": availableLength,
                                "menuRightSelected": menuRightSelected,
                                "openModal": openModal,
                                "categoriesElementLower": categoriesElementLower,
                                "elementLowerTemp": elementLowerTemp,
                                "state": state,
                                "catalogueProducts": catalogueProducts,
                                "customProducts": customProducts,
                                "accessories": accessories,
                            }}
                            collapsed={0}
                            indentWidth={4}
                            collapseStringsAfterLength={50}
                            displayDataTypes={false}
                            quotesOnKeys={false}
                            displayArrayKey={false}
                        />
                        <br/>
                        <ReactJson
                            src={kitchenElements}
                            collapsed={2}
                            indentWidth={4}
                            collapseStringsAfterLength={50}
                            displayDataTypes={false}
                            quotesOnKeys={false}
                            displayArrayKey={false}
                        />
                        <br/>
                        <ReactJson
                            src={kitchenElementsSecondarySide}
                            collapsed={2}
                            indentWidth={4}
                            collapseStringsAfterLength={50}
                            displayDataTypes={false}
                            quotesOnKeys={false}
                            displayArrayKey={false}
                        />

                    </div>
                </>
            )}
        </div>
    )
}
