import * as React from 'react';
import {Grid} from "@mui/material";

export default function Header(props) {
    const {
        title
    } = props

    return (

        <div className="header">
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <h5 className="header-title">{title}</h5>
                </Grid>
                <Grid item xs={4} style={{float: 'right'}}>
                    {props.children}
                </Grid>
            </Grid>
        </div>
    )
}
