import React, {useEffect, useState} from "react";
import {apiList} from "../../../../services/Admin/history";
import moment from "moment";
import {Grid, IconButton} from "@mui/material";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import {Link} from "react-router-dom";
import {HiOutlineCloudDownload} from "react-icons/hi";
import {useTranslation} from "react-i18next";

export default function History() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        apiList(params, i18n.resolvedLanguage).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    creationDate: moment.unix(doc.creationDate).format("D/M/Y H:mm:ss"),
                    isFinish: doc.isFinish ? "Yes" : "No",
                    numberOfErrors: doc.numberOfErrors ? doc.numberOfErrors : null,
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    return (
        <>
            <HeaderPage title="History" border={false}/>

            <MsgError msg={msgError}/>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        role: ""
    }
}

function tableColumns(handleChangeSelection, selection) {
    return [
        {
            field: "user_email",
            headerName: "USER",
            sortable: true,
            flex: 1
        }, {
            field: "fileOriginalName",
            headerName: "FILE NAME",
            sortable: true,
            flex: 1
        },{
            field: "creationDate",
            headerName: "CREATION DATE",
            sortable: true,
            flex: 1
        }, {
            field: "typeQuery",
            headerName: "TYPE",
            sortable: true,
            flex: 1
        }, {
            field: "isFinish",
            headerName: "IS FINISH",
            sortable: true,
            flex: 1
        }, {
            field: "numberOfErrors",
            headerName: "Number of errors",
            sortable: true,
            flex: 1
        }, {
            field: "url",
            headerName: "download",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <a href={`${params.url}`} target="_blank">
                            <IconButton size="small">
                                <HiOutlineCloudDownload fontSize="inherit"/>
                            </IconButton>
                        </a>
                    </>
                )
            }
        }
    ]
}

