import {Grid} from "@mui/material";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import {BsFileEarmarkSpreadsheet, BsFileEarmarkText} from "react-icons/bs";
import React from "react";
import downloaderFile from "../../../../../utils/downloaderFile";
import {useTranslation} from "react-i18next";

export default function ShowBntPdf(props) {

    const {urlPdf, urlExcel, loading, transactionCode} = props

    const {t} = useTranslation()

    const handleClick = (type) => {
        if (type === "pdf") {
            downloaderFile(urlPdf, `${transactionCode}.pdf`)

        } else if (type === "excel") {
            downloaderFile(urlExcel, `${transactionCode}.xlsx`)
        }
    }

    return (
        <>
            <Grid item md={12}>
                <LoadingSpinner show={loading}/>
            </Grid>

            {urlPdf && (
                <Grid item md={urlExcel ? 6 : 12}>
                    <div style={{cursor: 'pointer'}} onClick={() => handleClick('pdf')}>
                        <BsFileEarmarkText
                            size={70}
                            style={{margin: 'auto', display: 'block'}}
                        />
                        <p style={{textAlign: 'center'}}>{t('general.Download')} PDF</p>
                    </div>
                </Grid>
            )}

            {urlExcel && (
                <Grid item md={urlPdf ? 6 : 12}>
                    <div style={{cursor: 'pointer'}} onClick={() => handleClick('excel')}>
                        <BsFileEarmarkSpreadsheet
                            size={70}
                            style={{margin: 'auto', display: 'block'}}
                        />
                        <p style={{textAlign: 'center'}}>{t('general.Download')} EXCEL</p>
                    </div>
                </Grid>
            )}

            <Grid item md={12}>
                <div style={{height: 10}}></div>
            </Grid>
        </>
    )
}
