import React from "react";
import '../../../scss/Composer/element-width.css';
import width200 from "../../../assets/images/width-200.png";
import width400 from "../../../assets/images/width-400.png";
import width800 from "../../../assets/images/width-800.png";
import width600 from "../../../assets/images/width-600.png";
import width1000 from "../../../assets/images/width-1000.png";

export default function View(props) {

    const {
        element,
        elementSelected,
        setElementSelected,
        onChangeCode
    } = props

    const colorSelect = '#ea0c1f'
    const color = '#474747'

    const handleClick = (id) => {
        if (elementSelected === id) {
            setElementSelected(null)
        } else {
            setElementSelected(id)
        }
    }


    return (
        <div onClick={() => handleClick(element?.id)} style={{marginRight: '-2px'}}>

            {/* UPPER */}
            <div>
                {element?.upper.map((upper, key) => {
                    return (
                        <img
                            key={key}
                            className={`kitchen-${upper?.width}`}
                            src={upper?.imgUpper2d}
                            alt=""
                        />
                    )
                })}
            </div>
            {/* MIDDLE */}
            <div>
                {element?.upper.map((middle, key) => {
                    return (
                        <img
                            key={key}
                            className={`kitchen-${middle?.width}`}
                            src={middle?.imgMiddle2d}
                            alt=""
                        />
                    )
                })}
            </div>
            {/* LOWER */}
            <div style={{marginTop: '-4px'}}>

                {element?.lower.length === 0 && (
                    <>
                        {element?.upper.map((lower, key) => {

                            let img = ''
                            if (lower.width === 200) {
                                img = width200
                            } else if (lower.width === 400) {
                                img = width400
                            } else if (lower.width === 800) {
                                img = width800
                            } else if (lower.width === 600) {
                                img = width600
                            } else if (lower.width === 1000) {
                                img = width1000
                            }

                            return (
                                <img
                                    key={key}
                                    className={`kitchen-${lower?.width}`}
                                    src={img}
                                    alt=""
                                />
                            )
                        })}
                    </>
                )}

                {element?.lower.map((lower, key) => {
                    return (
                        <img
                            key={key}
                            className={`kitchen-${lower?.width}`}
                            src={lower?.imgLower2d}
                            alt=""
                        />
                    )
                })}
            </div>

            {/* WIDTH */}
            <div style={{paddingLeft: 2, paddingRight: 2, marginTop: 10}}>
                <div style={{
                    borderBottom: `1px dashed ${element.selected ? colorSelect : color}`,
                    borderLeft: `1px solid ${element.selected ? colorSelect : color}`,
                    borderRight: `1px solid ${element.selected ? colorSelect : color}`,
                    height: 4
                }}></div>
            </div>
            {element?.totalWidth !== 100 && (
                <>
                    <div style={{color: `${element.selected ? colorSelect : color}`, textAlign: 'center', fontFamily: 'verdana', fontSize: 14, paddingTop: 10}}>
                        {element?.totalWidth}
                    </div>

                    <input
                        type="text"
                        value={element?.productCodeNew}
                        onChange={(e) => {
                            onChangeCode(element?.id, `${e.target.value}`)
                        }}
                        className={`kitchen-input-${element?.totalWidth}`}
                        style={{
                            marginTop: 10,
                            border: `1px solid #b6b6b6`,
                            borderRadius: 5,
                            color: '#b6b6b6',
                            textAlign: 'center',
                            fontFamily: 'verdana',
                            fontSize: 10,
                            padding: 5,
                        }}
                    />
                </>
            )}

        </div>
    )
}
