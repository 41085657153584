import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {apiList as apiListCategory} from "../../../services/User/category";
import InputSelect from "../../../components/Inputs/InputSelect";
import InputText from "../../../components/Inputs/InputText";
import InputButton from "../../../components/Inputs/InputButton";
import {optionsType} from "../../../utils/optionsSelectors/optionsType";
import {optionsCompany} from "../../../utils/optionsSelectors/optionsCompany";
import {optionsWidth} from "../../../utils/optionsSelectors/optionsWidth";
import {apiGetSerie} from "../../../services/User/serie";

export default function Filters(props) {

    const {
        state,
        setState,
        products,
        btnShowCart,
        setBtnShowCart
    } = props

    const [optionsCategory, setOptionsCategory] = React.useState([])
    const {i18n} = useTranslation()

    useEffect(() => {
        setOptionsCategory([])
        getCategoryList()
        setState({...state, category: null})
    }, [state.type])

    useEffect(() => {
        if (state.typeQuery === 'products') {
            setState({...state, offset: 0, productCode: '', description: ''})
        } else {
            setState({...state, offset: 0, optionsCode: '', description: ''})
        }
    }, [state.typeQuery])

    const optionsQuery = [
        {id: "products", name: "Products"},
        {id: "options", name: "Options"}
    ]

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const getCategoryList = () => {
        apiListCategory({
            type: state.type
        }, i18n.resolvedLanguage).then(resp => {
            let temp = resp.data?.map(doc => {
                return {...doc}
            })
            setOptionsCategory(temp)
        }).catch(err => {
            setOptionsCategory([])
        })
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>

            {(state.typeQuery === 'products' && !btnShowCart) && (
                <FilterProduct
                    handleChange={handleChange}
                    state={state}

                    optionsCategory={optionsCategory}
                    optionsQuery={optionsQuery}

                    products={products}
                    btnShowCart={btnShowCart}
                    setBtnShowCart={setBtnShowCart}
                />
            )}
            {(state.typeQuery === 'options' && !btnShowCart) && (
                <FilterOptions
                    handleChange={handleChange}
                    state={state}

                    optionsCategory={optionsCategory}
                    optionsQuery={optionsQuery}

                    products={products}
                    btnShowCart={btnShowCart}
                    setBtnShowCart={setBtnShowCart}
                />
            )}

        </Grid>
    )
}

function FilterProduct(props) {

    const {
        state,
        handleChange,
        optionsCategory,
        optionsQuery,
    } = props

    const {t, i18n} = useTranslation()
    const [optionsSeries, setOptionsSeries] = React.useState([])

    useEffect(() => {
        apiGetSerie(i18n.resolvedLanguage).then(resp => {
            let temp = resp.data?.map(doc => {
                return {
                    id: doc.id,
                    name: doc.name
                }
            })
            setOptionsSeries(temp)
        }).catch(err => {
            setOptionsSeries([])
        })
    },[])

    return (
        <>
            <Grid item xs={3}>
                <InputSelect
                    label={t('catalogue.Company')}
                    name="company"
                    options={optionsCompany}
                    onChange={handleChange}
                    value={state.company}
                />
            </Grid>

            <Grid item xs={3}>
                <InputSelect
                    label={t('general.Serie')}
                    name="serie"
                    options={optionsSeries}
                    onChange={handleChange}
                    value={state.serie}
                />
            </Grid>

            <Grid item xs={3}>
                <InputSelect
                    label={t('catalogue.Type')}
                    name="type"
                    onChange={handleChange}
                    value={state?.type}
                    options={optionsType}
                />
            </Grid>

            <Grid item xs={3}>
                <InputSelect
                    label={t('catalogue.Category')}
                    name="category"
                    disabled={state.type === ""}
                    onChange={handleChange}
                    value={state?.category}
                    options={optionsCategory}
                />
            </Grid>

            <Grid item xs={3}>
                <InputSelect
                    label={t('catalogue.Width')}
                    name="width"
                    options={optionsWidth}
                    onChange={handleChange}
                    multiple={true}
                    value={state.width}
                />
            </Grid>

            <Grid item xs={3}>
                <InputText
                    label={t('catalogue.Product Code')}
                    name="productCode"
                    onChange={handleChange}
                    value={state.productCode}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    label={t('catalogue.Description')}
                    name="description"
                    onChange={handleChange}
                    value={state.description}
                />
            </Grid>
        </>
    )
}

function FilterOptions(props) {

    const {
        state,
        handleChange,
    } = props
    const {t} = useTranslation()

    return (
        <>
            <Grid item xs={6}>
                <InputText
                    label={t('catalogue.Optional Code')}
                    name="optionsCode"
                    onChange={handleChange}
                    value={state.optionsCode}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    label={t('catalogue.Product Code')}
                    name="productCode"
                    onChange={handleChange}
                    value={state.productCode}
                />
            </Grid>

            <Grid item xs={12}>
                <InputText
                    label={t('catalogue.Description')}
                    name="description"
                    onChange={handleChange}
                    value={state.description}
                />
            </Grid>

        </>
    )
}
