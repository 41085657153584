import React, {useEffect, useState} from "react";
import {apiList} from "../../../../services/Admin/transaction";
import {Grid, IconButton, Tooltip} from "@mui/material";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputText from "../../../../components/Inputs/InputText";
import {useTranslation} from "react-i18next";
import InputSelect from "../../../../components/Inputs/InputSelect";
import DatePickerSimple from "../../../../components/Inputs/DatePickerSimple";
import moment from "moment";
import {BsFileEarmarkSpreadsheet, BsFileEarmarkText} from "react-icons/bs";
import currencyFormatterPrice from "../../../../utils/currencyFormatterPrice";
import {apiGetUrlTransaction} from "../../../../services/User/transaction";
import downloaderFile from "../../../../utils/downloaderFile";
import {optionsSerie} from "../../../../utils/optionsSelectors/optionsSerie";

export default function Management() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.code) { params.code = state.code }
        if (state.serie) { params.serie = state.serie }
        if (state.dateFrom) { params.dateFrom = state.dateFrom }
        if (state.dateTo) { params.dateTo = state.dateTo }
        if (state.email) { params.email = state.email }

        apiList(params, i18n.resolvedLanguage).then(resp => {
            setRowCount(resp.data?.totalItems)

            let temp = resp.data.items?.map(doc => {
                return {
                    ...doc,
                    dateQuotation: moment.unix(doc.dateQuotation).format("D/M/Y"),
                    price: currencyFormatterPrice(doc.price) + ' €',
                    discountRosinox: doc.discountRosinox ? doc.discountRosinox  + ' %' : '-',
                    discountFriginox: doc.discountFriginox ? doc.discountFriginox  + ' %' : '-',
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
            setRowCount(0)
        })
    }

    const getUrlTransaction = (id, type) => {
        apiGetUrlTransaction(id, type).then(resp => {
            downloaderFile(resp.data?.url, resp.data?.name)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <>
            <HeaderPage title="Quotation" />

            <Grid container spacing={2}>

                <Filters
                    state={state}
                    setState={setState}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns(getUrlTransaction)}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "code", sort: "desc" },
        code: "",
        serie: "",
        dateFrom: "",
        dateTo: "",
        email: "",
    }
}

function tableColumns(getUrlTransaction) {
    return [
        {
            field: "code",
            headerName: "Quotation Code",
            sortable: true,
            flex: 1
        },
        {
            field: "email",
            headerName: "user mail",
            sortable: true,
            flex: 1
        },
        {
            field: "text",
            headerName: "Quotation Name",
            sortable: true,
            flex: 1
        },
        {
            field: "companyName",
            headerName: "Company Name",
            sortable: true,
            flex: 1
        },
        {
            field: "dateQuotation",
            headerName: "Date",
            sortable: true,
            flex: 1
        },
        {
            field: "price",
            headerName: "Total Public Price",
            sortable: true,
            flex: 1
        },
        {
            field: "discountRosinox",
            headerName: "Discount Rosinox",
            sortable: true,
            flex: 1
        },
        {
            field: "discountFriginox",
            headerName: "Discount Friginox",
            sortable: true,
            flex: 1
        },
        // {
        //     field: "price",
        //     headerName: "Total Général H.T.",
        //     sortable: true,
        //     flex: 1
        // },
        {
            field: "actions",
            headerName: "Actions",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params?.filePdf && (
                            <Tooltip title="PDF" arrow>
                                <IconButton size="small" onClick={() => getUrlTransaction(params?.id, 'pdf')}>
                                    <BsFileEarmarkText fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        )}

                        {params?.fileExcel && (
                            <Tooltip title="Excel" arrow>
                                <IconButton size="small" onClick={() => getUrlTransaction(params?.id, 'xlsx')}>
                                    <BsFileEarmarkSpreadsheet fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <>
            <Grid item xs={4}>
                <InputText
                    label="Code"
                    name="code"
                    value={state.code}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputSelect
                    label="Serie"
                    name="serie"
                    onChange={handleChange}
                    value={state?.serie}
                    options={optionsSerie}
                />
            </Grid>

            <Grid item xs={4}>
                <InputText
                    label="User mail"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <DatePickerSimple
                    label="Date From"
                    name="dateFrom"
                    value={state?.dateFrom}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <DatePickerSimple
                    label="Date To"
                    name="dateTo"
                    value={state?.dateTo}
                    onChange={handleChange}
                />
            </Grid>

        </>
    )
}
