import * as React from 'react';
import {apiList} from "../../../../services/User/product";
import {useEffect, useRef, useState} from "react";
import LoadingBar from "../../../../components/LoadingBar";
import ViewProduct from "../ViewProduct";
import BoxFilter from "./BoxFilter";
import {useTranslation} from "react-i18next";
import SwiperCore, {Navigation, Pagination, FreeMode, Scrollbar, Mousewheel} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {VscError} from "react-icons/vsc";

SwiperCore.use([Mousewheel])

export default function BoxProduct(props) {

    const {
        state,
        setState,
        addKitchenElementUpper,
        addKitchenElementLower,
        elementSelected,
        kitchenElements,
        loadingProducts,
        availableLength
    } = props

    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingIn, setLoadingIn] = useState(true)
    const [clientWidth, setClientWidth] = useState(0)

    const {t, i18n} = useTranslation()
    const divRef = useRef(null)
    const [swiper, setSwiper] = useState(null)
    const swiperRef = useRef(null);

    useEffect(() => {
        // Detectar cambio de tamaño del div
        const div = divRef.current
        const resizeObserver = new ResizeObserver(entries => {
            setClientWidth(entries[0].contentRect.width)
        })

        resizeObserver.observe(div)

        return () => {
            resizeObserver.unobserve(div)
        }
    }, [])

    useEffect(() => {
        let load = false
        if (loadingProducts) {
            // Esperamos a que el producto se haya añadido a la cocina
            load = true
        }
        if (loading) {
            load = true
        }
        setLoadingIn(load)
    }, [loading, loadingProducts])

    useEffect(() => {
        setLoading(true)
        setMsgError([])
        setRows([])
        const timeOutId = setTimeout(() => {
            if (!elementSelected) {
                getItemsData()
            } else {
                // En caso de tener elemento selecionado comprobamos si la parte inferior (lower) tiene elementos
                let product = kitchenElements.filter((item) => item.id === elementSelected)
                if (product[0]?.lower.length > 0) {
                    setLoading(false)
                    setMsgError([t('composer.The module is complete')])
                } else if (state.type.includes(2)) {
                    getItemsData()
                } else {}
            }
        }, 1000)
        return () => clearTimeout(timeOutId)
    }, [state, elementSelected, availableLength])

    const getItemsData = () => {
        setLoading(true)
        setMsgError([])

        let params = {}

        if (state?.type) {
            params.type = state.type
        }
        if (state?.width) {
            params.width = state.width
        }
        if (state?.serie) {
            params.serie = state.serie
        }
        if (state?.category) {
            params.category = state.category
        }

        if (params.type?.includes(2)) {
            // Si es de tipo Lower quitamos el selector de categoría
            params.category = null
        }
        if (params.category === 'none') {
            params.category = null
        }
        params.textOnlyLocale = true
        // Obtenemos el código de producto de la parte superior (upper) del elemento seleccionado
        let product = kitchenElements.filter((item) => item.id === elementSelected)
        if (product[0]?.upper.length > 0) {
            params.productUpperCode = product[0].upper[0].productCode
        }
        params.length = availableLength

        params.isActive = 1

        params.composer = true

        if (state.code) {
            params.code = state.code
        }

        apiList(params, "get", i18n.resolvedLanguage).then(resp => {
            let temp = []
            resp.data.items?.forEach(doc => {

                let img = null
                if (doc.type_id === 1) {
                    img = doc.imgUpper3d
                }
                if (doc.type_id === 2) {
                    img = doc.imgLower3d
                }
                if (doc.type_id === 4) {
                    img = doc.lower?.imgLower3d
                }
                if (doc.type_id === 5) {
                    img = doc?.imgCompact3d ?? null
                }

                let name = null
                if (doc.type_id === 1 || doc.type_id === 2 || doc.type_id === 5) {
                    name = doc.name
                }
                if (doc.type_id === 4) {
                    name = doc.lower?.name
                }

                // No mostrar en caso de que no exista imagen
                let show = img ? true : false

                if (show) {
                    temp.push({
                        ...doc,
                        img: img,
                        name: name,
                    })
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError([err.response?.data?.msg])
            setLoading(false)
            setRows([])
        })
    }

    const handleClick = (e) => {
        if (!loadingIn) {
            if (!elementSelected) {
                addKitchenElementUpper(e)
            } else {
                addKitchenElementLower(e)
            }
        }
    }

    const handlePrevClick = () => {
        swiperRef.current.swiper.slidePrev(100)
        const activeSlide = swiperRef.current.wrapperEl.querySelector('.swiper-slide-active')
        activeSlide.scrollIntoView({ behavior: 'smooth' })
    }

    const handleNextClick = () => {
        swiperRef.current.swiper.slideNext(100)
        const activeSlide = swiperRef.current.wrapperEl.querySelector('.swiper-slide-active')
        activeSlide.scrollIntoView({ behavior: 'smooth' })
    }

    function handleSwiper(swiperInstance) {
        setSwiper(swiperInstance);
    }

    return (
        <>
            <div id="box-product" ref={divRef}>

                <div className="header">
                    <div style={{display: 'flex', marginLeft: 20}}>
                        <div>
                            <BoxFilter
                                state={state}
                                setState={setState}
                                availableLength={availableLength}
                                elementSelected={elementSelected}
                            />
                        </div>

                    </div>
                </div>

                <LoadingBar loading={loadingIn}/>

                <div style={{padding: 15, height: 180}}>
                    <div style={{textAlign: 'center'}}>
                        {msgError.length > 0 && (<VscError style={{color: 'rgba(0, 0, 0, 0.26)', fontSize: 70, marginBottom: 10, marginTop: 40}}/>)}
                        {msgError.map((item, index) => (
                            <div key={index} style={{color: 'rgba(0, 0, 0, 0.26)', fontSize: 20}}>
                                {item}
                            </div>
                        ))}
                    </div>
                    {(!loadingIn && msgError.length === 0) && (
                        <Swiper
                            onSwiper={handleSwiper}
                            slidesPerView={clientWidth / 180}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                            direction="horizontal"
                            mousewheel={true}
                            freeMode={true}
                            grabCursor={false}
                            allowTouchMove={false}
                            modules={[FreeMode, Navigation, Scrollbar]}
                            scrollbar={{
                                hide: false,
                                draggable: true,
                            }}
                            className="mySwiper"
                        >
                            {rows.map((row, index) => (
                                <SwiperSlide>
                                    <ViewProduct
                                        key={index}
                                        idProduct={row.id}
                                        element={row}
                                        onClick={handleClick}
                                    />
                                </SwiperSlide>
                            ))}

                            <div className="swiper-button-prev" onClick={handlePrevClick}></div>
                            <div className="swiper-button-next" onClick={handleNextClick}></div>
                        </Swiper>
                    )}
                </div>
            </div>
        </>
    )
}
