import * as React from 'react';
import '../../../scss/Composer/index.scss';
import MenuRight from "./Menu/MenuRight";
import {useEffect, useState} from "react";
import BoxRight from "./Box/BoxRight";
import BoxProduct from "./Box/BoxProduct";
import {useParams} from "react-router-dom";
import BoxKitchen from "./Box/BoxKitchen";
import View from "./View";
import ProductElementLower from "./Modal/ProductElementLower";
import BoxAction from "./Box/BoxAction";
import {apiGetModule, apiList} from "../../../services/User/product";
import PushNotifications from "../../../components/User/PushNotifications";
import {useTranslation} from "react-i18next";
import AutoSave from "./Components/AutoSave";

export default function Composer() {

    const {serie} = useParams()
    const categoriesElementLower = [21]
    const [notifications, setNotifications] = useState([])
    const [msgError, setMsgError] = useState([])

    const [loadingAutoSave, setLoadingAutoSave] = useState(false)

    const {i18n} = useTranslation()
    // Variables de vista
    const [state, setState] = useState(initialValues({serie}))
    const [menuRightSelected, setMenuRightSelected] = useState('')

    // Variables de modal
    const [openModalProductElementLower, setOpenModalProductElementLower] = useState(false)

    // Variables de montaje
    const [kitchenElements, setKitchenElements] = useState([])
    const [accessories, setAccessories] = React.useState([])

    // Se almacena el Side de intercambio. Es decir, podemos intercambiar el lado de la cocina
    const [kitchenElementsSecondarySide, setKitchenElementsSecondarySide] = useState([])
    const [side, setSide] = useState(1)
    const [elementSelected, setElementSelected] = useState(null)
    const [elementLowerTemp, setElementLowerTemp] = useState(null)
    const [lastElementAdd, setLastElementAdd] = useState(null)
    const [loadingIncopatibility, setLoadingIncopatibility] = useState(false)
    const [loadingProducts, setLoadingProducts] = useState(false)
    const [availableLength, setAvailableLength] = useState(0)
    const [catalogueProducts, setCatalogueProducts] = useState([])
    const [customProducts, setCustomProducts] = useState([])

    // Revset View
    const [resetView, setResetView] = useState(null)

    // Incompatibilidades
    const [totalIncomplete, setTotalIncomplete] = useState(0)
    const [incompleteLoading, setIncompleteLoading] = useState(false)

    // Añadimos un elemento en la parte superior de la cocina
    const addKitchenElementUpper = (element) => {
        setLoadingProducts(true)
        // Generamos id a partir de variable time
        const id = new Date().getTime()

        let lower = []
        let upper = []
        let price = null
        let img = null
        let description = null
        let productCode = null
        let fileDwg = null

        if (element?.type_id === 5) {
            // En el caso de ser un módulo compacto
            price = element?.price
            img = element?.imgCompact3d
            description = element?.description
            productCode = element?.code
            fileDwg = element?.fileDwg
            upper.push({
                price: price,
                productId: element?.upper?.id,
                productCode: element?.upper?.code,
                companyId: element?.upper?.companyId,
                width: element.width,
                imgUpper3d: element?.upper?.imgUpper3d,
                imgUpper2d: element?.upper?.imgUpper2d,
                imgMiddle2d: element?.upper?.imgMiddle2d,
                description: element?.upper?.description,
                fileTechnicalSheets: element?.upper?.fileTechnicalSheets,
                fileDwg: element?.fileDwg
            })

            lower.push({
                productId: element?.lower?.id,
                productCode: element?.lower?.code,
                companyId: element?.lower?.companyId,
                width: element.width,
                imgLower3d: element?.lower?.imgLower3d,
                imgLower2d: element?.lower?.imgLower2d,
                description: element?.lower?.description,
                fileTechnicalSheets: element?.lower?.fileTechnicalSheets
            })
        } else {
            upper.push({
                price: element?.price,
                productId: element.id,
                productCode: element.code,
                companyId: element?.companyId,
                width: element.width,
                imgUpper3d: element.imgUpper3d,
                imgUpper2d: element.imgUpper2d,
                imgMiddle2d: element.imgMiddle2d,
                description: element.description,
                fileTechnicalSheets: element.fileTechnicalSheets,
                fileDwg: element?.fileDwg
            })
        }

        setKitchenElements([...kitchenElements, {
            id: `${id}`,
            totalWidth: element.width,
            productCode: productCode,
            productCodeNew: null,
            price: price,
            selected: false,
            fileDWG: fileDwg,
            typeId: element?.type_id,
            typeName: element?.typeName,
            companyId: element?.companyId,
            img: img,
            description: description,
            upper: upper,
            lower: lower,
            options: [],
            viewHtml: ""
        }])
        setLastElementAdd(id)
        setLoadingProducts(false)
    }

    const addKitchenElementLower = (element) => {
        // Comprobamos si es del tipo que contiene elementos múltiples en la parte inferior
        if (categoriesElementLower.includes(element?.categoryId)) {
            setOpenModalProductElementLower(true)
            setElementLowerTemp(element)
            return
        }

        const newKitchenElements = kitchenElements.map((item) => {
            if (item.id === elementSelected) {
                return {
                    ...item,
                    productCode: element?.code,
                    price: element?.price,
                    typeId: element?.type_id,
                    typeName: element?.typeName,
                    description: element?.description,
                    lower: [
                        ...item.lower,
                        {
                            productId: element?.lower?.id,
                            productCode: element?.lower?.code,
                            companyId: element?.lower?.companyId,
                            width: element?.width,
                            imgLower3d: element?.lower?.imgLower3d,
                            imgLower2d: element?.lower?.imgLower2d,
                            description: element?.lower?.description,
                            fileTechnicalSheets: element?.lower?.fileTechnicalSheets
                        }
                    ]
                }
            }
            return item
        })
        setKitchenElements(newKitchenElements)
        nextElementSelected()
    }

    // Recorremos kitchenElements y marcamos el elemento que hemos selcionado
    useEffect(() => {
        // Cocorremos el Side activo
        const newKitchenElements = kitchenElements.map(element => {
            element.selected = false
            if (element.id === elementSelected && element.selected === false) {
                element.selected = true
            }
            return element
        })
        setKitchenElements(newKitchenElements)

        // Recorremos el Side secundario
        const newKitchenElementsSecond = kitchenElementsSecondarySide.map(element => {
            element.selected = false
            if (element.id === elementSelected && element.selected === false) {
                element.selected = true
            }
            return element
        })
        setKitchenElementsSecondarySide(newKitchenElementsSecond)
    }, [elementSelected])

    // Pintamos los elementos en la vista al añadir un elemento o al selecionarlo
    useEffect(() => {
        const view = kitchenElements.map(element => {
            return {
                ...element,
                viewHtml: <View
                    element={element}
                    elementSelected={elementSelected}
                    setElementSelected={setElementSelected}
                    onChangeCode={onChangeCode}
                />
            }
        })
        setKitchenElements(view)

        const view2 = kitchenElementsSecondarySide.map(element => {
            return {
                ...element,
                viewHtml: <View
                    element={element}
                    elementSelected={elementSelected}
                    setElementSelected={setElementSelected}
                    onChangeCode={onChangeCode}
                />
            }
        })
        setKitchenElementsSecondarySide(view2)
    }, [lastElementAdd, elementSelected, resetView])

    // En el caso de pulsar sobre un elemento de la cocina y no tener el menú derecho seleccionado, seleccionamos el menú INFO-OPTIONS
    useEffect(() => {
        if (!menuRightSelected && elementSelected) {
            setMenuRightSelected("INFO-OPTIONS")
        }
    }, [elementSelected])

    // Funcionalidad de seleccionar de forma automática el siguiente elemento
    const nextElementSelected = () => {
        const index = kitchenElements.findIndex(element => element.id === elementSelected)
        // Comprobamos si el elementoSelected  actuak es el último de la lista
        if (kitchenElements[index].id === kitchenElements[kitchenElements.length - 1].id) {
            setElementSelected(null)
        } else if (index < kitchenElements.length - 1) {
            // Si no el ultimo elemento de la lista, seleccionamos el siguiente
            let nextElement = kitchenElements[index + 1]
            // Seleccionamos el siguiente elemento en caso de no tener parte inferior
            if (nextElement.lower.length === 0) {
                setElementSelected(nextElement.id)
            } else {
                setElementSelected(null)
            }
        }
    }

    useEffect(() => {
        setState({...state, type: !elementSelected ? [1, 5] : [2]})
    }, [elementSelected])

    useEffect(() => {
        if (elementSelected) {
            calculateAvailableLength()
        } else {
            setAvailableLength(0)
        }
    }, [kitchenElements])

    // Calculamos el largo disponible teniendo en cuanta los elementos de la cocina siguientes
    const calculateAvailableLength = () => {
        kitchenElements.map((element, index) => {
            if (element.id === elementSelected) {
                let totalWidth = 0
                if (element.lower.length === 0) {
                    totalWidth = element.totalWidth
                    let count = true
                    for (let i = index + 1; i < kitchenElements.length; i++) {
                        if (count && kitchenElements[i].lower.length === 0) {
                            totalWidth += kitchenElements[i].totalWidth
                        } else {
                            count = false
                        }
                    }
                }
                setAvailableLength(totalWidth)
            }
        })
    }

    const handleChangeSide = () => {
        setElementSelected(null)
        setSide(side === 1 ? 2 : 1)
        setKitchenElements(kitchenElementsSecondarySide)
        setKitchenElementsSecondarySide(kitchenElements)
    }

    const onChangeCode = (id, code) => {
        // En la vista de la cocina, cambiamos el código del producto
        const newKitchenElements = kitchenElements.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    productCodeNew: code
                }
            }
            return item
        })
        setKitchenElements(newKitchenElements)
        setResetView(Math.random())
    }

    return (
        <div style={{height: 'calc(100vh - 45px)'}}>

            <AutoSave
                {...{
                    loadingAutoSave,
                    setLoadingAutoSave,

                    setResetView,
                    serie,

                    kitchenElements,
                    setKitchenElements,

                    kitchenElementsSecondarySide,
                    setKitchenElementsSecondarySide,

                    catalogueProducts,
                    setCatalogueProducts,

                    customProducts,
                    setCustomProducts,

                    side,
                    setSide
                }}
            />

            <PushNotifications msgError={msgError}/>

            {loadingIncopatibility ? 'Cargando...' : ''}
            <ProductElementLower
                open={openModalProductElementLower}
                setOpen={setOpenModalProductElementLower}
                elementSelected={elementSelected}
                setLastElementAdd={setLastElementAdd}
                kitchenElements={kitchenElements}
                elementLowerTemp={elementLowerTemp}
                setElementLowerTemp={setElementLowerTemp}
                nextElementSelected={nextElementSelected}
            />

            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>

                <div style={{float: 'left', width: menuRightSelected === '' ? 'calc(100% - 50px)' : 'calc(100% - 450px)'}}>
                    <div style={{display: 'block', height: 'calc(100vh - 400px)', padding: 20}}>
                        <BoxKitchen
                            kitchenElements={kitchenElements}
                            setKitchenElements={setKitchenElements}
                        />

                    </div>

                    <div>
                        <BoxAction
                            handleChangeSide={handleChangeSide}
                            side={side}
                            elementSelected={elementSelected}
                            kitchenElements={kitchenElements}
                            setKitchenElements={setKitchenElements}
                            kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                            setKitchenElementsSecondarySide={setKitchenElementsSecondarySide}
                            setElementSelected={setElementSelected}
                            accessories={accessories}
                            catalogueProducts={catalogueProducts}
                            customProducts={customProducts}
                            totalIncomplete={totalIncomplete}
                            incompleteLoading={incompleteLoading}
                            setLoadingAutoSave={setLoadingAutoSave}
                            setShowMenu={setMenuRightSelected}
                        />

                        <BoxProduct
                            state={state}
                            setState={setState}
                            addKitchenElementUpper={addKitchenElementUpper}
                            addKitchenElementLower={addKitchenElementLower}
                            elementSelected={elementSelected}
                            kitchenElements={kitchenElements}
                            loadingProducts={loadingProducts}
                            availableLength={availableLength}
                        />

                    </div>
                </div>

                <div style={{float: 'right'}}>
                    <BoxRight
                        showMenu={menuRightSelected}
                        setShowMenu={setMenuRightSelected}
                        kitchenElements={kitchenElements}
                        setKitchenElements={setKitchenElements}
                        kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                        elementSelected={elementSelected}
                        lastElementAdd={lastElementAdd}
                        menuRightSelected={menuRightSelected}
                        openModal={setOpenModalProductElementLower}
                        categoriesElementLower={categoriesElementLower}
                        state={state}
                        elementLowerTemp={elementLowerTemp}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        availableLength={availableLength}
                        accessories={accessories}
                        setAccessories={setAccessories}
                        catalogueProducts={catalogueProducts}
                        setCatalogueProducts={setCatalogueProducts}
                        customProducts={customProducts}
                        setCustomProducts={setCustomProducts}
                        side={side}
                        totalIncomplete={totalIncomplete}
                        setTotalIncomplete={setTotalIncomplete}
                        incompleteLoading={incompleteLoading}
                        setIncompleteLoading={setIncompleteLoading}
                    />
                </div>

                <div style={{float: 'right'}}>
                    <MenuRight
                        showMenu={menuRightSelected}
                        setShowMenu={setMenuRightSelected}
                        notifications={notifications}
                        totalIncomplete={totalIncomplete}
                        incompleteLoading={incompleteLoading}
                        setLoadingAutoSave={setLoadingAutoSave}
                        loadingAutoSave={loadingAutoSave}
                    />
                </div>

            </div>
        </div>
    )
}

function initialValues(props) {
    return {
        type: [1, 5],
        width: [],
        serie: props.serie,
        category: 'none',
    }
}
