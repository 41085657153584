import * as React from 'react';
import Header from "./Header";
import ModalCustomProductsFrom from "../Modal/CustomProducts/CustomProductFrom";
import ModalCustomProductsCart from "../Modal/CustomProducts/CustomProductCart";
import {Button} from "@mui/material";
import SimpleListProducts from "./SimpleListProducts";
import {useTranslation} from "react-i18next";

export default function CustomProducts(props) {
    const {
        showMenu,
        customProducts,
        setCustomProducts
    } = props

    const {t} = useTranslation()

    const [modalOpenFrom, setModalOpenFrom] = React.useState(false)
    const [modalOpenCart, setModalOpenCart] = React.useState(false)

    return (
        <>
            <div style={{maxWidth: 400}}>
                {showMenu === 'CUSTOM_PRODUCTS' && (
                    <>
                        <Header title={t('composer.Custom products')} >
                            <button
                                className="btn-panel"
                                style={{width: '100%'}}
                                onClick={() => setModalOpenFrom(true)}
                            >
                                + {t('general.Add')}
                            </button>
                        </Header>

                        <div style={{display: 'block', marginBottom: 70}}>
                            <SimpleListProducts
                                customProducts={customProducts}
                            />
                        </div>

                        <div style={{padding: 20, position: 'absolute', bottom: 0, width: 360, background: '#f6f7f9'}}>
                            <Button
                                variant="outlined"
                                style={{width: '100%'}}
                                color="error"
                                size={'small'}
                                onClick={() => setModalOpenCart(true)}
                            >
                                {t('general.Show details')}
                            </Button>
                        </div>

                    </>
                )}
            </div>

            <ModalCustomProductsFrom
                open={modalOpenFrom}
                setOpen={setModalOpenFrom}
                customProducts={customProducts}
                setCustomProducts={setCustomProducts}
            />

            <ModalCustomProductsCart
                open={modalOpenCart}
                setOpen={setModalOpenCart}
                product={customProducts}
                setProduct={setCustomProducts}
            />
        </>
    )
}
