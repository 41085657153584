import React, {useEffect, useState} from "react";
import {apiList} from "../../../../services/User/accessories";
import {Grid} from "@mui/material";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import Filters from "./Filters";
import TableColumns from "./TableColumns";
import {useTranslation} from "react-i18next";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingExport, setLoadingExport] = React.useState(false)

    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const {i18n} = useTranslation()

    useEffect(() => {
        setLoading(true)
        setRowCount(0)

        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = (isExport) => {
        setMsgError([])
        if (isExport) {
            setLoadingExport(true)
        }

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            isExport: isExport ? true : false
        }


        if (state.code) {
            params.code = state.code
        }

        if (state.description) {
            params.description = state.description
        }

        params.isActive = state.isActive

        apiList(params, i18n.resolvedLanguage).then(resp => {
            if (isExport) {
                // Downloader file
                const link = document.createElement('a')
                link.href = resp.data.url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click()
                link.remove()

            } else {
                setRowCount(resp.data?.totalItems)

                let temp = resp.data.items?.map(doc => {
                    return {
                        ...doc,
                    }
                })
                setRows(temp)
            }

            setLoadingExport(false)
            setLoading(false)

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExport(false)
            if (!isExport) {
                setRows([])
            }
        })
    }

    return (
        <>
            <HeaderPage title="Accessories"/>

            <Grid container spacing={1}>
                <Grid item xs={12}>

                    <Filters
                        inpoutDisabled={loadingExport}
                        state={state}
                        setState={setState}
                        disabledExport={((rowCount > 0 && !loading) && state?.type !== "") ? false : true}
                        totalRows={rowCount}
                        handleClickExport={() => getItemsData('export')}
                        loadingExport={loadingExport}
                    />

                    <Table
                        columns={TableColumns(state?.type || null)}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "code", sort: "desc"},
        code: "",
        isActive: true,
        description: "",
    }
}
