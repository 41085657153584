import React, {useEffect, useState} from "react";
import '../../../scss/Composer/index.scss';
import CatalogueView from "./CatalogueView";

export default function Catalogue(props) {

    const [product, setProduct] = useState([])

    return (
        <div>
            <CatalogueView
                isModal={false}
                product={product}
                setProduct={setProduct}
            />
        </div>
    )
}
