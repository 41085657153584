import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MsgError from "../../../../../components/MsgError";
import '../../../../../scss/Composer/element-width.css';
import {Grid} from "@mui/material";
import ElementConcerned from "./ElementConcerned";
import SelectProduct from "./SelectProduct";
import InputButton from "../../../../../components/Inputs/InputButton";
import {apiCheckUpper} from "../../../../../services/User/incompatibility";
import {useTranslation} from "react-i18next";

export default function Index(props) {
    const {
        open, setOpen,
        elementSelected,
        kitchenElements,
        elementLowerTemp,
        setElementLowerTemp,
        setLastElementAdd,
        nextElementSelected
    } = props

    const {t, i18n} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [elementsAffected, setElementsAffected] = useState([])
    const [unionElementsEnd, setUnionElementsEnd] = useState(false)
    const [elementLowerPrepare, setElementLowerPrepare] = useState([])

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        setMsgError([])

        if (open) {
            resetVariables()

            // Comprobamos si el elemento seleccionado tiene el tamaño (width) esperado
            // Comprobamos si el elementoSelected  actual es el último de la lista
            const nowIndex = kitchenElements.findIndex(element => element.id === elementSelected)
            let widthTemp = 0
            let widthRequiere = elementLowerTemp.width
            let elementsAffectedTemp = []

            if (widthTemp < widthRequiere) {
                kitchenElements.forEach((element, index) => {
                    if (index >= nowIndex && widthTemp < widthRequiere) {
                        elementsAffectedTemp.push(element.id)
                        widthTemp += element.totalWidth
                    }
                })
            }
            setElementsAffected(elementsAffectedTemp)

            if (widthTemp > widthRequiere) {
                setMsgError([t('composer.The selected item does not fit in the kitchen')])
            } else if (widthTemp < widthRequiere) {
                setMsgError([t('composer.The selected item does not fit in the kitchen')])
            }
        }
    }, [open])

    const confirmLower = () => {
        const indexSelect = kitchenElements.findIndex(element => element.id === elementSelected)
        kitchenElements[indexSelect].lower = elementLowerPrepare
        kitchenElements[indexSelect].fileTechnicalSheets = elementLowerPrepare[0].fileTechnicalSheets
        setLastElementAdd(kitchenElements[indexSelect].id)
        setElementLowerTemp([])

        handleClose()
        nextElementSelected()
    }

    const resetVariables = () => {
        setElementsAffected([])
        setUnionElementsEnd(false)
    }

    return (
        <div>
            <Modal
                open={open}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter: ' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>

                    <MsgError msg={msgError}/>

                    {msgError.length > 0 ? (
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={6}></Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <InputButton
                                    style={{width: '100%'}}
                                    text="Cancel"
                                    onClick={handleClose}
                                    color="secondary"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <>

                            {/* PASO 1 */}
                            <ElementConcerned
                                msgError={msgError}
                                handleClose={handleClose}

                                unionElementsEnd={unionElementsEnd}
                                setUnionElementsEnd={setUnionElementsEnd}

                                kitchenElements={kitchenElements}
                                elementsAffected={elementsAffected}
                                elementSelected={elementSelected}
                                elementLowerTemp={elementLowerTemp}
                            />

                            {/* PASO 2 */}
                            <SelectProduct
                                unionElementsEnd={unionElementsEnd}
                                elementLowerTemp={elementLowerTemp}
                                elementLowerPrepare={elementLowerPrepare}
                                setElementLowerPrepare={setElementLowerPrepare}
                                confirmLower={confirmLower}
                                setMsgError={setMsgError}
                            />
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#f6f6f6',
}
