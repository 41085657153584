import React from "react";
import {Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

/**
 * Muestra un selector
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **options** *object*
 * - **onChange** *function*
 * - **multiple** *bool*
 * - **disabled** *bool*
 *
 * ## EJ
 * options: [{id: 1, name: "Aaaa"}, {id: 2, name: "Bbbbb"}]
 */
export default function InputSelect(props) {
    const {label, name, value, options, onChange, required, multiple, disabled, className, style, selectText} = props

    const {t} = useTranslation()

    const text = selectText ? selectText : t('Selecciona un elemento')

    const showName = (id) => {
        const option = options.find(option => option.id === id)
        return option ? option.name : ''
    }

    return (
        <>
            {!multiple && (

                <FormControl
                    fullWidth
                    size={"small"}
                    required={required}
                    sx={{
                        '& label.Mui-focused': {
                            color: 'red',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'red',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                               background: 'rgba(0, 0, 0, 0.04)',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'red',
                            },
                            '& fieldset': {
                                border: 'none'
                            },
                        },

                    }}
                >
                    <Select
                        value={value}
                        name={name}
                        size="small"
                        disabled={disabled}
                        onChange={onChange}
                        className={className}
                        style={{border: 'none'}}
                    >
                        <MenuItem value="none">
                            <span style={{color: "#828384"}}>{text}</span>
                        </MenuItem>
                        {options.map((option, key) => {
                            return (
                                <MenuItem
                                    key={key}
                                    value={option.id}
                                >
                                    {option.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>


            )}

            {multiple && (

                <FormControl variant="standard" fullWidth size={"small"} required={required}>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        value={value}
                        name={name}
                        size="small"
                        disabled={disabled}
                        onChange={onChange}
                        className={className}
                        multiple
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((id) => (
                                    <Chip
                                        key={id} l
                                        size={"small"}
                                        label={showName(id)}
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="">
                            <span style={{color: "#828384"}}>{text}</span>
                        </MenuItem>
                        {options.map((option, key) => {
                            return (
                                <MenuItem
                                    key={key}
                                    value={option.id}
                                >
                                    {option.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

            )}
        </>
    )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
