import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {apiGetUser, apiDeleteFile, apiSaveFile, apiUpdate} from "../../../../services/User/user";
import InputText from "../../../../components/Inputs/InputText";
import InputPassword from "../../../../components/Inputs/InputPassword";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import InputCheckbox from "../../../../components/Inputs/InputCheckbox";
import optionsPrefix from "../../../../utils/optionsSelectors/phonePrefix.json"
import ButtonFile from "../../../../components/Inputs/ButtonFile";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import InputButton from "../../../../components/Inputs/InputButton";
import {optionsArea} from "../../../../utils/optionsSelectors/optionsArea";
import MenuLanguage from "../../../../components/Layouts/Admin/MenuLanguage";

export default function Edit() {
    const {i18n} = useTranslation()
    const {id} = useParams()
    const navigate = useNavigate()
    const {auth, login} = useAuth()
    const {t} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)

    // Loading
    const [loading, setLoading] = useState(false)
    const [loadingNewFileLogo, setLoadingNewFileLogo] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    // Options
    const optionsRole = [{id: "ROLE_USER", name: t('user.roles.user')}, {id: "ROLE_ADMIN", name: t('user.roles.admin')}]

    const myRef = useRef(null)
    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getUser()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUser = () => {
        setMsgError([])

        apiGetUser(i18n.resolvedLanguage).then(resp => {
            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        updateUser()
    }

    const updateUser = () => {
        apiUpdate(state, i18n.resolvedLanguage).then(resp => {
            saveFile(id)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const saveFile = (tempId) => {
        let f = new FormData()
        f.append(`file[]`, state?.fileLogo[0]?.binary)

        apiSaveFile(f, tempId, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()

            if (!id) {
                navigate(RouteAdmin.USER_MANAGEMENT.path)
            }
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const deleteFile = () => {
        apiDeleteFile(id, i18n.resolvedLanguage).then(resp => {
            state.fileLogo = []
            state.companyLogo = ""
            setState({...state})
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    return (
        <>
            <div ref={myRef}></div>
            <div style={{maxWidth: 1000, margin: 'auto', marginBottom: '10vh'}}>

                <HeaderPage title={t('my_account')}>
                    <div style={{float: 'right'}}>
                        <MenuLanguage
                            showLang={true}
                        />
                    </div>
                </HeaderPage>

                <MsgError msg={msgError}/>
                <MsgSuccess msg={msgSuccess}/>

                <LoadingSpinner show={loadingGetInfo}/>

                <Grid container spacing={2} style={{display: loadingGetInfo ? 'none' : ''}}>

                    <Grid item xs={12} md={6}>
                        <InputText
                            label={t('user.first_name')}
                            required
                            name="name"
                            value={state?.name}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputText
                            label={t('user.last_name')}
                            required
                            name="surnames"
                            value={state?.surnames}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputText
                            label={t('user.email')}
                            required
                            name="email"
                            value={state?.email}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <InputSelect
                            label={t('user.phone prefix')}
                            required
                            name="phonePrefix"
                            onChange={handleChange}
                            value={state?.phonePrefix}
                            options={optionsPrefix}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <InputText
                            label={t('user.phone')}
                            required
                            name="phone"
                            value={state?.phone}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <InputPassword
                            label={t('user.password')}
                            name="password"
                            value={state?.password}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {state?.smsExpirationDate && (
                            <>
                                {t('user.expiration date')} {moment.unix(state?.smsExpirationDate).format('DD/MM/YYYY HH:mm')}
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <h3 className="fields-title-2">{t('user.Area assignación')}</h3>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputSelect
                            label={t('user.area')}
                            required
                            name="area"
                            onChange={handleChange}
                            value={state?.area}
                            options={optionsArea}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputText
                            label={t('user.postal code')}
                            required
                            name="postalCode"
                            value={state?.postalCode}
                            onChange={handleChange}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <h3 className="fields-title-2">{t('user.Company')}</h3>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {(!state?.fileLogo[0]?.base64 && !state.companyLogo) ? (
                            <>
                                <Avatar
                                    style={{height: 95, width: 95, margin: 'auto', marginBottom: 10}}
                                    variant="rounded"
                                />
                                <ButtonFile
                                    label={t('user.Subir logo')}
                                    loading={loadingNewFileLogo}
                                    variant="outlined"
                                    name="fileLogo"
                                    onChange={(e) => setState({...state, fileLogo: e.target.value})}
                                    style={{width: '100%'}}
                                />
                            </>
                        ) : (
                            <>
                                <div style={{width: '100%'}}>
                                    <img alt="" src={state?.fileLogo[0]?.base64 ?? state.companyLogo}
                                         style={{height: 95, margin: 'auto', marginBottom: 10, display: 'block'}}/>
                                </div>
                                <InputButton
                                    text={t('user.Eliminar logo')}
                                    variant="outlined"
                                    onClick={deleteFile}
                                    style={{width: '100%'}}
                                />
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2} style={{marginLeft: 0}}>
                            <Grid item xs={12} md={6}>
                                <InputText
                                    label={t('user.companyName')}
                                    name="companyName"
                                    value={state?.companyName}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputText
                                    label={t('user.Client number')}
                                    name="clientNumber"
                                    value={state?.clientNumber}
                                    onChange={handleChange}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputText
                                    label={t('user.Address')}
                                    name="address"
                                    value={state?.address}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputText
                                    label={t('user.fax')}
                                    name="fax"
                                    value={state?.fax}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputText
                                    label={t('user.city')}
                                    name="city"
                                    value={state?.city}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputText
                                    label={t('user.province')}
                                    name="province"
                                    value={state?.province}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={12}>
                        <InputButtonLoading
                            onClick={handleSubmit}
                            loading={loading}
                            label={t('general.Save')}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

function initialValues(props) {

    let phone = props?.phone || ""
    let phonePrefix = null
    if (phone) {
        let phoneArray = phone.split("-")
        phonePrefix = phoneArray[0]
        phone = phoneArray[1]
    }


    return {
        id: props?.id || "",
        name: props?.name || "",
        surnames: props?.surnames || "",
        email: props?.email || "",
        phonePrefix: phonePrefix || null,
        phone: phone || "",
        password: "",
        mediaId: props?.mediaId || "",
        mediaUrl: props?.mediaUrl || "",
        companyName: props?.companyName || "",
        clientNumber: props?.clientNumber || "",
        address: props?.address || "",
        fax: props?.fax || "",
        city: props?.city || "",
        province: props?.province || "",
        fileLogo: [],
        companyLogo: props?.companyLogo || null,
        postalCode: props?.postalCode || "",
        area: props?.area?.id || null,
        smsExpirationDate: props?.smsExpirationDate || null,
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }
    if (!schema.email) {
        error.push("El email es obligatorio")
    }
    // if (!schema.password) { error.push("La contraseña es obligatoria") }

    return error
}
