import * as React from 'react';
import Header from "./Header";
import {useEffect, useState} from "react";
import MsgError from "../../../../components/MsgError";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {apiCheck} from "../../../../services/User/incompatibility";
import NoData from "../../../../components/User/NoData";
import {Chip, Divider, List, ListItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VscCheck, VscSymbolMisc, VscVerified} from "react-icons/vsc";
import Typography from "@mui/material/Typography";

export default function Incompatibility(props) {
    const {
        showMenu,
        setShowMenu,
        kitchenElements,
        kitchenElementsSecondarySide,
        totalIncomplete,
        setTotalIncomplete,
        side,
        incompleteLoading,
        setIncompleteLoading
    } = props
    const {t, i18n} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [elements, setElements] = useState([])
    const [elementsSecond, setElementsSecond] = useState([])


    useEffect(() => {
        setElements([])
        setElementsSecond([])
        setMsgError([])
        setTotalIncomplete(0)
        setIncompleteLoading(true)
        const timeOutId = setTimeout(() => {
            analyzeIncompatibility()
        }, 3000)
        return () => clearTimeout(timeOutId)
    }, [kitchenElements])

    useEffect(() => {
        if (totalIncomplete > 1 ) {
            setShowMenu('INCOMPATIBILITY')
        }
    }, [totalIncomplete])

    const analyzeIncompatibility = async () => {
        setMsgError([])
        setIncompleteLoading(true)
        setElements([])
        setElementsSecond([])
        setTotalIncomplete(0)
        let totalError = 0
        let msgE = []

        if (kitchenElements.length > 0) {
            // Obtenermos todos los productCode de los elementos de la cocina
            let kitchenElementsCode = kitchenElements.map((element) => {
                return element.productCode
            })

            let kitchenElementsId = kitchenElements.map((element) => {
                return element.id
            })

            await apiCheck({
                productsCodes: kitchenElementsCode,
                kitchenId: kitchenElementsId
            }, i18n.resolvedLanguage).then(resp => {
                setElements(resp.data?.check)
                totalError = resp.data?.numErrors
            }).catch(err => {
                msgE = [...msgE, err.response?.data?.msg]
                totalError += err.response?.data?.numErrors
            })
        }

        // Obtenermos todos los productCode de los elementos de la cocina
        if (kitchenElementsSecondarySide.length > 0) {
            let kitchenElementsSecondCode = kitchenElementsSecondarySide.map((element) => {
                return element.productCode
            })

            let kitchenElementsSecondId = kitchenElementsSecondarySide.map((element) => {
                return element.id
            })

            await apiCheck({
                productsCodes: kitchenElementsSecondCode,
                kitchenId: kitchenElementsSecondId
            }, i18n.resolvedLanguage).then(resp => {
                setElementsSecond(resp.data?.check)
                totalError += resp.data?.numErrors
            }).catch(err => {
                msgE = [...msgE, err.response?.data?.msg]
                totalError += err.response?.data?.numErrors
            })
        }

        setTotalIncomplete(totalError)

        if (totalError > 0) {
            setMsgError([t('composer.The following modules cannot be next to each other due to incompatibility issues. Please change the settings.'), ...msgE])
        }

        setIncompleteLoading(false)

    }

    return (
        <div style={{maxWidth: 400}}>
            {showMenu === 'INCOMPATIBILITY' && (
                <>
                    <Header title={t('composer.Incompatibility')}/>

                    <div className="content" style={{paddingTop: incompleteLoading ? '20px' : '0px'}}>
                        <LoadingSpinner show={incompleteLoading}/>

                        {!incompleteLoading && (
                            <>
                                <br/>
                                <MsgError msg={msgError} style={{marginBottom: 0}}/>

                                <ListIncompatibility
                                    elements={elements}
                                    side={side}
                                    items={kitchenElements}
                                    t={t}
                                />

                                <ListIncompatibility
                                    elements={elementsSecond}
                                    side={side === 1 ? 2 : 1}
                                    items={kitchenElementsSecondarySide}
                                    t={t}
                                />

                            </>
                        )}

                        {(kitchenElements.length > 0 || kitchenElementsSecondarySide.length > 0) ? (
                            <>
                                {(!incompleteLoading && msgError.length === 0 && totalIncomplete === 0) && (
                                    <>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{pt: 3, textAlign: 'center', color: 'rgba(0, 0, 0, 0.26)'}}
                                        >
                                            <VscVerified size={70}/>
                                        </Typography>
                                        <span style={{width: '100%'}}>
                                            <Typography variant="h6" component="div" sx={{width: 200, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', color: 'rgba(0, 0, 0, 0.26)', textTransform: 'uppercase'}}>
                                                {t('general.Correct')}
                                            </Typography>
                                        </span>
                                    </>
                                )}
                            </>
                        ) : (
                            <NoData show={true}/>
                        )}

                    </div>
                </>
            )}
        </div>
    )
}

function ListIncompatibility(props) {

    const {elements, side, t, items} = props

    return (
        <List sx={{width: '100%', padding: 0, margin: 0}}>
            {elements.map((item, index) => {

                let correct = ((item.left === null || item.left?.correct) && (item.right === null || item.right?.correct))

                // Obtener las imagenes a partir del ID
                let indexSelect = items.findIndex(element => element.id === item.kitchenId)

                let imageUpper = []
                let imageLower = []
                let img = null

                if (items[indexSelect]?.typeId === 1 || items[indexSelect]?.typeId === 2 || items[indexSelect]?.typeId === 4) {
                    imageUpper = items[indexSelect]?.upper ?? []
                    imageLower = items[indexSelect]?.lower ?? []
                    img = items[indexSelect]?.img ?? null
                }

                if (items[indexSelect]?.typeId === 5) {
                    img = items[indexSelect]?.img ?? null
                }

                return (
                    <span key={index} style={{display: !correct ? 'block' : 'none'}}>
                        <div style={{padding: '15px 5px'}}>
                            <div style={{display: 'block'}}>
                                <strong>{t('composer.Side')} {side}</strong>
                                <Chip label={`${t('general.Position')}: ${index + 1}`} size="small" style={{marginTop: 0, float: 'right'}}/>
                                {item.left !== null && (
                                    <p style={{margin: '5px 0px', fontSize: 13}}>
                                        {item.left?.correct ? '' : `${t('composer.The item on the left is incompatible')}`}
                                    </p>
                                )}
                                {item.right !== null && (
                                    <p style={{margin: '5px 0px', fontSize: 13}}>
                                        {item.right?.correct ? '' : `${t('composer.The item on the right is incompatible')}`}
                                    </p>
                                )}
                            </div>

                            <div style={{display: 'flex', marginTop: 10}}>
                                {imageUpper.map((item, index) => (
                                    <ImgCardProduct
                                        key={index}
                                        src={item?.imgUpper3d}
                                    />
                                ))}

                                {imageLower.map((item, index) => (
                                    <ImgCardProduct
                                        key={index}
                                        src={item?.imgLower3d}
                                    />
                                ))}

                                {img && (
                                    <ImgCardProduct
                                        key={1}
                                        src={img}
                                    />
                                )}
                            </div>

                        </div>
                        <Divider component="li"/>
                    </span>
                )
            })}
        </List>
    )
}

function ImgCardProduct(props) {

    const {src, key} = props

    return (
        <div key={key} style={{padding: 5}}>
            <div
                style={{
                    padding: 10,
                    background: '#fff',
                    borderRadius: 5,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        maxWidth: 100,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'block',
                    }}
                >
                    <img
                        src={src}
                        alt=""
                        style={{width: 90, height: 90, marginLeft: 'auto', marginRight: 'auto', display: 'block'}}
                    />
                </div>
            </div>
        </div>
    )
}
