import * as React from 'react';
import Header from "./Header";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import NoData from "../../../../components/User/NoData";
import {useTranslation} from "react-i18next";
import downloaderFile from "../../../../utils/downloaderFile";

export default function Info(props) {
    const {
        showMenu,
        kitchenElements,
        elementSelected
    } = props
    const {t} = useTranslation()

    const [infoUpper, setInfoUpper] = useState([])
    const [infoLower, setInfoLower] = useState([])
    const [fileTechnicalSheets, setFileTechnicalSheets] = useState(null)
    const [totalWidth, setTotalWidth] = useState(null)
    const [fileDWG, setFileDWG] = useState(null)
    const [img, setImg] = useState(null)
    const [description, setDescription] = useState(null)
    const [productCode, setProductCode] = useState(null)

    useEffect(() => {
        setInfoUpper([])
        setInfoLower([])
        setTotalWidth(null)
        setFileDWG(null)
        setProductCode(null)
        setImg(null)
        setDescription(null)
        if (elementSelected != null && showMenu == 'INFO-OPTIONS') {
            getData()
        }
    }, [elementSelected, showMenu])

    const getData = () => {
        // Buscamos el id de elementSelected en kitchenElements
        const element = kitchenElements.find(element => element.id === elementSelected)
        setDescription(element?.description)
        setTotalWidth(element?.totalWidth)
        setFileDWG(element?.fileDWG)
        setProductCode(element?.productCode)
        if (element?.typeId === 1 || element?.typeId === 2 || element?.typeId === 3 || element?.typeId === 4) {
            setInfoUpper(element?.upper || [])
            setInfoLower(element?.lower || [])
            setImg(element?.img)
            setFileTechnicalSheets(element?.fileTechnicalSheets)

        }

        if (element?.typeId === 5) {
            setImg(element?.img)
        }

    }


    return (
        <div style={{maxWidth: 400}}>
            {(showMenu === 'INFO-OPTIONS') && (
                <>
                    <Header title={t('composer.Module description')}/>

                    <div className="content">

                        <NoData show={(!totalWidth)}/>

                        {totalWidth && (
                            <div style={{
                                padding: 10,
                                background: '#fff',
                                width: '95%',
                                borderRadius: 5,
                            }}>
                                <span>
                                    {t('general.Width')}: {totalWidth} mm
                                </span>
                                {fileDWG && (
                                    <Button
                                        variant="outlined"
                                        size={"small"}
                                        color="error"
                                        fullWidth
                                        style={{marginTop: 20}}
                                        onClick={() => downloaderFile(fileDWG, `${productCode}.dwg`)}
                                    >
                                        {t('general.Download')} DWG
                                    </Button>
                                )}
                            </div>
                        )}

                        <br/>

                        {infoUpper.map((item, index) => (
                            <>
                                <CardInfo
                                    key={index}
                                    img={item?.imgUpper3d}
                                    description={item?.description}
                                    fileTechnicalSheets={item?.fileTechnicalSheets}
                                    fileDwg={item?.fileDwg}
                                />
                            </>
                        ))}

                        {infoLower.map((item, index) => (
                            <>
                                <CardInfo
                                    key={index}
                                    img={item?.imgLower3d}
                                    description={item?.description}
                                    fileTechnicalSheets={item?.fileTechnicalSheets}
                                    fileDwg=""
                                />
                            </>
                        ))}

                        {img && (
                            <CardInfo
                                key={1}
                                img={img}
                                description={description}
                                fileDwg=""
                                fileTechnicalSheets={fileTechnicalSheets}
                            />
                        )}


                    </div>
                </>
            )}
        </div>
    )
}

function CardInfo(props) {

    const {
        img,
        description,
        fileTechnicalSheets,
        fileDwg
    } = props

    const {t} = useTranslation()

    return (
        <>
            {(img || description) && (
                <div style={{
                    padding: 10,
                    background: '#fff',
                    width: '95%',
                    borderRadius: 5,
                    marginBottom: 20
                }}>
                    <div
                        style={{
                            width: '100%',
                            maxWidth: 120,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            position: 'block',
                        }}
                    >

                        <img
                            src={img}
                            alt=""
                            style={{
                                width: '100%',
                            }}
                        />
                    </div>

                    {description && (
                        <p>
                            <strong>{t('general.Description')}:</strong> {description}
                        </p>
                    )}


                    {fileTechnicalSheets && (
                        <>
                            <Button
                                variant="outlined"
                                size={"small"}
                                color="error"
                                fullWidth
                                target={"_blank"}
                                href={fileTechnicalSheets}
                            >
                                {t('composer.Technical Sheets')}
                            </Button>
                            <br/>
                        </>
                    )}

                    {fileDwg && (
                        <>
                            <Button
                                variant="outlined"
                                size={"small"}
                                color="error"
                                fullWidth
                                target={"_blank"}
                                href={fileDwg}
                                style={{marginTop: fileTechnicalSheets ? 10 : 0}}
                            >
                                DWG
                            </Button>
                            <br/>
                        </>
                    )}

                </div>
            )}
        </>
    )
}
