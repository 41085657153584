import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/users`;

export function apiList(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.email) { query += `&email=${props.email}`; }
    if (props.role) { query += `&role=${props.role}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(data, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/create`, data, config)
}

export function apiUpdate(data, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.put(`${BASE_PATH}/${lang}/${baseUrl}/update/${data.id}`, data, config)
}

export function apiGetUser(id, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/${id}`, config)
}

export function apiSaveFile(props, id, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-file/${id}`, props, config)
}

export function apiDeleteFile(id, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/delete-file/${id}`, config)
}
