import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import Table from "../../../../../components/Table/Table";
import {useTranslation} from "react-i18next";
import {apiListCategory} from "../../../../../services/Admin/category";
import InputButtonLoading from "../../../../../components/Inputs/InputButtonLoading";

export default function Management() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [loadingExport, setLoadingExport] = useState(false)

    useEffect(() => {
        setLoading(true)
        setRowCount(0)
        getItemsData()
    }, [])

    const getItemsData = (isExport) => {
        if (isExport) {
            setLoadingExport(true)
        }
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        apiListCategory({
            isExport: isExport ? true : false
        }, i18n.resolvedLanguage).then(resp => {
            if (isExport) {
                // Downloader file
                const link = document.createElement('a')
                link.href = resp.data.url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click()
                link.remove()

            } else {
                setRowCount(resp.data.length)

                let temp = resp.data?.map(doc => {
                    return {
                        ...doc,
                        isActive: doc.isActive ? "Yes" : "No",
                    }
                })

                setRows(temp)
            }

            setLoadingExport(false)
            setLoading(false)

        }).catch(err => {
            setLoading(false)
            setRows([])
        })
    }

    return (
        <>
            <HeaderPage title="Category" border={false}>
                <InputButtonLoading
                    onClick={() => getItemsData('export')}
                    loading={loadingExport}
                    label={`Export results ${rowCount}`}
                    variant="outlined"
                />
            </HeaderPage>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows || []}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function tableColumns() {
    return [
        {
            field: "id",
            headerName: "id",
            sortable: false,
            flex: 1,
        },
        {
            field: "type",
            headerName: "type",
            sortable: false,
            flex: 1,
        },
        {
            field: "name_fr",
            headerName: "name FR",
            sortable: false,
            flex: 1,
        },
        {
            field: "name_en",
            headerName: "name EN",
            sortable: false,
            flex: 1,
        },
        {
            field: "position_serie_1",
            headerName: "position (Royal Chef 800)",
            sortable: false,
            flex: 1,
        },
        {
            field: "position_serie_2",
            headerName: "position (Rosichef Signature 900)",
            sortable: false,
            flex: 1,
        },
        {
            field: "isActive",
            headerName: "Active",
            sortable: false,
            flex: 1,
        },
    ]
}

function initialValues() {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}
