import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/products`;

export function apiListElements(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.elementCode) { query += `&elementCode=${props.elementCode}`; }
    if (props.lowerCode) { query += `&lowerCode=${props.lowerCode}`; }
    if (props.isExport) { query += `&isExport=${props.isExport}`; }
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list-elements?1=1${query}`, config)
}
