import React from "react";
import {Grid, Box} from "@mui/material";

export default function MsgError(props) {

    const {msg, style} = props

    if (!msg || msg.length === 0) { return "" }

    return (
        <Grid item xs={12} md={12} lg={12} style={style}>
            <Box className="alert-danger" style={style}>
                {msg.map((doc, key) => {
                    return ( <span key={key}>{doc} <br /></span>)
                })}
            </Box>
        </Grid>
    )
}
