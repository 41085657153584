import React, {useState} from "react";
import {Button, Fade, IconButton, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {MdOutlineLanguage} from "react-icons/md";

const language = {
    en: {nativeName: '(EN) English'},
    fr: {nativeName: '(FR) Français'},
}

export default function MenuLanguage(props) {

    const {showLang} = props

    const showLangIn = showLang ? showLang : false

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (lng) => {
        if (lng) { void i18n.changeLanguage(lng) }
        setAnchorEl(null)
    }

    const {i18n} = useTranslation()

    return (
        <div className="menu-language">
            <IconButton
                onClick={handleClick}
            >
                <MdOutlineLanguage style={{color: "#545969"}}/>
            </IconButton>

            {showLangIn && (
                <Button
                    id="language-button"
                    aria-controls="menu-language"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    style={{color: "#545969"}}
                >
                    {language[i18n.resolvedLanguage].nativeName}
                </Button>
            )}

            <Menu
                id="menu-language"
                MenuListProps={{
                    'aria-labelledby': 'language-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {Object.keys(language).map((item) => (
                    <>
                        {i18n.resolvedLanguage !== item && (
                            <MenuItem key={item} onClick={() => handleClose(item)}>
                                {language[item].nativeName}
                            </MenuItem>
                        )}
                    </>

                ))}
            </Menu>
        </div>
    )
}
