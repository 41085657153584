import React from "react";
import {Button, Grid, IconButton} from "@mui/material";
import {RouteAdmin} from "../../routes/RouteAdmin";
import {ArrowBackIos} from "@mui/icons-material";
import InputSelect from "../Inputs/InputSelect";

/**
 *  Añade una cabecera a la página
 *  @param {string} title - Título de la cabecera
 *  @param {string} urlBack - Url de la página de vuelta
 *  @param {boolean} border - Si se muestra o no el borde inferior
 *  @param {object} children - Contenido de la cabecera
 */
export default function HeaderPage(props) {
    const {title, children, urlBack, border} = props

    const urlBackIn = urlBack ? urlBack : null
    const borderIn = border === false ? border : true

    return (
        <Grid container spacing={2} style={{marginBottom: borderIn ? 20 : 0, marginTop: 0}}>
            <Grid item xs={12} md={6}>
                <div
                    style={{
                        borderBottom: borderIn ? '1px solid #e8ebef' : 'none',
                        height: 46
                    }}
                >
                    {urlBackIn && (
                        <IconButton
                            aria-label="back"
                            size="small"
                            href={urlBack}
                            style={{float: 'left', color: 'black'}}
                        >
                            <ArrowBackIos fontSize="inherit"/>
                        </IconButton>
                    )}
                    <h3 className="fields-title" style={{float: 'left', border: 'none'}}>{title}</h3>
                </div>
            </Grid>

            <Grid item xs={12} md={6}
                  style={{
                      borderBottom: borderIn ? '1px solid #e8ebef' : 'none',
                  }}
            >
                {children}
            </Grid>

        </Grid>
    )
}
