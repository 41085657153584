import Homepage from "../pages/User/Homepage"
import Composer from "../pages/User/Composer";
import Catalogue from "../pages/User/Catalogue";
import UserEdit from "../pages/User/User/Fields";

const baseTitle = "Rosinox - "

export const RouteUser = {
    ROOT: {
        path: "/",
        page: Homepage,
        title: `${baseTitle}Homepage`,
        roles: ["ROLE_USER"]
    },
    COMPOSER: {
        path: "/composer",
        params: "/:serie",
        page: Composer,
        title: `${baseTitle}Homepage`,
        adminBar: false,
        roles: ["ROLE_USER", "ROLE_ADMIN"]
    },
    CATALOGUE: {
        path: "/catalogue",
        page: Catalogue,
        title: `${baseTitle}Catalogue`,
        adminBar: false,
        roles: ["ROLE_USER", "ROLE_ADMIN"]
    },
    USER_EDIT: {
        path: `/users/edit`,
        params: "/:id",
        page: UserEdit,
        title: `${baseTitle}User Edit`,
        roles: ["ROLE_USER", "ROLE_ADMIN"]
    },
}
