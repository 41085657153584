import React, {useEffect, useState} from "react";
import {apiLogReport} from "../../../services/Admin/log";
import moment from "moment";
import {Grid} from "@mui/material";
import MsgError from "../../../components/MsgError";
import HeaderPage from "../../../components/Admin/HeaderPage";
import Table from "../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../utils/constants";
import {useTranslation} from "react-i18next";

export default function LogReport() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "roleName") { field = "role" }
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "access") { field = "lastActivity" }

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.email) { params.email = state.email }
        if (state.role) { params.role = state.role }

        apiLogReport(params, i18n.resolvedLanguage).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    id: doc.id,
                    createdAt: moment.unix(doc.createdAt).format("D/M/Y H:mm"),
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    return (
        <>
            <HeaderPage title="Reports" border={false}/>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        role: ""
    }
}

function tableColumns(handleChangeSelection, selection) {
    return [
        {
            field: "id",
            headerName: "ID",
            sortable: false,
            flex: 1
        },
        {
            field: "createdAt",
            headerName: "CREATED AT",
            sortable: false,
            flex: 1
        },
        {
            field: "platform",
            headerName: "PLATFORM CLIENT",
            sortable: false,
            flex: 1
        },
        {
            field: "requestUrl",
            headerName: "URL",
            sortable: false,
            flex: 1
        },
        {
            field: "browser",
            headerName: "BROWSER CLIENT",
            sortable: false,
            flex: 2
        }
    ]
}
