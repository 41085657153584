import {BASE_PATH, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_USER}/serie`;

export function apiGetSerie(lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/`, config)
}
