import UserManagement from "../pages/Admin/User/Management"
import UserEdit from "../pages/Admin/User/Fields"
import UserCreate from "../pages/Admin/User/Fields"
import MediaManagement from "../pages/Admin/Media/Management"
import Testing from "../pages/Admin/Testing"
import LogReport from "../pages/Admin/Log/LogReport"
import LogMail from "../pages/Admin/Log/LogMail"
import LogSms from "../pages/Admin/Log/LogSms"
import DocInstall from "../pages/Admin/Doc/DocInstall"
import ProductManagement from "../pages/Admin/BlockProduct/Product/Management"
import CategoryManagement from "../pages/Admin/BlockProduct/Category/Management"
import SerieManagement from "../pages/Admin/BlockProduct/Serie/Management"
import AccessoriesManagement from "../pages/Admin/Accessories/Management"
import OptionsManagement from "../pages/Admin/BlockProduct/Options/Management"
import ProductOptionsManagement from "../pages/Admin/BlockProduct/Relationships/ProductOptions/Management"
import ProductLowerElementsManagement from "../pages/Admin/BlockProduct/Relationships/ProductLowerElements/Management"
import IncompatibilityManagement from "../pages/Admin/BlockProduct/Incompatibility/Management"
import IncompatibilityUpperManagement from "../pages/Admin/BlockProduct/IncompatibilityUpper/Management"
import TransactionManagement from "../pages/Admin/Transaction/Management"
import Import from "../pages/Admin/BlockImport/Import"
import ImportHistory from "../pages/Admin/BlockImport/History"
import ImportMedia from "../pages/Admin/BlockImport/Media"
const baseTitle = "Rosinox - "
const urlBase = "/dash"

export const RouteAdmin = {
    USER_MANAGEMENT: {
        path: `${urlBase}/users/management`,
        menu: "user",
        menuChild: "",
        page: UserManagement,
        title: `${baseTitle}User Management`,
        roles: ["ROLE_ADMIN"]
    },
    USER_EDIT: {
        path: `${urlBase}/users/edit`,
        menu: "user",
        menuChild: "",
        params: "/:id",
        page: UserEdit,
        title: `${baseTitle}User Edit`,
        roles: ["ROLE_ADMIN"]
    },
    USER_CREATE: {
        path: `${urlBase}/users/create`,
        menu: "user",
        menuChild: "",
        page: UserCreate,
        title: `${baseTitle}User Create`,
        roles: ["ROLE_ADMIN"]
    },
    MEDIA_MANAGEMENT: {
        path: `${urlBase}/media/management`,
        menu: "media",
        menuChild: "",
        page: MediaManagement,
        title: `${baseTitle}Media Management`,
        roles: ["ROLE_ADMIN"]
    },
    TESTING: {
        path: `${urlBase}/testing`,
        menu: "test",
        menuChild: "",
        page: Testing,
        title: "",
        roles: ["ROLE_ADMIN"]
    },
    LOG_REPORT: {
        path: `${urlBase}/logs/report`,
        menu: "log",
        menuChild: "report",
        page: LogReport,
        title: `${baseTitle}Log Report`,
        roles: ["ROLE_ADMIN"]
    },
    LOG_MAIL: {
        path: `${urlBase}/logs/mail`,
        menu: "log",
        menuChild: "mail",
        page: LogMail,
        title: `${baseTitle}Log Mail`,
        roles: ["ROLE_ADMIN"]
    },
    LOG_SMS: {
        path: `${urlBase}/logs/sms`,
        menu: "log",
        menuChild: "sms",
        page: LogSms,
        title: `${baseTitle}Log Sms`,
        roles: ["ROLE_ADMIN"]
    },
    DOC_INSTALL: {
        path: `${urlBase}/doc/install`,
        menu: "doc",
        menuChild: "install",
        page: DocInstall,
        title: `${baseTitle}Doc Install`,
        roles: ["ROLE_ADMIN"]
    },
    PRODUCT_MANAGEMENT: {
        path: `${urlBase}/product/management`,
        menu: "product",
        menuChild: "product",
        page: ProductManagement,
        title: `${baseTitle}Product Management`,
        roles: ["ROLE_ADMIN"]
    },
    CATEGORY_MANAGEMENT: {
        path: `${urlBase}/category/management`,
        menu: "product",
        menuChild: "category",
        page: CategoryManagement,
        title: `${baseTitle}category Management`,
        roles: ["ROLE_ADMIN"]
    },
    SERIE_MANAGEMENT: {
        path: `${urlBase}/serie/management`,
        menu: "product",
        menuChild: "serie",
        page: SerieManagement,
        title: `${baseTitle}serie Management`,
        roles: ["ROLE_ADMIN"]
    },
    ACCESSORIES_MANAGEMENT: {
        path: `${urlBase}/accessories/management`,
        menu: "accessories",
        menuChild: "",
        page: AccessoriesManagement,
        title: `${baseTitle}Accessories Management`,
        roles: ["ROLE_ADMIN"]
    },
    PRODUCT_INCOMPATIBILITY_MANAGEMENT: {
        path: `${urlBase}/incompatibility/management`,
        menu: "product",
        menuChild: "product-incompatibility",
        page: IncompatibilityManagement,
        title: `${baseTitle}Incompatibility Management`,
        roles: ["ROLE_ADMIN"]
    },
    PRODUCT_INCOMPATIBILITY_UPPER_MANAGEMENT: {
        path: `${urlBase}/incompatibility-upper/management`,
        menu: "product",
        menuChild: "product-incompatibility-upper",
        page: IncompatibilityUpperManagement,
        title: `${baseTitle}Incompatibility Upper Management`,
        roles: ["ROLE_ADMIN"]
    },
    PRODUCT_OPTIONS_MANAGEMENT: {
        path: `${urlBase}/product-options/management`,
        menu: "product",
        menuChild: "product-options",
        page: ProductOptionsManagement,
        title: `${baseTitle}Product Options Management`,
        roles: ["ROLE_ADMIN"]
    },
    PRODUCT_LOWER_ELEMENTS_MANAGEMENT: {
        path: `${urlBase}/product-lower-elements/management`,
        menu: "product",
        menuChild: "product-lower-elements",
        page: ProductLowerElementsManagement,
        title: `${baseTitle}Product Lower Elements Management`,
        roles: ["ROLE_ADMIN"]
    },
    OPTIONS_MANAGEMENT: {
        path: `${urlBase}/options/management`,
        menu: "product",
        menuChild: "options",
        page: OptionsManagement,
        title: `${baseTitle}Options Management`,
        roles: ["ROLE_ADMIN"]
    },
    TRANSACTION_MANAGEMENT: {
        path: `${urlBase}/transaction/management`,
        menu: "transaction",
        menuChild: "",
        page: TransactionManagement,
        title: `${baseTitle}Transaction Management`,
        roles: ["ROLE_ADMIN"]
    },
    IMPORT_INDEX: {
        path: `${urlBase}/import`,
        menu: "import",
        menuChild: "import-manager",
        page: Import,
        title: `${baseTitle}Import`,
        roles: ["ROLE_ADMIN"]
    },
    IMPORT_HISTORY: {
        path: `${urlBase}/import-history`,
        menu: "import",
        menuChild: "history",
        page: ImportHistory,
        title: `${baseTitle}Import history`,
        roles: ["ROLE_ADMIN"]
    },
    IMPORT_MEDIA: {
        path: `${urlBase}/import-media`,
        menu: "import",
        menuChild: "media",
        page: ImportMedia,
        title: `${baseTitle}Import media`,
        roles: ["ROLE_ADMIN"]
    },
}
