import {BASE_PATH, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";
import {useTranslation} from "react-i18next";

const baseUrl = `${BASE_PATH_EXTENSION_USER}/products`;

export function apiList(props, methods = "get", lang = "en") {
    const config = {headers: {'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.isExport) {
        query += `&isExport=${props.isExport}`;
    }
    if (props.limit) {
        query += `&limit=${props.limit}`;
    }
    if (props.offset) {
        query += `&offset=${props.offset}`;
    }
    if (props.field) {
        query += `&field=${props.field}`;
    }
    if (props.sort) {
        query += `&sort=${props.sort}`;
    }

    if (props.code && methods === "get") {
        query += `&code=${props.code}`;
    }
    if (props.serie) {
        query += `&serie=${props.serie}`;
    }
    if (props.category) {
        query += `&category=${props.category}`;
    }
    if (props.productUpperCode) {
        query += `&productUpperCode=${props.productUpperCode}`;
    }
    if (props.length) {
        query += `&length=${props.length}`;
    }
    if (props.description) {
        query += `&description=${props.description}`;
    }
    if (props.company) {
        query += `&company=${props.company}`;
    }
    if (props.catalogue) {
        query += `&catalogue=${props.catalogue}`;
    }
    if (props.textOnlyLocale) {
        query += `&textOnlyLocale=${props.textOnlyLocale}`;
    }

    if (props.composer) {
        query += `&composer=${props.composer}`;
    }

    query += `&isActive=${props.isActive}`;

    if (props.width) {
        props.width.forEach((item) => {
            query += `&width[]=${item}`
        })
    }

    if (props.type) {
        if (Array.isArray(props.type)) {
            props.type.forEach((item) => {
                query += `&type[]=${item}`
            })
        } else {
            query += `&type=${props.type}`;
        }
    }

    if (methods === "post") {
        return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/list`, props, config)
    } else {
        return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?1=1${query}`, config)
    }
}

export function apiListElements(props, lang = "en") {
    const config = {headers: {'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.type) {
        query += `&type=${props.type}`;
    }
    if (props.serie) {
        query += `&serie=${props.serie}`;
    }
    if (props.category) {
        query += `&category=${props.category}`;
    }
    if (props.elementCode) {
        query += `&elementCode=${props.elementCode}`;
    }
    if (props.lowerCode) {
        query += `&lowerCode=${props.lowerCode}`;
    }
    if (props.isExport) {
        query += `&isExport=${props.isExport}`;
    }
    if (props.isActive) {
        query += `&isActive=${props.isActive}`;
    }
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list-elements?1=1${query}`, config)
}

export function apiGetModule(productUpperCode, productLowerCode, lang = "en") {
    const config = {headers: {'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-module?productUpperCode=${productUpperCode}&productLowerCode=${productLowerCode}`, config)
}

export function apiGetProduct(code, lang = "en") {
    const config = {headers: {'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/${code}`, config)
}
