import React, {useEffect, useState} from "react";
import {apiListUpper} from "../../../../../services/Admin/incompatibility";
import {Grid, IconButton} from "@mui/material";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import Table from "../../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../../utils/constants";
import InputText from "../../../../../components/Inputs/InputText";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {useTranslation} from "react-i18next";
import InputButtonLoading from "../../../../../components/Inputs/InputButtonLoading";
import downloaderFile from "../../../../../utils/downloaderFile";

export default function Management() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingExport, setLoadingExport] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])
    const params = new URLSearchParams(window.location.search)

    useEffect(() => {
        if (params.get("code")) {
            setState({...state, type: null, code: params.get("code")})
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        setRowCount(0)

        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = (isExport) => {
        setMsgError([])
        if (isExport) {
            setLoadingExport(true)
        }

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            isExport: isExport ? true : false
        }

        if (state.productCodeLower) { params.productCodeLower = state.productCodeLower }
        if (state.productCodeUpper) { params.productCodeUpper = state.productCodeUpper }

        apiListUpper(params, i18n.resolvedLanguage).then(resp => {
            if (isExport) {
                downloaderFile(resp.data.url, "incompatibility-sbr.xlsx")
            } else {
                setRowCount(resp.data?.totalItems)

                let temp = resp.data.items?.map(doc => {
                    return {
                        ...doc,
                    }
                })

                setRows(temp)
            }
            setLoading(false)
            setLoadingExport(false)

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExport(false)

            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    return (
        <>
            <HeaderPage title="Incompatibility SBR">
                <InputButtonLoading
                    onClick={() => getItemsData('export')}
                    loading={loadingExport}
                    label={`Export results ${rowCount}`}
                    variant="outlined"
                />
            </HeaderPage>

            <Grid container spacing={1}>

                <Filters
                    state={state}
                    setState={setState}
                    loadingExport={loadingExport}
                    disabledExport={(rowCount > 0 && !loading) ? false : true}
                    totalRows={rowCount}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        productCodeLower: "",
        productCodeUpper: "",
    }
}

function tableColumns() {
    return [
        {
            field: "productCodeLower",
            headerName: "product Code Lower",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.productCodeLower}`} target="_blank">
                        {params.productCodeLower}
                    </a>
                )
            },
        },
        {
            field: "productCodeUpper",
            headerName: "Product Code Upper",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.productCodeUpper}`} target="_blank">
                        {params.productCodeUpper}
                    </a>
                )
            },
        },
    ]
}

function Filters(props) {

    const {
        state,
        setState,
    } = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <>
            <Grid item xs={6}>
                <InputText
                    label="Product Code Lower"
                    name="productCodeLower"
                    value={state.productCodeLower}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6}>
                <InputText
                    label="Product Code Upper"
                    name="productCodeUpper"
                    value={state.productCodeUpper}
                    onChange={handleChange}
                />
            </Grid>
        </>
    )
}
