import {Divider, List, ListItem} from "@mui/material";
import NoData from "../../../../components/User/NoData";
import * as React from "react";
import {useTranslation} from "react-i18next";

export default function SimpleListProducts(props) {

    const {customProducts} = props
    const {t} = useTranslation()

    return (
        <List>
            <ListItem style={{width: '100%', display: 'flex', fontFamily: 'AdminFontBold'}}>
                <span style={{width: '60%', fontSize: 14}}>Code</span>
                <span style={{width: '40%', fontSize: 14, textAlign: 'right'}}>{t('general.Quantity')}</span>
            </ListItem>
            <Divider component="li"/>
            {customProducts.map((item, index) => (
                <span key={index}>
                     <ListItem style={{
                         width: '100%',
                         display: 'flex',
                     }}>
                        <span style={{width: '60%', fontSize: 14}}>{item?.productCode}</span>
                        <span style={{width: '40%', textAlign: 'right'}}>
                            <span>{item.quantity}</span>
                        </span>
                    </ListItem>

                    <Divider component="li"/>
                </span>
            ))}

            <NoData show={(customProducts.length === 0)}/>

        </List>
    )
}
