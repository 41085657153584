import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import InputButton from "../../../../../../components/Inputs/InputButton";
import View from "../../../View";
import {VscChromeClose} from "react-icons/vsc";
import Formulario from "./Formulario";
import MsgError from "../../../../../../components/MsgError";
import {useTranslation} from "react-i18next";

export default function Index(props) {
    const {
        open,
        setOpen,
        customProducts,
        setCustomProducts,
    } = props

    const {t} = useTranslation()

    const [stateForm, setStateForm] = React.useState(initialValue())
    const [msgError, setMsgError] = React.useState([])

    const handleClose = () => {
        setStateForm(initialValue())
        setOpen(false)
    }

    const handleSubmit = () => {
        setMsgError([])

        let form = stateForm

        let error = []
        // Comprobamos si el productCode ya existe
        let productCodeExists = customProducts.find((item) => item.productCode === form.productCode)
        if (productCodeExists) {
            error.push(t('composer.The Product Code already exists'))
        }

        // Comprovamos si el productCode es un número
        if (!form.productCode){
            error.push(t('composer.The Product Code is required'))
        }

        // Probamos si el precio es un número
        if (isNaN(form.price)) {
            error.push(t('composer.The price must be a number'))
        }

        // Probamos si la cantidad es un número
        if (isNaN(form.quantity)) {
            error.push(t('composer.The quantity must be a number'))
        }

        // Probamos si la cantidad es superior a 0
        if (form.quantity <= 0) {
            error.push(t('composer.The quantity must be greater than 0'))
        }

        if (error.length > 0) {
            setMsgError(error)
            return
        }


        form.quantity = parseInt(form.quantity)

        setCustomProducts([
            ...customProducts,
            form
        ])
        handleClose()
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter:' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>{t('composer.Custom products')}</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>

                        <Formulario
                            state={stateForm}
                            setState={setStateForm}
                        />

                        {msgError.length > 0 && (
                            <Grid item md={12}>
                                <MsgError msg={msgError}/>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={t('general.Cancel')}
                                onClick={handleClose}
                                variant="outlined"
                                color="secondary"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={t('general.Save')}
                                onClick={handleSubmit}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    padding: '20px 10px 30px 30px',
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}

function initialValue() {
    return {
        productCode: '',
        discount: '',
        price: '',
        quantity: '',
        description: '',
    }
}
