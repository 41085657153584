import {BASE_PATH, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_USER}/category`;

export function apiList(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.limit) { query += `&limit=${props.limit}`; }
    if (props.offset) { query += `&offset=${props.offset}`; }
    if (props.field) { query += `&field=${props.field}`; }
    if (props.sort) { query += `&sort=${props.sort}`; }

    if (props.type) { query += `&type=${props.type}`; }
    if (props.width) { query += `&width=${props.width}`; }
    if (props.serie) { query += `&serie=${props.serie}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?1=1${query}`, config)
}
