import * as React from 'react';
import {Button, Card, CardActions, CardContent, Grid, IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "../../../components/Table/Table";
import {useState} from "react";
import complet800 from "../../../assets/images/complet-800.jpg";
import complet900 from "../../../assets/images/complet-900.jpg";
import rosichef900 from "../../../assets/images/rosichef-900.jpg";
import {useNavigate} from "react-router-dom";
import {RouteUser} from "../../../routes/RouteUser";
import Quotation from "../Quotation";
import {VscBook} from "react-icons/vsc";
import {useTranslation} from "react-i18next";

export default function Homepage(props) {

    const navigate = useNavigate()
    const {t} = useTranslation()

    const handleClick = (href) => {
        navigate(href)
    }

    const card800 = (
        <React.Fragment>
            <CardContent>
                <img src={complet800} alt="complet-800" style={{width: 170, margin: 'auto', display: 'block'}} />
                <p style={{marginBottom: 0, fontSize: 12}}>Royal Chef 800</p>
            </CardContent>
        </React.Fragment>
    )

    const card900 = (
        <React.Fragment>
            <CardContent>
                <img src={complet900} alt="complet-900" style={{width: 180, margin: 'auto', display: 'block'}} />
                <p style={{marginBottom: 0, fontSize: 12}}>Rosichef Signature 900</p>
            </CardContent>
        </React.Fragment>
    )

    const Rosichef900 = (
        <React.Fragment>
            <CardContent>
                <img src={rosichef900} alt="complet-900" style={{width: 170, margin: 'auto', display: 'block'}} />
                <p style={{marginBottom: 0, fontSize: 12}}>Rosichef 900</p>
            </CardContent>
        </React.Fragment>
    )

    return (
        <div style={{maxWidth: 1000, margin: 'auto', marginTop: '5vh', marginBottom: '10vh'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>{t('PAGE_HOMEPAGE.title')}</h1>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <Card
                            variant="outlined"
                            style={{border: 'none'}}
                        >
                            <React.Fragment>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {t('PAGE_HOMEPAGE.description')}
                                    </Typography>
                                    <p style={{marginBottom: 0, fontSize: 12, marginTop: 50}}>{t('PAGE_HOMEPAGE.Made in France')}</p>

                                </CardContent>
                            </React.Fragment>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <Card
                            variant="outlined"
                            style={{ cursor: 'pointer'}}
                            onClick={() => handleClick(`${RouteUser.CATALOGUE.path}`)}
                        >
                            <React.Fragment>
                                <CardContent style={{background: '#ea0c1f', color: '#fff'}}>
                                    <div className="icon-white">
                                        <VscBook style={{fontSize: 60, display: 'block', margin: 'auto', marginTop: 20}} />
                                    </div>
                                    <p style={{marginBottom: 0, fontSize: 12}}>{t('catalogue.Catalogue')}</p>
                                </CardContent>
                            </React.Fragment>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Card
                            variant="outlined"
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick(`${RouteUser.COMPOSER.path}/1`)}
                        >
                            {card800}
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Card
                            variant="outlined"
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick(`${RouteUser.COMPOSER.path}/2`)}
                        >
                            {card900}
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Card
                            variant="outlined"
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick(`${RouteUser.COMPOSER.path}/22`)}
                        >
                            {Rosichef900}
                        </Card>
                    </Box>
                </Grid>
                <Quotation />
            </Grid>
        </div>
    )
}
const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
)

function initialValues() {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        role: ""
    }
}
