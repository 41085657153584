import React, {useEffect} from "react";
import "./scss/global.scss";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Auth from "./auth"
import NotFoundPage from "./pages/User/NotFoundPage";
import {RouteAdmin} from "./routes/RouteAdmin";
import {RouteAuth} from "./routes/RouteAuth";
import {RouteUser} from "./routes/RouteUser";
import {Helmet} from "react-helmet";
import CheckRole from "./components/Auth/CheckRole";
import LayoutUser from "./components/Layouts/User";
import LayoutAdmin from "./components/Layouts/Admin";
import MaintenanceAdmin from "./components/Admin/MaintenanceAdmin";
import MaintenanceUser from "./components/User/MaintenanceUser";
import ErrorDetector from "./services/ErrorDetector";

export default function App() {

    const checkVersion = () => {
        // Detect si es nueva version y redireccionar a la pagina de actualizacion
        if (localStorage.getItem("version") !== process.env.REACT_APP_VERSION) {
            localStorage.setItem("version", process.env.REACT_APP_VERSION)
            // reload page
            window.location.reload()
        }
    }

    return (
        <BrowserRouter>
            <ErrorDetector />
            <Auth>
                <Routes>
                    {Object.keys(RouteAuth).map((item, key) => {
                        checkVersion()

                        const e = RouteAuth[item]
                        return (
                            <Route key={key} path={`${e.path}${e.params ?? ""}`} element={
                                <>
                                    <Helmet>
                                        <title>{e.title}</title>
                                    </Helmet>
                                    <CheckRole roles={e.roles}/>
                                    <e.page />
                                </>
                            } />
                        )
                    })}

                    {Object.keys(RouteUser).map((item, key) => {
                        checkVersion()

                        const e = RouteUser[item]
                        return (
                            <Route key={key} path={`${e.path}${e.params ?? ""}`} element={
                                <LayoutUser adminBar={e.adminBar ?? true}>
                                    <Helmet>
                                        <title>{e.title}</title>
                                    </Helmet>
                                    <CheckRole roles={e.roles}/>
                                    <MaintenanceUser path={e.path}/>
                                    <e.page />
                                </LayoutUser>
                            } />
                        )
                    })}

                    {Object.keys(RouteAdmin).map((item, key) => {
                        checkVersion()

                        const e = RouteAdmin[item]
                        return (
                            <Route key={key} path={`${e.path}${e.params ?? ""}`} element={
                                <LayoutAdmin menuChild={e.menuChild} menu={e.menu}>
                                    <Helmet>
                                        <title>{e.title}</title>
                                    </Helmet>
                                    <CheckRole roles={e.roles}/>
                                    <MaintenanceAdmin path={e.path}/>
                                    <e.page />
                                </LayoutAdmin>
                            } />
                        )
                    })}

                    <Route path='*' element={<NotFoundPage/>}/>
                </Routes>
            </Auth>
        </BrowserRouter>
    )
}
