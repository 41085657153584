import {TOKEN} from "../../utils/constants"
import SecureLS from "secure-ls";
import jwtDecode from "jwt-decode";

function LocalStorage() {
    if (typeof window !== 'undefined') {
        return new SecureLS({'encodingType': 'aes'});
    } else {
        return null;
    }
}

export function setToken(token) {

    let ls = LocalStorage();
    ls?.set(TOKEN, token);

}

export function getToken() {
    try {
        let ls = LocalStorage()
        return ls?.get(TOKEN)
    } catch (error) {
        removeToken()
        return null
    }

}

export function removeToken() {

    let ls = LocalStorage()
    ls?.remove(TOKEN)

}

export function hasExpiredToken() {
    const auth = getToken()
    const currentDate = new Date().getTime()

    let expireDate = null
    let expired = false
    if (auth?.primaryUser?.token) {
        expireDate = jwtDecode(auth.primaryUser?.token).exp * 1000
        if (currentDate > expireDate) {
            expired = true
        }
    }

    if (auth?.secondaryUser?.token) {
        expireDate = jwtDecode(auth.secondaryUser?.token).exp * 1000
        if (currentDate > expireDate) {
            expired = true
        }

    }
    return expired;
}
