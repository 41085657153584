import React from "react";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {VscFile, VscFilePdf} from "react-icons/vsc";
import {IconButton} from "@mui/material";

export default function TableColumns(type) {

    if (type === 1) {
        return Type1()
    }
    if (type === 2) {
        return Type2()
    }
    if (type === 3) {
        return Type3()
    }
    if (type === 4) {
        return Type4()
    }
    if (type === 5) {
        return Type5()
    }

    if (type === null) {
        return TypeAll()
    }
}

function Type1() {
    return [
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <b>{params.code}</b>
                )
            },
        },
        {
            field: "companyName",
            headerName: "company",
            sortable: false,
            flex: 1
        },
        {
            field: "serie_name",
            headerName: "serie",
            sortable: false,
            flex: 1
        },
        {
            field: "typeName",
            headerName: "type",
            sortable: false,
            flex: 1
        },
        {
            field: "categoryName",
            headerName: "Category",
            sortable: false,
            flex: 1
        },
        {
            field: "kwMm",
            headerName: "kwMm",
            sortable: false,
            flex: 1
        },
        {
            field: "price",
            headerName: "price",
            sortable: false,
            flex: 1
        },
        {
            field: "width",
            headerName: "width",
            sortable: false,
            flex: 1
        },
        {
            field: "imgMiddle2d",
            headerName: "img Middle 2d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgMiddle2d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgMiddle2d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "imgUpper2d",
            headerName: "img Upper 2d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgUpper2d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgUpper2d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "imgUpper3d",
            headerName: "img Upper 3d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgUpper3d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgUpper3d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "text_en_description",
            headerName: "description EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_description",
            headerName: "description FR",
            sortable: false,
            flex: 1
        },
        {
            field: "text_en_name",
            headerName: "name EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_name",
            headerName: "name FR",
            sortable: false,
            flex: 1
        },
        {
            field: "catalogue",
            headerName: "catalogue",
            sortable: true,
            flex: 1
        },
        {
            field: "partOfCompactType",
            headerName: "Part Of Compact Type",
            sortable: true,
            flex: 1
        },
        {
            field: "text_en_fileTechnicalSheets",
            headerName: "technical sheets EN",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.text_en_fileTechnicalSheets && (
                            <a href={params.text_en_fileTechnicalSheets} target="_blank">
                                <IconButton>
                                    <VscFilePdf/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        },
        {
            field: "text_fr_fileTechnicalSheets",
            headerName: "technical sheets fr",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.text_fr_fileTechnicalSheets && (
                            <a href={params.text_fr_fileTechnicalSheets} target="_blank">
                                <IconButton>
                                    <VscFilePdf/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        },
        {
            field: "fileDwg",
            headerName: "DWG",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params?.fileDwg && (
                            <a href={params.fileDwg} target="_blank">
                                <IconButton>
                                    <VscFile/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        }
    ]
}

function Type2() {
    return [
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <b>{params.code}</b>
                )
            },
        },
        {
            field: "companyName",
            headerName: "company",
            sortable: false,
            flex: 1
        },
        {
            field: "serie_name",
            headerName: "serie",
            sortable: false,
            flex: 1
        },
        {
            field: "typeName",
            headerName: "type",
            sortable: false,
            flex: 1
        },
        {
            field: "categoryName",
            headerName: "Category",
            sortable: false,
            flex: 1
        },
        {
            field: "kwMm",
            headerName: "kwMm",
            sortable: false,
            flex: 1
        },
        {
            field: "price",
            headerName: "price",
            sortable: false,
            flex: 1
        },
        {
            field: "width",
            headerName: "width",
            sortable: false,
            flex: 1
        },
        {
            field: "productUpperCode",
            headerName: "Product Code Upper",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.productUpperCode}`} target="_blank">
                        {params.productUpperCode}
                    </a>
                )
            },
        },
        {
            field: "imgLower2d",
            headerName: "img Lower 2d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgLower2d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgLower2d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "imgLower3d",
            headerName: "img Lower 3d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgLower3d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgLower3d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "text_en_description",
            headerName: "description EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_description",
            headerName: "description FR",
            sortable: false,
            flex: 1
        },
        {
            field: "text_en_name",
            headerName: "name EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_name",
            headerName: "name FR",
            sortable: false,
            flex: 1
        },
        {
            field: "catalogue",
            headerName: "catalogue",
            sortable: true,
            flex: 1
        },
        {
            field: "partOfCompactType",
            headerName: "Part Of Compact Type",
            sortable: true,
            flex: 1
        },
        {
            field: "text_en_fileTechnicalSheets",
            headerName: "technical sheets EN",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.text_en_fileTechnicalSheets && (
                            <a href={params.text_en_fileTechnicalSheets} target="_blank">
                                <IconButton>
                                    <VscFilePdf/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        },
        {
            field: "text_fr_fileTechnicalSheets",
            headerName: "technical sheets EN",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.text_fr_fileTechnicalSheets && (
                            <a href={params.text_fr_fileTechnicalSheets} target="_blank">
                                <IconButton>
                                    <VscFilePdf/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        }
    ]
}

function Type3() {
    return [
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <b>{params.code}</b>
                )
            },
        },
        {
            field: "companyName",
            headerName: "company",
            sortable: false,
            flex: 1
        },
        {
            field: "serie_name",
            headerName: "serie",
            sortable: false,
            flex: 1
        },
        {
            field: "typeName",
            headerName: "type",
            sortable: false,
            flex: 1
        },
        {
            field: "categoryName",
            headerName: "Category",
            sortable: false,
            flex: 1
        },
        {
            field: "width",
            headerName: "width",
            sortable: false,
            flex: 1
        },
        {
            field: "imgLower2d",
            headerName: "img Lower 2d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgLower2d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgLower2d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "text_en_description",
            headerName: "description EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_description",
            headerName: "description FR",
            sortable: false,
            flex: 1
        },
        {
            field: "catalogue",
            headerName: "catalogue",
            sortable: true,
            flex: 1
        },
        {
            field: "productElementLowerPosition",
            headerName: "potition",
            sortable: true,
            flex: 1
        },
    ]
}

function Type4() {
    return [
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <b>{params.code}</b>
                )
            },
        },
        {
            field: "companyName",
            headerName: "company",
            sortable: false,
            flex: 1
        },
        {
            field: "serie_name",
            headerName: "serie",
            sortable: false,
            flex: 1
        },
        {
            field: "typeName",
            headerName: "type",
            sortable: false,
            flex: 1
        },
        {
            field: "categoryName",
            headerName: "Category",
            sortable: false,
            flex: 1
        },
        {
            field: "price",
            headerName: "price",
            sortable: false,
            flex: 1
        },
        {
            field: "width",
            headerName: "width",
            sortable: false,
            flex: 1
        },
        {
            field: "productUpperCode",
            headerName: "Product Code Upper",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params?.upper?.code}`} target="_blank">
                        {params?.upper?.code}
                    </a>
                )
            },
        },
        {
            field: "productLowerCode",
            headerName: "Product Code Lower",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params?.lower?.code}`} target="_blank">
                        {params?.lower?.code}
                    </a>
                )
            },
        },
        {
            field: "text_en_description",
            headerName: "description EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_description",
            headerName: "description FR",
            sortable: false,
            flex: 1
        },
        {
            field: "text_en_name",
            headerName: "name EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_name",
            headerName: "name FR",
            sortable: false,
            flex: 1
        },
        {
            field: "catalogue",
            headerName: "catalogue",
            sortable: true,
            flex: 1
        }
    ]
}

function Type5() {
    return [
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <b>{params.code}</b>
                )
            },
        },
        {
            field: "companyName",
            headerName: "company",
            sortable: false,
            flex: 1
        },
        {
            field: "serie_name",
            headerName: "serie",
            sortable: false,
            flex: 1
        },
        {
            field: "typeName",
            headerName: "type",
            sortable: false,
            flex: 1
        },
        {
            field: "categoryName",
            headerName: "Category",
            sortable: false,
            flex: 1
        },
        {
            field: "price",
            headerName: "price",
            sortable: false,
            flex: 1
        },
        {
            field: "width",
            headerName: "width",
            sortable: false,
            flex: 1
        },
        {
            field: "imgCompact3d",
            headerName: "img compact 3d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgCompact3d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgCompact3d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "productUpperCode",
            headerName: "Product Code Upper",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.upper.code}`} target="_blank">
                        {params.upper.code}
                    </a>
                )
            },
        },
        {
            field: "productLowerCode",
            headerName: "Product Code Lower",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.lower.code}`} target="_blank">
                        {params.lower.code}
                    </a>
                )
            },
        },
        {
            field: "text_en_description",
            headerName: "description EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_description",
            headerName: "description FR",
            sortable: false,
            flex: 1
        },
        {
            field: "text_en_name",
            headerName: "name EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_name",
            headerName: "name FR",
            sortable: false,
            flex: 1
        },
        {
            field: "catalogue",
            headerName: "catalogue",
            sortable: true,
            flex: 1
        },
        {
            field: "fileDwg",
            headerName: "DWG",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params?.fileDwg && (
                            <a href={params.fileDwg} target="_blank">
                                <IconButton>
                                    <VscFile/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        }
    ]
}

function TypeAll() {
    return [
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <b>{params.code}</b>
                )
            },
        },
        {
            field: "companyName",
            headerName: "company",
            sortable: false,
            flex: 1
        },
        {
            field: "serie_name",
            headerName: "serie",
            sortable: false,
            flex: 1
        },
        {
            field: "typeName",
            headerName: "type",
            sortable: false,
            flex: 1
        },
        {
            field: "categoryName",
            headerName: "Category",
            sortable: false,
            flex: 1
        },
        {
            field: "kwMm",
            headerName: "kwMm",
            sortable: false,
            flex: 1
        },
        {
            field: "price",
            headerName: "price",
            sortable: false,
            flex: 1
        },
        {
            field: "width",
            headerName: "width",
            sortable: false,
            flex: 1
        },
        {
            field: "productUpperCode",
            headerName: "Product Code Upper",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.productUpperCode}`} target="_blank">
                        {params.productUpperCode}
                    </a>
                )
            },
        },
        {
            field: "productLowerCode",
            headerName: "Product Code Lower",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.productLowerCode}`} target="_blank">
                        {params.productLowerCode}
                    </a>
                )
            },
        },
        {
            field: "imgLower2d",
            headerName: "img Lower 2d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgLower2d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgLower2d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "imgMiddle2d",
            headerName: "img Middle 2d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgMiddle2d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgMiddle2d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "imgUpper2d",
            headerName: "img Upper 2d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgUpper2d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgUpper2d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "imgUpper3d",
            headerName: "img Upper 3d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgUpper3d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgUpper3d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "imgLower3d",
            headerName: "img Lower 3d",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.imgLower3d} target="_blank">
                        <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                            <img src={params.imgLower3d} style={{width: "100%", maxHeight: "70px"}}/>
                        </div>
                    </a>
                )
            },
        },
        {
            field: "text_en_description",
            headerName: "description EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_description",
            headerName: "description FR",
            sortable: false,
            flex: 1
        },
        {
            field: "text_en_name",
            headerName: "name EN",
            sortable: false,
            flex: 1
        },
        {
            field: "text_fr_name",
            headerName: "name FR",
            sortable: false,
            flex: 1
        },
        {
            field: "catalogue",
            headerName: "catalogue",
            sortable: true,
            flex: 1
        },
        ,
        {
            field: "text_en_fileTechnicalSheets_filename",
            headerName: "technical sheets EN",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.text_en_fileTechnicalSheets_filename && (
                            <a href={params.text_en_fileTechnicalSheets_filename} target="_blank">
                                <IconButton>
                                    <VscFilePdf/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        },
        {
            field: "text_fr_fileTechnicalSheets_filename",
            headerName: "technical sheets EN",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.text_fr_fileTechnicalSheets_filename && (
                            <a href={params.text_fr_fileTechnicalSheets_filename} target="_blank">
                                <IconButton>
                                    <VscFilePdf/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        },
        {
            field: "fileDwg",
            headerName: "DWG",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params?.fileDwg && (
                            <a href={params.fileDwg} target="_blank">
                                <IconButton>
                                    <VscFile/>
                                </IconButton>
                            </a>
                        )}
                    </>
                )
            }
        }
    ]
}
