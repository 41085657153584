import * as React from "react";
export default function ViewProduct(props) {
    const {element, onClick, key} = props

    const maxWidth = element?.width > 1000 ? 210 : 100

    return (
        <div
            key={key}
            style={{display: 'block', cursor: 'pointer', height: 185}}
            onClick={() => onClick(element)}
        >
            <div
                style={{
                    width: 150,
                    height: 100,
                    background: '#fff',
                    borderTop: '1px solid #dadada',
                    borderLeft: '1px solid #dadada',
                    borderRight: '1px solid #dadada',
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginRight: 20,
                    padding: 5
                }}
            >
                <img
                    src={element?.img}
                    style={{width: maxWidth < 150 ? maxWidth : 150, maxHeight: maxWidth < 150 ? maxWidth : 150, marginLeft: 'auto', marginRight: 'auto', display: 'block'}}
                    alt={""}
                />
            </div>

            <div
                style={{
                    width: 150,
                    background: '#fff',
                    borderBottom: '1px solid #dadada',
                    borderLeft: '1px solid #dadada',
                    borderRight: '1px solid #dadada',
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    marginRight: 20,
                    padding: 5,
                    maxHeight: 130,
                    minHeight: 40
                }}
            >
                <div style={{fontSize: 12, width: '100%', textAlign: 'center'}} >
                    {element?.name}
                </div>
            </div>

        </div>
    )
}
