import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import {makeStyles} from "@mui/styles";

/**
 * Muestra un input de de fecha
 *
 * ## PROPS
 * - **value** *timestamp*
 * - **name** *string*
 * - **label** *string*
 * - **onChange** *function*
 */
export default function DatePickerSimple(props) {

    const {
        value,
        name,
        label,
        onChange,
        disabled,
        required
    } = props

    const classes = useStyles()

    const valueIn = value ? moment.unix(value).format("YYYY-MM-DD") : null
    const disabledIn = disabled ? disabled : false
    const requiredIn = required ? required : false

    const handleChange = (newValue) => {
        onChange({target: {name: name, value: moment(newValue).format("X")}})
    }

    return (
            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <Stack>
                    <DesktopDatePicker
                        label={`${label} ${requiredIn ? "*" : ""}`}
                        name={name}
                        size="small"
                        disabled={disabledIn}
                        InputLabelProps={{shrink: true}}
                        inputFormat="dd/MM/yyyy"
                        value={valueIn}
                        onChange={handleChange}
                        className={classes.root}
                        renderInput={(params) => <TextField {...params} size="small" style={{fontSize: 10}}/>}
                        InputAdornmentProps={{ position: "start" }}
                        inputProps={{
                            style: {
                                padding: '7px 12px',
                                fontSize: '14px',
                            }
                        }}
                    />
                </Stack>
            </LocalizationProvider>
    )
}

const useStyles = makeStyles({
    root: {
        "& .MuiInputBase-root": {
            padding: '0px 7px',
            "& .MuiButtonBase-root": {
                padding: 0,
                paddingLeft: 10
            },
            "& .MuiInputBase-input": {
                padding: 15,
                paddingLeft: 0
            }
        }
    }
})
