import * as React from "react";
import InputButtonLoading from "./Inputs/InputButtonLoading";
import InputButton from "./Inputs/InputButton";
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import {VscChromeClose} from "react-icons/vsc";
import Box from "@mui/material/Box";

/**
 * Muestra un modal
 *
 * ## PROPS
 * - **title** *string*
 * - **btnOk** *string*
 * - **btnCancel** *string*
 * - **open**
 * - **setOpen**
 * - **handleClick** *function*
 * - **loading** *bool*
 */
export default function ModalIn(props) {

    const {title, btnOk, btnCancel, open, setOpen, handleClick, loading, handleClose} = props

    const handleCloseIn = () => {
        setOpen(false)
        handleClose()
    }

    return (
        <div>
            <Modal
                open={open}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter: ' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={1} style={{margin: 0}}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>{title}</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleCloseIn}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>

                        {props.children}

                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={btnCancel}
                                onClick={handleCloseIn}
                                color="secondary"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButtonLoading
                                onClick={handleClick}
                                loading={loading}
                                label={`${btnOk}`}
                                style={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
