import React from "react";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";
import {BASE_PATH} from "../../../utils/constants";

export default function MenuHeader(props) {
    const {menu, menuChild} = props

    return (
        <div id="admin-menu-left">
            <MenuLogs menu={menu} menuChild={menuChild}/>
            <MenuDoc menu={menu} menuChild={menuChild}/>
            <MenuProduct menu={menu} menuChild={menuChild}/>
            <MenuImport menu={menu} menuChild={menuChild}/>
        </div>
    )
}

function MenuImport(props) {
    const {menu, menuChild} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'import') {
        return (
            <>
                <h3>Import</h3>
                <MenuLink type="import-manager" menu={menuChild} to={RouteAdmin.IMPORT_INDEX.path} name="Import manager" />
                <MenuLink type="media" menu={menuChild} to={RouteAdmin.IMPORT_MEDIA.path} name="Media" />
                <MenuLink type="history" menu={menuChild} to={RouteAdmin.IMPORT_HISTORY.path} name="History" />
            </>
        )
    }
}

function MenuProduct(props) {
    const {menu, menuChild} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'product') {
        return (
            <>
                <h3>Products</h3>
                <MenuLink type="product" menu={menuChild} to={RouteAdmin.PRODUCT_MANAGEMENT.path} name="Products" />
                <MenuLink type="product-incompatibility" menu={menuChild} to={RouteAdmin.PRODUCT_INCOMPATIBILITY_MANAGEMENT.path} name="Incompatibility"/>
                <MenuLink type="product-incompatibility-upper" menu={menuChild} to={RouteAdmin.PRODUCT_INCOMPATIBILITY_UPPER_MANAGEMENT.path} name="Incompatibility SBR"/>

                <br/>

                <h3>Options</h3>
                <MenuLink type="options" menu={menuChild} to={RouteAdmin.OPTIONS_MANAGEMENT.path} name="Options" />

                <br/>

                <h3>Relationships</h3>
                <MenuLink type="product-options" menu={menuChild} to={RouteAdmin.PRODUCT_OPTIONS_MANAGEMENT.path} name="Products Options"/>
                <MenuLink type="product-lower-elements" menu={menuChild} to={RouteAdmin.PRODUCT_LOWER_ELEMENTS_MANAGEMENT.path} name="Products Elements SBR"/>

                <br/>

                <h3>Selector</h3>
                <MenuLink type="category" menu={menuChild} to={RouteAdmin.CATEGORY_MANAGEMENT.path} name="Category"/>
                <MenuLink type="serie" menu={menuChild} to={RouteAdmin.SERIE_MANAGEMENT.path} name="Range"/>

            </>
        )
    }
}

function MenuLogs(props) {
    const {menu, menuChild} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'log') {
        return (
            <>
                <h3>Logs</h3>
                <MenuLink type="report" menu={menuChild} to={RouteAdmin.LOG_REPORT.path} name="Reports" />
                <MenuLink type="mail" menu={menuChild} to={RouteAdmin.LOG_MAIL.path} name="Mails"/>
                <MenuLink type="sms" menu={menuChild} to={RouteAdmin.LOG_SMS.path} name="SMS"/>
            </>
        )
    }
}

function MenuDoc(props) {
    const {menu, menuChild} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'doc') {
        return (
            <>
                <h3>Doc</h3>
                <MenuLink type="install" menu={menuChild} to={RouteAdmin.DOC_INSTALL.path} name="Repos" />
                <MenuHref type="apiDos" menu={menuChild} to={`${BASE_PATH}/api/doc`} name="API DOC"/>
            </>
        )
    }
}

function MenuLink(props) {
    const {to, name, menu, type} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }


    return (
        <>
            <div className="menu-links">
                <Link
                    to={to}
                    className={isActive ? 'active' : ''}
                >
                    {name}
                </Link>
            </div>
        </>
    )
}

function MenuHref(props) {
    const {to, name} = props

    return (
        <>
            <div className="menu-links">
                <a
                    href={to}
                    target="_blank"
                >
                    {name}
                </a>
            </div>
        </>
    )
}
