import React, {useEffect, useState} from "react";
import {apiList, apiUpload, apiDelete} from "../../../services/Admin/media";
import {Button, Grid, CircularProgress, Box} from "@mui/material";
import ButtonFile from "../../Inputs/ButtonFile";
import MsgError from "../../MsgError";
import MsgSuccess from "../../MsgSuccess";
import HeaderPage from "../../Admin/HeaderPage";
import Modal from "../../Modal";
import {
    BsCheckCircleFill
} from "react-icons/bs";
import {
    VscFilePdf
} from "react-icons/vsc";
import {useTranslation} from "react-i18next";

export default function Management(props) {
    const {i18n} = useTranslation()
    const {selectElementsReturn, setSelectElementsReturn, canBeSelected, selectedMultiple, onlyShow} = props

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [loadingRemove, setLoadingRemove] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fileRemove, setFileRemove] = useState({url: null, id: null, key: null, type: null})
    const [openModalRemove, setOpenModalRemove] = useState(false)
    const [noImage, setNoImage] = useState(false)
    const [selectElementsNumber, setSelectElementsNumber] = useState(0)
    const [fileLimit, setFileLimit] = useState(50)
    const [fileOffset, setFileOffset] = useState(0)
    const [fileTotal, setFileTotal] = useState(null)

    const [state, setState] = useState(initialValues())

    useEffect(() => {
        getMediaData()
    }, [])

    const getMediaData = () => {
        setLoading(true)
        apiList({limit: fileLimit, offset: fileOffset, onlyShow: onlyShow}, i18n.resolvedLanguage).then(resp => {
            state["image"] = state.image.concat(resp.data?.medias)

            setState({...state})
            setLoading(false)
            setFileTotal(resp.data?.total)
            if (resp.data?.data.length == 0) {
                setNoImage(true)
            }
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingAdd(true)

        let f = new FormData()
        for (let value of state.newImage) {
            f.append("file[]", value.binary)
        }

        apiUpload(f, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            state["image"] = resp.data?.data.concat(state.image)
            state["newImage"] = []
            setState({...state})
            setLoadingAdd(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingAdd(false)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
        setNoImage(false)
        handleSubmit()
    }

    const handleDelete = () => {
        setLoadingRemove(true)
        setMsgError([])
        apiDelete(fileRemove.id, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            state.image.splice(fileRemove.key, 1)
            setState({...state, image: state.image})
            setFileRemove({url: null, id: null, key: null, type: null})
            setOpenModalRemove(false)
            setLoadingRemove(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setOpenModalRemove(false)
            setLoadingRemove(false)
        })
    }

    const handleDeleteModal = (url, id, key, type) => {
        setFileRemove({url, id, key, type})
        setOpenModalRemove(true)
    }

    const handleSelectElement = (id, key) => {

        if (state["image"][key]["select"]) {
            state["image"][key]["select"] = false
            setSelectElementsNumber(selectElementsNumber - 1)
        } else {
            if ((!selectedMultiple && selectElementsNumber === 0) || selectedMultiple) {
                state["image"][key]["select"] = true
                setSelectElementsNumber(selectElementsNumber + 1)
            }
        }

        setState({...state})
    }

    useEffect(() => {
        let temp = []
        state.image.forEach(item => {
            if (item?.select) {
                temp.push(item)
            }
        })
        if (typeof selectElementsReturn === 'undefined' ) {
        } else {
            setSelectElementsReturn(temp)
        }
    }, [state])

    const moreImage = () => {
        setFileOffset(fileLimit)
        setFileLimit(fileLimit + 50)
        getMediaData()
    }

    return (<>
        <HeaderPage title="Media">
            <div style={{float: "right", margin: "0px 0px 10px 0px"}}>
                <ButtonFile
                    label="+ Add"
                    loading={loadingAdd}
                    required
                    name="newImage"
                    onChange={handleChange}
                    multiple={true}
                />
            </div>
        </HeaderPage>
        <Grid container spacing={1}>

            <Grid xs={12}>
                <MsgError msg={msgError}/>
                <MsgSuccess msg={msgSuccess}/>
            </Grid>

            <Grid item xs={12}>
                <div sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                    {loading && (
                        <Box sx={{maxWidth: "50px", marginLeft: "auto", marginRight: "auto", marginTop: "100px", marginBottom: "100px"}}>
                            <CircularProgress />
                        </Box>
                    )}
                    {noImage && (
                        <div>
                            <h2 style={{textAlign: "center", marginTop: "30px"}}>Press the button below to upload images</h2>
                            <div style={{maxWidth: "200px", marginLeft: "auto", marginRight: "auto"}}>
                                <ButtonFile
                                    label="Add images"
                                    loading={loadingAdd}
                                    required
                                    name="newImage"
                                    onChange={handleChange}
                                    multiple={true}
                                />
                            </div>
                        </div>
                    )}
                    {state.image && (
                        <Grid container spacing={2}>
                            {state.image.map((item, key) => (
                                <Grid item xs={3}>
                                    <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "150px"}}>
                                        <div
                                            onClick={() => {
                                                handleSelectElement(item.id, key)
                                            }}
                                            style={{width: "150px", height: "150px", background: "#fff", border: "1px solid #dadada"}}
                                        >
                                            {(item.type === 'png' || item.type === 'jpg') && (
                                                <img
                                                    src={item.url}
                                                    srcSet={item.url}
                                                    alt={item.name}
                                                    style={{width: "100%", maxHeight: "150px", padding: "5px"}}
                                                />
                                            )}
                                            {item.type === 'pdf' && (
                                                <VscFilePdf style={{padding: "5px", fontSize: "100px", marginLeft: "25px", marginTop: "25px"}}/>
                                            )}
                                        </div>
                                        <div style={{float: "left"}}>
                                            <Button color='primary' onClick={() => {
                                                handleDeleteModal(`${item.url}`, item.id, key, `${item.type}`)
                                            }}>
                                                Delete
                                            </Button>
                                        </div>
                                        <div style={{float: "right", marginTop: "7px", marginRight: "7px"}}>
                                            {(item?.select && canBeSelected) && (
                                                <BsCheckCircleFill />
                                            )}
                                        </div>

                                    </div>
                                </Grid>
                            ))}
                            {fileLimit < fileTotal && (
                                <Grid item xs={12}>
                                    <Button variant="outlined" fullWidth style={{marginTop: "20px"}} onClick={moreImage}>
                                        More Image
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </div>
            </Grid>
        </Grid>
        <Modal
            title="Delete"
            btnOk="Delete"
            btnCancel="Cancel"
            open={openModalRemove}
            setOpen={setOpenModalRemove}
            handleClick={handleDelete}
            loading={loadingRemove}
            BackdropProps={{
                style: {
                    background: 'rgba(0, 0, 0, .5)',
                    backdropFilter:' blur(5px)',
                },
            }}
        >
            Are you sure you want to delete this element?

            <div style={{width: "400px", marginLeft: "auto", marginRight: "auto", marginTop: "20px", display: "block"}}>
                {(fileRemove.type === 'png' || fileRemove.type === 'jpg') && (
                    <img
                        src={fileRemove.url}
                        srcSet={fileRemove.url}
                        alt=""
                        loading="lazy"
                        style={{width: "400px", marginLeft: "auto", marginRight: "auto", marginTop: "20px", display: "block"}}
                    />
                )}
                {fileRemove.type === 'pdf' && (
                    <VscFilePdf style={{fontSize: "100px"}}/>
                )}
            </div>
        </Modal>
    </>)
}

function initialValues(props) {
    return {
        image: props?.image || [], newImage: props?.newImage || []
    }
}
