import React from "react";

export default function TableColumns(type) {
    return [
        {
            field: "code",
            headerName: "code",
            sortable: false,
            flex: 1,
        },
        {
            field: "price",
            headerName: "price",
            sortable: false,
            flex: 1,
        },
        {
            field: "text_en_description",
            headerName: "description en",
            sortable: false,
            flex: 1,
        },
        {
            field: "text_fr_description",
            headerName: "description fr",
            sortable: false,
            flex: 1,
        }
    ]
}
