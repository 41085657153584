import * as React from 'react';
import {
    VscBook,
    VscDebug,
    VscDebugLineByLine,
    VscExtensions,
    VscInfo,
    VscSettings,
    VscSettingsGear,
    VscBell,
    VscUnverified,
    VscVerified,
    VscSave,
    VscSaveAs,
    VscSymbolField
} from "react-icons/vsc";
import {CircularProgress, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import LoadingSpinner from "../../../../components/LoadingSpinner";

export default function MenuRight(props) {
    const {
        showMenu,
        setShowMenu,
        notifications,
        totalIncomplete,
        incompleteLoading,
        loadingAutoSave,
        setLoadingAutoSave
    } = props

    const {auth} = useAuth()
    const {t} = useTranslation()

    const handleShowMenu = (type) => {
        if (type === showMenu) {
            setShowMenu("")
        } else {
            setShowMenu(type)
        }
    }

    const colorAlert = '#FF0000'

    return (
        <div>
            <div id="box-right-menu-icons">
                <Tooltip title={t('composer.Module description & Options')} placement="left">
                    <button
                        className={`btn-lateral-menu ${showMenu === 'INFO-OPTIONS' ? 'btn-lateral-menu-active' : ''}`}
                        onClick={() => handleShowMenu('INFO-OPTIONS')}
                    >
                        <VscInfo/>
                    </button>
                </Tooltip>

                <Tooltip title={t('composer.Custom products')} placement="left">
                    <button
                        className={`btn-lateral-menu ${showMenu === 'CUSTOM_PRODUCTS' ? 'btn-lateral-menu-active' : ''}`}
                        onClick={() => handleShowMenu('CUSTOM_PRODUCTS')}
                    >
                        <VscExtensions/>
                    </button>
                </Tooltip>

                <Tooltip title={t('catalogue.Catalogue')} placement="left">
                    <button
                        className={`btn-lateral-menu ${showMenu === 'CATALOGUE' ? 'btn-lateral-menu-active' : ''}`}
                        onClick={() => handleShowMenu('CATALOGUE')}
                    >
                        <VscBook/>
                    </button>
                </Tooltip>

                <Tooltip title="Accessories" placement="left">
                    <button
                        className={`btn-lateral-menu ${showMenu === 'ACCESSORIES' ? 'btn-lateral-menu-active' : ''}`}
                        onClick={() => handleShowMenu('ACCESSORIES')}
                    >
                        <VscSymbolField />
                    </button>
                </Tooltip>

                <Tooltip title={t('composer.Incompatibility')} placement="left">
                    <button
                        className={`btn-lateral-menu ${showMenu === 'INCOMPATIBILITY' ? 'btn-lateral-menu-active' : ''}`}
                        onClick={() => handleShowMenu('INCOMPATIBILITY')}
                    >
                        {/*{incompleteLoading ? <CircularProgress sx={{color: '#bababa'}} size={20}/> : (*/}
                        {/*    <>*/}
                        {/*        {totalIncomplete > 0 ? (*/}
                        {/*            <div className="box-incomplete">*/}
                        {/*                {showMenu === 'INCOMPATIBILITY' ? <VscUnverified/> :*/}
                        {/*                    <VscUnverified color={colorAlert}/>}*/}

                        {/*                <span*/}
                        {/*                    className={`number-alerts-menu ${showMenu === 'INCOMPATIBILITY' ? 'number-alerts-menu-active' : ''}`}>{totalIncomplete}</span>*/}
                        {/*            </div>*/}
                        {/*        ) : (*/}
                        {/*            <VscVerified/>*/}
                        {/*        )}*/}
                        {/*    </>*/}
                        {/*)}*/}

                        {incompleteLoading ? <VscUnverified/> : (
                            <>
                                {totalIncomplete > 0 ? (
                                    <div className="box-incomplete">
                                        <VscUnverified/>
                                            <span
                                                className={`number-alerts-menu ${showMenu === 'INCOMPATIBILITY' ? 'number-alerts-menu-active' : ''}`}
                                            >
                                                {totalIncomplete}
                                            </span>
                                    </div>
                                ) : (
                                    <VscVerified/>
                                )}
                            </>
                        )}
                    </button>
                </Tooltip>

                {/*<Tooltip title={t('composer.Notifications')} placement="left">*/}
                {/*    <button*/}
                {/*        className={`btn-lateral-menu ${showMenu === 'NOTIFICATIONS' ? 'btn-lateral-menu-active' : ''}`}*/}
                {/*        onClick={() => handleShowMenu('NOTIFICATIONS')}*/}
                {/*    >*/}
                {/*        {notifications.length > 0 ? (*/}
                {/*            <div className="box-notifications">*/}
                {/*                {showMenu === 'NOTIFICATIONS' ? <VscBell /> : <VscBell color={colorAlert}/>}*/}

                {/*                <span className={`number-notifications ${showMenu === 'NOTIFICATIONS' ? 'number-notifications-active' : ''}`}>{notifications.length}</span>*/}
                {/*            </div>*/}
                {/*        ) : (*/}
                {/*            <VscBell />*/}
                {/*        )}*/}
                {/*    </button>*/}
                {/*</Tooltip>*/}


                {auth?.secondaryUser.role === 'ROLE_ADMIN' && (
                    <Tooltip title="Debugger" placement="left">
                        <button
                            className={`btn-lateral-menu ${showMenu === 'DEBUGGER' ? 'btn-lateral-menu-active' : ''}`}
                            onClick={() => handleShowMenu('DEBUGGER')}
                        >
                            <VscDebug/>
                        </button>
                    </Tooltip>
                )}

                <div style={{position: 'fixed', bottom: 0, right: 8}}>
                    <Tooltip title={t('general.Save')} placement="left">
                        <button
                            className={`btn-lateral-menu`}
                            onClick={() => setLoadingAutoSave(true)}
                        >
                            {loadingAutoSave ? (<VscSaveAs size={27}/>) : (<VscSave/>)}
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}
