import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import InputText from "../../../../../../components/Inputs/InputText";
import React from "react";
import {TbPercentage} from "react-icons/tb";

export default function Formulario(props) {
    const {
        state,
        setState,
    } = props

    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    return (
        <>
            <Grid item md={12}>
                <InputText
                    label={t('catalogue.Product Code')}
                    required
                    name="productCode"
                    value={state?.productCode}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={t('general.Unit price')}
                    name="price"
                    value={state?.price}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={t('general.Quantity')}
                    required
                    name="quantity"
                    value={state?.quantity}
                    onChange={handleChange}
                />
            </Grid>


            <Grid item md={11}>
                <InputText
                    label={t('general.Discount')}
                    name="discount"
                    value={state?.discount}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item md={1}>
                <TbPercentage style={{fontSize: 30, marginTop: 5, float: 'right'}}/>
            </Grid>

            <Grid item md={12}>
                <InputText
                    label={t('general.Description')}
                    rows={4}
                    name="description"
                    value={state?.description}
                    onChange={handleChange}
                />
            </Grid>

        </>
    )
}
