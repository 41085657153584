import React from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../components/Inputs/InputText";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import {useTranslation} from "react-i18next";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

export default function Filters(props) {

    const {
        inpoutDisabled,
        state,
        setState,
        disabledExport,
        totalRows,
        handleClickExport,
        loadingExport
    } = props

    const {i18n} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>

            <Grid item xs={12}>
                <InputText
                    disabled={inpoutDisabled}
                    label="Accessories Code"
                    placeholder="code1, code2, code3, ..."
                    name="code"
                    value={state.code}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    disabled={inpoutDisabled}
                    label={`Description ( ${i18n.resolvedLanguage} )`}
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={2}>
                <div style={{float: 'right'}}>
                    <InputSwitch
                        label="is Active"
                        name="isActive"
                        checked={state?.isActive}
                        onChange={handleChangeChecked}
                    />
                </div>
            </Grid>

            <Grid item xs={4}>
                <InputButtonLoading
                    onClick={handleClickExport}
                    loading={loadingExport}
                    label={`Export results ${!disabledExport && totalRows}`}
                    variant="outlined"
                    style={{width: '100%'}}
                />
            </Grid>


        </Grid>
    )
}
