import React from "react";
import {Button, CircularProgress, TextField} from "@mui/material";
import color from "../../scss/Admin/colors.scss";
import {LoadingButton} from "@mui/lab";

/**
 * Muestra un botón con un spinner
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **onClick** *function*
 * - **loading** *boolean*
 * - **positionIn** *string* (Por defecto right)
 * - **variant** *string* (Por defecto contained)
 */
export default function InputButtonLoading(props) {

    const {label, onClick, loading, position, variant, style, disabled} = props

    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    const positionIn = position ? position : 'right'
    const variantIn = variant ? variant : 'contained'
    const disabledIn = disabled ? disabled : false
    return (
        <>
            <button
                type="button"
                onClick={onClick}
                className={variantIn === 'contained' ? `btn-primary` : `btn-primary-outline`}
                style={{
                    float: positionIn,
                    ...style
                }}
                disabled={loading || disabledIn}
            >
                {loading && (<CircularProgress style={{ color: color, marginRight: 10, marginBottom: '-2px' }} size={16}/>)}
                {label}

            </button>
        </>
    )
}
