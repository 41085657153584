import * as React from 'react';
import Grid from '@mui/material/Grid';
import logo from '../../../assets/images/logo-scaled.png'
import "../../../scss/Admin/index.scss"
import {Link} from "react-router-dom";
import MenuAccount from "../Admin/MenuAccount";
import {RouteUser} from "../../../routes/RouteUser";
import MenuLanguage from "../Admin/MenuLanguage";
import AdminBar from "../Admin/AdminBar";

export default function Basic(props){

    const {adminBar} = props

    return(
        <>
            {adminBar && <AdminBar />}

            <div id="admin-header">
                <div class="first-menu">
                    <Grid container>
                        <Grid item xs={8} sx={{
                            paddingBottom: "8px"
                        }}>
                            <Link
                                to={RouteUser.ROOT.path}
                            >
                                <img id="logo" src={logo} style={{marginLeft: 10, marginTop: 5}}/>
                            </Link>
                        </Grid>

                        <Grid item xs={4} sx={{
                            paddingBottom: "8px"
                        }}>
                            <div style={{display: 'flex'}}>
                                <MenuAccount />
                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>

            <div id="admin-content">
                {props.children}
            </div>
        </>
    )
}
