import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import DatePickerSimple from "../../../../../components/Inputs/DatePickerSimple";
import InputText from "../../../../../components/Inputs/InputText";
import InputSelect from "../../../../../components/Inputs/InputSelect";
import {TbPercentage} from "react-icons/tb";
import InputCheckbox from "../../../../../components/Inputs/InputCheckbox";
import React from "react";

export default function Formulario(props) {
    const {
        state,
        setState,
        loading
    } = props
    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const optionsType = [
        {id: 'Electric  Only', name: t('OPTIONS_GAZ.Electric units only')},
        {id: 'Natural gas - G20 Pn (20 mbar)', name: t('OPTIONS_GAZ.Natural gas - G20 Pn (20 mbar)')},
        {id: 'Natural gas - G25 Pn (20 mbar)', name: t('OPTIONS_GAZ.Natural gas - G25 Pn (20 mbar)')},
        {id: 'Natural gas - G25 Pn (25 mbar)', name: t('OPTIONS_GAZ.Natural gas - G25 Pn (25 mbar)')},
        {id: 'Butane gas - G30 Pn (29 mbar)', name: t('OPTIONS_GAZ.Butane gas - G30 Pn (29 mbar)')},
        {id: 'Butane gas - G30 Pn (50 mbar)', name: t('OPTIONS_GAZ.Butane gas - G30 Pn (50 mbar)')},
        {id: 'Propane gas - G31 Pn (37 mbar)', name: t('OPTIONS_GAZ.Propane gas - G31 Pn (37 mbar)')},
        {id: 'Propane gas - G31 Pn (50 mbar)', name: t('OPTIONS_GAZ.Propane gas - G31 Pn (50 mbar)')}
    ]

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <DatePickerSimple
                        label={t('quotation.Date')}
                        required
                        name="dateQuotation"
                        value={state?.dateQuotation}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </Grid>
                <Grid item md={6}>
                    <InputText
                        label={t('quotation.Company name')}
                        required
                        name="companyName"
                        value={state?.companyName}
                        onChange={handleChange}
                        disabled={loading}
                    />
                </Grid>
                <Grid item md={12}>
                    <br/>
                </Grid>
                <Grid item md={12}>
                    <InputText
                        label={t('quotation.Client Contact')}
                        required
                        name="clientContact"
                        value={state?.clientContact}
                        disabled={loading}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item md={6}>
                    <InputText
                        label={t('quotation.Client Address')}
                        required
                        name="clientAddress"
                        value={state?.clientAddress}
                        disabled={loading}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item md={6}>
                    <InputText
                        label={t('quotation.Client Location')}
                        required
                        name="clientLocation"
                        value={state?.clientLocation}
                        disabled={loading}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <br/>
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={t('quotation.Quotation name')}
                        required
                        name="name"
                        value={state?.name}
                        disabled={loading}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item md={12}>
                    <InputText
                        label={t('quotation.Quotation Notes')}
                        required
                        name="text"
                        value={state?.text}
                        rows={3}
                        disabled={loading}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <br/>
                </Grid>

                <Grid item md={12}>
                    <InputSelect
                        label={t('quotation.Type of gas')}
                        name="gasType"
                        required
                        onChange={handleChange}
                        value={state?.gasType}
                        disabled={loading}
                        options={optionsType}
                    />
                </Grid>
                <Grid item md={12}>
                    <br/>
                </Grid>
                <Grid item md={11}>
                    <InputText
                        label={t('quotation.Rosinox Discount')}
                        name="discountRosinox"
                        value={state?.discountRosinox}
                        disabled={loading}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item md={1}>
                    <TbPercentage style={{fontSize: 30, marginTop: 5, float: 'right'}}/>
                </Grid>

                <Grid item md={11}>
                    <InputText
                        label={t('quotation.Friginox Discount')}
                        name="discountFriginox"
                        value={state?.discountFriginox}
                        disabled={loading}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item md={1}>
                    <TbPercentage style={{fontSize: 30, marginTop: 5, float: 'right'}}/>
                </Grid>

                <Grid item md={12}>
                    <br/>
                </Grid>
                <Grid item md={12}>
                    <InputCheckbox
                        label={t('quotation.Show the VAT in the quotation')}
                        disabled={loading}
                        checked={state?.vatActive}
                        onChange={(e) => {
                            state.vatActive = e.target.checked
                            setState({...state})
                        }}
                    />
                </Grid>

            </Grid>
        </>
    )
}
