import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import {VscChromeClose} from "react-icons/vsc";
import Table from "../../../../../../components/Table/Table";
import {useTranslation} from "react-i18next";

export default function Index(props) {
    const {
        open,
        setOpen,
        product,
        setProduct,
    } = props

    const {t} = useTranslation()

    const [stateForm, setStateForm] = React.useState(initialValue)

    const handleClose = () => {
        setStateForm(initialValue())
        setOpen(false)
    }

    const handleChangeCell = (value, element) => {

        let code = element.productCode
        let quantity = value

        // Detectamos si el id existe en el array y lo añádimos o actualizamos array
        if (product.find(item => item.productCode === code)) {
            // Actualizamos quantity en el array
            product.map(item => {
                if (item.productCode === code) {
                    item.quantity = quantity
                }
            })

            // Eliminamos en el caso de que el quantity sea 0
            if (!quantity || quantity === '0' || quantity === 0) {
                product.splice(product.findIndex(item => item.code === code), 1)
            }
            setProduct([...product])
        } else if (quantity > 0) {
            let temp = {
                typeQuery: element.typeQuery,
                quantity: quantity,
                element: element
            }
            // Merge objects temp + element
            setProduct([...product, {...element, ...temp}])
        }

        // Recorremos la Row y actualizamos la cantidad
        product.map(item => {
            if (item.productCode === code) {
                item.quantity = quantity
            }
        })

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter:' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>{t('composer.Custom products')}</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>

                        <Grid item md={12}>
                            <Table
                                columns={TableColumns()}
                                rows={product}
                                handleChangeCell={handleChangeCell}
                                rowCount={product.length || 0}
                                filter={stateForm}
                                setFilter={setStateForm}
                                loading={false}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

function TableColumns() {
    const {t} = useTranslation()

    return [
        {
            field: "productCode",
            headerName: "code",
            sortable: false,
            flex: 1
        },
        {
            field: "price",
            headerName: t('general.Price') + ' (€)',
            sortable: false,
            flex: 1
        },
        {
            field: "description",
            headerName: t('general.Description'),
            sortable: false,
            flex: 1
        },
        {
            field: "discount",
            headerName: t('general.Discount') + ' (%)',
            sortable: false,
            flex: 1
        },
        {
            field: "quantity",
            headerName: t('general.Quantity'),
            sortable: false,
            number: true,
            flex: 1
        },
    ]
}

function initialValue()
{
    return {
        offset: 0,
        sort: {field: "code", sort: "desc"},
    }
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: '85vh',
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    padding: '20px 10px 30px 30px',
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
