import * as React from 'react';
import Header from "./Header";
import {Divider, List, ListItem, ListItemText} from "@mui/material";
import NoData from "../../../../components/User/NoData";
import {useTranslation} from "react-i18next";

export default function Notifications(props) {
    const {
        showMenu,
        notifications,
        setNotifications
    } = props
    const {t} = useTranslation()

    const removeNotification = () => {
        setNotifications([])
    }

    return (
        <div style={{maxWidth: 400}}>
            {showMenu === 'NOTIFICATIONS' && (
                <>
                    <Header title={t('composer.Notifications')}
                    >
                        <button onClick={removeNotification} className="btn-panel" style={{width: '100%'}}>{t('general.Clear')}</button>
                    </Header>

                    <div className="content">
                        <Contenido
                            notifications={notifications}
                            setNotifications={setNotifications}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

function Contenido(props) {

    const {
        notifications,
        setNotifications
    } = props

    return (
        <List sx={{ width: '100%'}}>
            {notifications.map((notification, index) => (
                <span key={index}>
                    <ListItem>
                        {notification}
                    </ListItem>
                    <Divider component="li" />
                </span>
            ))}

            <NoData show={(notifications.length === 0)} />
        </List>
    )
}
