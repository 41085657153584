import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}`;

export function apiLogMail(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.email) { query += `&email=${props.email}`; }
    if (props.role) { query += `&role=${props.role}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-mail/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiLogReport(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.email) { query += `&email=${props.email}`; }
    if (props.role) { query += `&role=${props.role}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-report/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiLogSms(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.phone) { query += `&phone=${props.phone}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-sms/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}
