import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {FcFolder} from "react-icons/fc";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {apiImportFile} from "../../../../services/Admin/import";
import {apiDeleteFile, apiListFile} from "../../../../services/Admin/export";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {LoadingButton} from "@mui/lab";
import ButtonFile from "../../../../components/Inputs/ButtonFile";
import InputButton from "../../../../components/Inputs/InputButton";
import {useTranslation} from "react-i18next";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import {VscFile} from "react-icons/vsc";
import downloaderFile from "../../../../utils/downloaderFile";

export default function Media() {
    const {i18n} = useTranslation()
    const [openFoler, setOpenFolder] = React.useState(null)
    const [openLever1, setOpenLever1] = React.useState(null)
    const [btnAdd, setBtnAdd] = React.useState(false)

    const [loading, setLoading] = React.useState(false)
    const [loadingNewFile, setLoadingNewFile] = React.useState(false)

    const [filesPrepare, setFilesPrepare] = React.useState([])
    const [files, setFiles] = React.useState([])
    const folders = structureS3()

    const handleOpenFolder = (path, btnAdd) => {
        setBtnAdd(btnAdd)
        if (openFoler === null) {
            setOpenFolder(path)
        } else {
            setOpenLever1(path)
        }
    }

    useEffect(() => {
        setFiles([])
        if (btnAdd) {
            getFile()
        }
    }, [openFoler, openLever1, btnAdd])

    const getFile = () => {
        if (
            ((openFoler === 'dwg' || openFoler === 'technical-sheets') && openLever1 === null) ||
            (openFoler === 'lower' && openLever1 !== null) ||
            (openFoler === 'upper' && openLever1 !== null) ||
            (openFoler === 'compact' && openLever1 !== null) ||
            (openFoler === 'middle' && openLever1 !== null)
        ) {
            setLoading(true)
            setFiles([])

            let params = {
                type: openFoler,
            }

            if (openLever1) {
                params = {
                    type: openFoler,
                    format: openLever1
                }
            }
            apiListFile(params, i18n.resolvedLanguage).then(resp => {
                setFiles(resp.data)
                setLoading(false)
            }).catch(err => {
                setFiles([])
                setLoading(false)
            })
        }
    }

    const handleSubmit = () => {
        setLoadingNewFile(true)

        let f = new FormData()
        for (let value of filesPrepare) {
            f.append("files[]", value.binary)
        }

        let path = openFoler
        if (openLever1) {
            path = `${openFoler}/${openLever1}`
        }
        f.append("folder", path)

        apiImportFile(f, i18n.resolvedLanguage).then(resp => {
            getFile()
            setFilesPrepare([])
            setLoadingNewFile(false)
        }).catch(err => {
            setFilesPrepare([])
            setLoadingNewFile(false)
        })
    }

    const handleDeleteFile = (fileName) => {
        setLoading(true)
        let params = {
            type: openFoler,
            fileName: fileName
        }

        if (openLever1) {
            params = {
                type: openFoler,
                format: openLever1,
                fileName: fileName
            }
        }
        apiDeleteFile(params, i18n.resolvedLanguage).then(resp => {
            getFile()
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    return (
        <>
            <HeaderPage title="Media"/>

            <Grid container spacing={1}>
                <Grid item xs={6} style={{display: loading ? 'none' : 'block'}}>
                    <p style={{cursor: 'pointer'}}>
                        <span onClick={() => {
                            setOpenFolder(null)
                            setOpenLever1(null)
                            setFilesPrepare([])
                            setBtnAdd(false)
                        }}>{'/home'}</span>
                        {openFoler !== null && (
                            <span onClick={() => {
                                setOpenLever1(null)
                                setFilesPrepare([])
                            }}>
                            {'/'}{openFoler}
                            </span>
                        )}
                        {openLever1 !== null && (
                            <span>
                                {'/'}{openLever1}
                            </span>
                        )}
                    </p>
                </Grid>
                {files.length > 0 ? (
                    <Grid item xs={2} style={{display: loading ? 'none' : 'block'}}>
                        <div style={{float: 'right', height: 40}}>
                            <div style={{display: 'flex'}}>
                                <p>{`Total image: ${files.length}`}</p>
                            </div>
                        </div>
                    </Grid>
                ) : (
                    <Grid item xs={2}></Grid>
                )}

                {btnAdd && (
                    <Grid item xs={4} style={{display: loading ? 'none' : 'block'}}>
                        {filesPrepare.length > 0 ? (
                            <div style={{display: 'flex'}}>
                                <InputButton
                                    text="Cancel"
                                    onClick={() => setFilesPrepare([])}
                                    color="secondary"
                                    style={{marginTop: 8, height: 37}}
                                    variant="outlined"
                                />

                                <div style={{padding: 10}}>
                                    <InputButtonLoading
                                        onClick={handleSubmit}
                                        loading={loadingNewFile}
                                        label={`Accept and upload`}
                                        style={{width: '100%'}}
                                    />
                                </div>
                            </div>
                        ) : (
                            <ButtonFile
                                label="+ Add"
                                loading={loadingNewFile}
                                required
                                name="filesPrepare"
                                onChange={(e) => setFilesPrepare(e.target.value)}
                                multiple={true}
                            />
                        )}
                    </Grid>
                )}

                <Grid item xs={12} style={{display: loading ? 'none' : 'block'}}>

                    {/* CARPETAS PREVIEW */}

                    <div style={{display: 'flex'}}>
                        {openLever1 === null && folders.map((folder, index) => (
                            <>
                                {openFoler !== null ? (
                                    <>
                                        {folder.path === openFoler && folder.lever1.map((lever1, index) => (
                                            <Folder
                                                key={index}
                                                name={lever1.name}
                                                path={lever1.path}
                                                btnAdd={lever1.btnAdd}
                                                onClick={handleOpenFolder}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <Folder
                                        key={index}
                                        name={folder.name}
                                        path={folder.path}
                                        btnAdd={folder.btnAdd}
                                        onClick={handleOpenFolder}
                                    />
                                )}
                            </>
                        ))}
                    </div>

                    {/* IMAGENES PREVIEW */}

                    {filesPrepare.length > 0 && (
                        <>
                            <h3>Pending upload ({filesPrepare.length})</h3>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {filesPrepare.map((file, index) => (
                                    <BoxImage
                                        index={index}
                                        file={file}
                                        isNew={true}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {filesPrepare.length > 0 && (
                        <>
                            <br/>
                            <hr/>
                            <h3>Already uploaded in server</h3>
                        </>
                    )}

                    {files.length > 0 && (
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {files.map((file, index) => (
                                <BoxImage
                                    index={index}
                                    file={file}
                                    onClickDelete={handleDeleteFile}
                                    isNew={false}
                                />
                            ))}
                        </div>
                    )}

                </Grid>

                <Grid item xs={12}>
                    <LoadingSpinner show={loading}/>
                </Grid>

            </Grid>
        </>
    )
}

function BoxImage(props) {

    const {index, file, onClickDelete, isNew} = props

    const [img, setImg] = useState(null)

    useEffect(() => {
        let img = file?.url ?? file?.base64

        // En el casi de que sea un PDF
        if (file?.type === 'application/pdf') {
            img = null
        }

        // En caso de que el nombre termine .pdf
        if (file?.name?.endsWith('.pdf')) {
            img = null
        }

        // En caso de que el nombre termine .dwg
        if (file?.name?.endsWith('.dwg')) {
            img = null
        }

        setImg(img)
    }, [file])


    return (
        <div
            key={index}
            style={{
                margin: 5,
                display: "block",
                border: '1px solid #ccc',
                position: 'relative'
            }}
        >
            <div>
                <div style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "200px",
                    padding: 5
                }}>
                    <div
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100px",
                            height: "100px",
                        }}
                    >
                        {img ? (
                            <img
                                src={img}
                                alt={file.name}
                                style={{
                                    width: "100%",
                                    maxHeight: "100px",
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}
                            />
                        ) : (
                            <VscFile
                                size={60}
                                style={{
                                    width: "100%",
                                    maxHeight: "100px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={() => downloaderFile(file.url, file.name)}
                                color={'#727272'}
                            />
                        )}
                    </div>
                    <div
                        style={{fontSize: 13, width: '100%', textAlign: 'center'}}
                    >
                        {file.name}
                    </div>
                </div>

            </div>
        </div>
    )
}

function Folder(props) {
    const {name, path, onClick, btnAdd} = props;

    return (
        <div
            style={{width: 90, padding: 5, margin: 10, cursor: 'pointer'}}
            onClick={() => onClick(path, btnAdd)}
        >
            <div style={{marginLeft: 'auto', marginRight: 'auto', display: 'table'}}>
                <FcFolder size={80}/>
            </div>
            <p className="text-center" style={{margin: 0}}>{name}</p>
        </div>
    )
}

function structureS3() {
    return [
        {
            name: 'Lower',
            path: 'lower',
            btnAdd: false,
            lever1: [
                {
                    name: '2d',
                    path: '2d',
                    btnAdd: true,
                },
                {
                    name: '3d',
                    path: '3d',
                    btnAdd: true,
                },
            ]
        },
        {
            name: 'Middle',
            path: 'middle',
            btnAdd: false,
            lever1: [
                {
                    name: '2d',
                    path: '2d',
                    btnAdd: true,
                }
            ]
        },
        {
            name: 'Compact',
            path: 'compact',
            btnAdd: false,
            lever1: [
                {
                    name: '3d',
                    path: '3d',
                    btnAdd: true,
                }
            ]
        },
        {
            name: 'Upper',
            path: 'upper',
            btnAdd: false,
            lever1: [
                {
                    name: '2d',
                    path: '2d',
                    btnAdd: true,
                },
                {
                    name: '3d',
                    path: '3d',
                    btnAdd: true,
                },
            ]
        },
        {
            name: 'DWG',
            path: 'dwg',
            btnAdd: true,
            lever1: []
        },
        {
            name: 'Technical Sheets',
            path: 'technical-sheets',
            btnAdd: true,
            lever1: []
        },
    ]
}
