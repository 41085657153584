import React, {useEffect, useState} from "react";
import {Chip, Grid, IconButton} from "@mui/material";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import Table from "../../../../../components/Table/Table";
import {useTranslation} from "react-i18next";
import {apiGetSerie, apiSetSerie, apiAddSerie, apiRemoveSerie} from "../../../../../services/Admin/serie";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import InputButton from "../../../../../components/Inputs/InputButton";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";

export default function Management() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(2)
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getSerie()
    }, [])

    const getSerie = () => {
        setLoading(true)
        apiGetSerie(i18n.resolvedLanguage).then((resp) => {
            let temp = resp.data.map(doc => {

                return {
                    id: doc.id,
                    name: doc.name,
                    depth: doc.depth,
                    type: (
                        <Chip
                            label={doc.type}
                            style={{margin: 3, background: doc.type === 'RX' ? '#ea0c1f' : '#004175', color: '#fff'}}
                            size="small"
                        />
                    ),
                    editable: true,
                    saveElement: false
                }
            })
            setRows(temp)
            setRowCount(resp.data.length)
            setLoading(false)
        })
    }

    const handleChangeCell = (value, element) => {
        let temp = rows.map(row => {
            if (row.id === element.id) {
                row.name = value
                row.saveElement = true
            }
            return row
        })
        setRows(temp)
    }

    const handleSaveElement = (element) => {
        apiSetSerie({
            id: element.id,
            name: element.name
        }, i18n.resolvedLanguage).then((resp) => {

            // Actualizamos row y quitamos el saveElement
            let temp = rows.map(row => {
                if (row.id === element.id) {
                    row.saveElement = false
                }
                return row
            })
            setRows(temp)
        })
    }

    const handleAdd = (type) => {
        apiAddSerie({
            type: type
        }, i18n.resolvedLanguage).then((resp) => {
            getSerie()
        })
    }

    const handleRemove = (id) => {
        apiRemoveSerie({
            id: id
        }, i18n.resolvedLanguage).then((resp) => {
            getSerie()
        })
    }


    return (
        <>
            <HeaderPage title="Range" border={false}>
                <>
                    <InputButton
                        style={{float: "right", margin: "0px 0px 10px 10px", background: '#004175', borderColor: "#004175"}}
                        onClick={() => handleAdd("FR")}
                        text=" + Add FR"
                    />

                    <InputButton
                        style={{float: "right", margin: "0px 0px 10px 0px",}}
                        onClick={() => handleAdd("RX")}
                        text=" + Add RX"
                    />

                </>
            </HeaderPage>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table
                        columns={tableColumns(handleSaveElement, handleRemove)}
                        handleChangeCell={handleChangeCell}
                        rows={rows || []}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function tableColumns(handleSaveElement, handleRemove) {
    return [
        {
            field: "id",
            headerName: "id",
            sortable: false,
            flex: 1,
        },
        {
            field: "type",
            headerName: "type",
            flex: 1,
        },
        {
            field: "name",
            headerName: "name",
            editable: true,
            sortable: false,
            flex: 1,
        },
        {
            field: "depth",
            headerName: "depth",
            sortable: false,
            flex: 1,
        },
        {
            field: "actions",
            headerName: "ACCIONES",
            sortable: false,
            flex: 1,
            renderCell: (params) => {

                const removeElement = (params.id != 1 && params.id != 2 && params.id != 22) ? true : false

                return (
                    <>
                        {removeElement && (
                            <IconButton size="small" onClick={() => handleRemove(params.id)}>
                                <DeleteForeverIcon fontSize="inherit" />
                            </IconButton>
                        )}

                        {params.saveElement && (
                            <IconButton size="small" onClick={() => handleSaveElement(params)}>
                                <SaveIcon fontSize="inherit" />
                            </IconButton>
                        )}
                    </>
                )
            },
        },
    ]
}

function initialValues() {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}
