import {BASE_PATH, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_USER}/options`;

export function apiListOptions(props, methods = "get", lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.isExport) { query += `&isExport=${props.isExport}`; }
    if (props.limit) { query += `&limit=${props.limit}`; }
    if (props.offset) { query += `&offset=${props.offset}`; }
    if (props.field) { query += `&field=${props.field}`; }
    if (props.sort) { query += `&sort=${props.sort}`; }
    if (props.description) { query += `&description=${props.description}`; }

    if (props.productCode) { query += `&productCode=${props.productCode}`; }

    if (props.code && methods === "get") { query += `&code=${props.code}`; }

    query += `&isActive=${props.isActive}`;

    if (methods === "post") {
        return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/list`, props, config)
    } else {
        return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?1=1${query}`, config)
    }
}
