import React, {useEffect, useState} from "react";
import {Chip, Grid} from "@mui/material";
import HeaderPage from "../../../../../../components/Admin/HeaderPage";
import Table from "../../../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../../../utils/constants";
import Filters from "./Filters";
import {apiListProductOptions} from "../../../../../../services/Admin/options";
import {RouteAdmin} from "../../../../../../routes/RouteAdmin";
import {useTranslation} from "react-i18next";
import InputButtonLoading from "../../../../../../components/Inputs/InputButtonLoading";

export default function Management() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingExport, setLoadingExport] = React.useState(false)

    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])
    const params = new URLSearchParams(window.location.search)

    useEffect(() => {
        if (params.get("code")) {
            setState({...state, type: null, code: params.get("code")})
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        setRowCount(0)

        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = (isExport) => {
        setMsgError([])
        setRowCount(0)
        if (isExport) {
            setLoadingExport(true)
        }

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            isExport: isExport ? true : false
        }


        if (state.productCode) {
            params.productCode = state.productCode
        }
        if (state.optionsCode) {
            params.optionsCode = state.optionsCode
        }
        params.isActive = state.isActive

        apiListProductOptions(params, i18n.resolvedLanguage).then(resp => {
            if (isExport) {
                // Downloader file
                const link = document.createElement('a')
                link.href = resp.data.url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click()
                link.remove()

            } else {
                setRowCount(resp.data?.totalItems)

                let temp = resp.data.items?.map(doc => {

                    // array to Chip
                    let optionsCodeRequiered = doc.optionsCodeRequiered?.split(",")
                    let optionsCodeIncompatible = doc.optionsCodeIncompatible?.split(",")

                    optionsCodeRequiered = optionsCodeRequiered?.map((item, index) => {
                        return (
                            <Chip
                                key={index}
                                label={item}
                                style={{margin: 3, background: '#e5ecff'}}
                                size="small"
                            />
                        )
                    })

                    optionsCodeIncompatible = optionsCodeIncompatible?.map((item, index) => {
                        return (
                            <Chip
                                key={index}
                                label={item}
                                style={{margin: 3, background: '#fff6e5'}}
                                size="small"
                            />
                        )
                    })

                    return {
                        ...doc,
                        optionsCodeRequiered: optionsCodeRequiered,
                        optionsCodeIncompatible: optionsCodeIncompatible,
                    }
                })
                setRows(temp)
            }

            setLoadingExport(false)
            setLoading(false)

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExport(false)
            if (!isExport) {
                setRows([])
            }
        })
    }

    return (
        <>
            <HeaderPage title="Relationships Options">
                <InputButtonLoading
                    onClick={() => getItemsData('export')}
                    loading={loadingExport}
                    label={`Export results ${rowCount}`}
                    variant="outlined"
                />
            </HeaderPage>

            <Grid container spacing={2}>

                <Filters
                    inpoutDisabled={loadingExport}
                    state={state}
                    setState={setState}
                    totalRows={rowCount}
                    loadingExport={loadingExport}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function tableColumns() {
    return [
        {
            field: "productCode",
            headerName: "product code",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.productCode}`} target="_blank">
                        {params.productCode}
                    </a>
                )
            },
        },
        {
            field: "optionsCode",
            headerName: "options code",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.OPTIONS_MANAGEMENT.path}?code=${params.optionsCode}`} target="_blank">
                        {params.optionsCode}
                    </a>
                )
            },
        },
        {
            field: "quantity",
            headerName: "quantity",
            sortable: false,
            flex: 1,
        },
        {
            field: "optionsCodeRequiered",
            headerName: "options Code Requiered (if)",
            sortable: false,
            flex: 1,
        },
        {
            field: "optionsCodeIncompatible",
            headerName: "options Code Incompatible (or)",
            sortable: false,
            flex: 1,
        }
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
        productCode: "",
        optionsCode: ""
    }
}
