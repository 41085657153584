import React, {useState} from "react";
import {apiRegister} from "../../../services/Auth/login";
import {Link, useNavigate} from "react-router-dom";
import {Checkbox, CssBaseline, FormGroup, TextField} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {ThemeProvider} from "@emotion/react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {createTheme} from "@mui/material/styles";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MsgError from "../../../components/MsgError";
import MsgSuccess from "../../../components/MsgSuccess";
import {LoadingButton} from "@mui/lab";
import {RouteAuth} from "../../../routes/RouteAuth";
import {useTranslation} from "react-i18next";
import InputText from "../../../components/Inputs/InputText";
import logo from "../../../assets/images/logo-scaled.png";
import optionsPrefix from "../../../utils/optionsSelectors/phonePrefix.json";
import InputSelect from "../../../components/Inputs/InputSelect";
import MenuLanguage from "../../../components/Layouts/Admin/MenuLanguage";
import {optionsArea} from "../../../utils/optionsSelectors/optionsArea";
import FormControlLabel from "@mui/material/FormControlLabel";

const theme = createTheme();

export default function RegisterForm() {

    const navigate = useNavigate()
    const {i18n, t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [showForm, setShowForm] = useState(true)
    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeCheckbox = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
    }

    const handleSubmit = (event) => {
        setLoading(true)
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state, t)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return
        }

        apiRegister(state, i18n.resolvedLanguage).then(resp => {
            navigate(RouteAuth.LOGIN_THANKS.path)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <div style={{minHeight: '100vh', display: 'flex', alignItems: 'center'}}>
                    <Container
                        component="main"
                        maxWidth="xs"
                        style={{
                            padding: '48px 40px 36px',
                            border: '1px solid #dadce0',
                            borderRadius: '8px',
                        }}
                    >
                        <CssBaseline/>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img style={{width: 150}} src={logo} alt={""}/>
                            <br/>
                            <br/>
                            <Typography component="h1" variant="h5">
                                {t('PAGE_LOGIN.Create an account')}
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <MsgError msg={msgError}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputText
                                            label={t('user.first_name')}
                                            required
                                            name="name"
                                            value={state.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputText
                                            label={t('user.last_name')}
                                            required
                                            name="surnames"
                                            value={state.surnames}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label={t('user.email')}
                                            required
                                            name="email"
                                            value={state.email}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label={t('user.password')}
                                            required
                                            name="password"
                                            value={state.password}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <InputSelect
                                            label={t('user.phone prefix')}
                                            required
                                            name="phonePrefix"
                                            onChange={handleChange}
                                            value={state?.phonePrefix}
                                            options={optionsPrefix}
                                        />
                                    </Grid>

                                    <Grid item xs={9}>
                                        <InputText
                                            label={t('user.phone')}
                                            required
                                            name="phone"
                                            value={state.phone}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label={t('user.company name')}
                                            required
                                            name="companyName"
                                            value={state.companyName}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputSelect
                                            label={t('user.area')}
                                            required
                                            name="area"
                                            onChange={handleChange}
                                            value={state?.area}
                                            options={optionsArea}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label={t('user.postal code')}
                                            disabled={state?.area !== 1 ? true : false}
                                            name="postalCode"
                                            value={state?.area === 1 ? state.postalCode : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            style={{marginRight: 5}}
                                            control={<Checkbox color="error" size={"small"}/>}
                                            onChange={handleChangeCheckbox}
                                            name={"terms"}
                                            label={t('user.I agree to all terms')}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <LoadingButton
                                            type="submit"
                                            loading={loading}
                                            loadingPosition="start"
                                            variant="contained"
                                            fullWidth
                                            sx={{mt: 1, mb: 2}}
                                        >
                                            {t('PAGE_LOGIN.Sign Up')}
                                        </LoadingButton>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Link
                                            to={RouteAuth.LOGIN.path}
                                            style={{
                                                textDecoration: 'none',
                                                fontFamily: 'AdminFontBold',
                                                fontSize: '14px',
                                                color: color,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                display: 'table'
                                            }}
                                        >
                                            {t('PAGE_LOGIN.Already have an account? Sign in')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </div>
            </ThemeProvider>

            <div style={{
                color: "gray",
                position: 'absolute',
                bottom: 20,
                left: 20,
                fontSize: 15,
            }}><MenuLanguage showLang={true}/></div>
        </>
    )
}

function initialValues() {
    return {
        name: "",
        surnames: "",
        email: "",
        password: ""
    }
}

function validationSchema(schema, t) {
    let error = []

    if (!schema.name) {
        error.push(t('PAGE_LOGIN.The name is required.'))
    }
    if (!schema.email) {
        error.push(t('PAGE_LOGIN.The email is required.'))
    }
    if (!schema.password) {
        error.push(t('PAGE_LOGIN.The password is required.'))
    }
    if (!schema.surnames) {
        error.push(t('PAGE_LOGIN.The surnames is required.'))
    }
    if (!schema.phonePrefix) {
        error.push(t('PAGE_LOGIN.The phone prefix is required.'))
    }
    if (!schema.phone) {
        error.push(t('PAGE_LOGIN.The phone is required.'))
    }
    if (!schema.companyName) {
        error.push(t('PAGE_LOGIN.The company name is required.'))
    }
    if (!schema.area) {
        error.push(t('PAGE_LOGIN.The area is required.'))
    }
    if (schema.area === 1 && !schema.postalCode) {
        error.push(t('PAGE_LOGIN.The postal code is required.'))
    }
    if (!schema.terms) {
        error.push(t('PAGE_LOGIN.The terms is required.'))
    }

    return error
}
