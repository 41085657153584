import * as React from 'react';
import InputSelect from "../../../../components/Inputs/InputSelectLine";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {apiList} from "../../../../services/User/category";
import {Grid} from "@mui/material";
import {optionsWidth} from "../../../../utils/optionsSelectors/optionsWidth";
import useAuth from "../../../../hooks/useAuth";
import InputText from "../../../../components/Inputs/InputText";

export default function BoxFilter(props) {
    const {auth} = useAuth()

    const {state, setState, availableLength, elementSelected} = props
    const [optionsCategory, setOptionsCategory] = React.useState([])
    const {t, i18n} = useTranslation()

    useEffect(() => {
        getCategoryList()
    }, [])

    const getCategoryList = () => {
        apiList({
            type: state.type
        }, i18n.resolvedLanguage).then(resp => {
            let temp = resp.data?.map(doc => {
                return {...doc}
            })
            setOptionsCategory(temp)
        }).catch(err => {
            setOptionsCategory([])
        })
    }

    return (
        <>
            <Grid container spacing={3} style={{minWidth: 700}}>
                {!elementSelected && (
                    <Grid item xs={6} md={6} lg={6}>
                        <InputSelect
                            label={t('general.category')}
                            name="category"
                            onChange={(e) => setState({...state, category: e.target.value})}
                            value={state?.category}
                            selectText={t('general.category')}
                            options={optionsCategory}
                        />
                    </Grid>
                )}

                {auth?.secondaryUser.role === 'ROLE_ADMIN' && (
                    <Grid item xs={6} md={6} lg={6}>
                        <InputText
                            label="Code"
                            name="code"
                            value={state.code}
                            onChange={(e) => setState({...state, code: e.target.value})}
                        />
                    </Grid>

                )}

                {(elementSelected && availableLength > 0) && (
                    // Si estamos con las partes inferiores, mostramos el ancho maximo disponible
                    <Grid item xs={6} md={6} lg={6}>
                        <div style={{marginTop: 13, marginBottom: 13}}>{t('general.Max width')}: {availableLength} mts</div>
                    </Grid>
                )}

            </Grid>

        </>
    )
}
