import {BASE_PATH, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_USER}/transaction`;
export function apiList(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.code) { query += `&code=${props.code}`; }
    if (props.serie) { query += `&serie=${props.serie}`; }
    if (props.dateFrom) { query += `&dateFrom=${props.dateFrom}`; }
    if (props.dateTo) { query += `&dateTo=${props.dateTo}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(data, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/create`, data, config)
}

export function apiGetUrlTransaction(id, type, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-url/${id}/${type}`, config)
}
