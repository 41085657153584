import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import HeaderPage from "../../../../../../components/Admin/HeaderPage";
import Table from "../../../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../../../utils/constants";
import Filters from "./Filters";
import {RouteAdmin} from "../../../../../../routes/RouteAdmin";
import {useTranslation} from "react-i18next";
import {apiListElements} from "../../../../../../services/Admin/product";
import InputButtonLoading from "../../../../../../components/Inputs/InputButtonLoading";

export default function Management() {
    const {i18n} = useTranslation()
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingExport, setLoadingExport] = React.useState(false)

    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])
    const params = new URLSearchParams(window.location.search)

    useEffect(() => {
        if (params.get("code")) {
            setState({...state, type: null, code: params.get("code")})
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        setRowCount(0)

        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = (isExport) => {
        setMsgError([])
        if (isExport) {
            setLoadingExport(true)
        }

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            isExport: isExport ? true : false
        }


        if (state.lowerCode) {
            params.lowerCode = state.lowerCode
        }
        if (state.elementCode) {
            params.elementCode = state.elementCode
        }

        apiListElements(params, i18n.resolvedLanguage).then(resp => {
            if (isExport) {
                // Downloader file
                const link = document.createElement('a')
                link.href = resp.data.url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click()
                link.remove()

            } else {
                setRowCount(resp.data?.totalItems)

                let temp = resp.data.items?.map(doc => {
                    return {
                        ...doc
                    }
                })
                setRows(temp)
            }

            setLoadingExport(false)
            setLoading(false)

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExport(false)
            if (!isExport) {
                setRows([])
            }
        })
    }

    return (
        <>
            <HeaderPage title="Relationships SBR Elements">
                <InputButtonLoading
                    onClick={() => getItemsData('export')}
                    loading={loadingExport}
                    label={`Export results ${rowCount}`}
                    variant="outlined"
                />
            </HeaderPage>

            <Grid container spacing={2}>

                <Filters
                    inpoutDisabled={loadingExport}
                    state={state}
                    setState={setState}
                    totalRows={rowCount}
                    loadingExport={loadingExport}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function tableColumns() {
    return [
        {
            field: "lower_code",
            headerName: "product code lower",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.lower_code}`} target="_blank">
                        {params.lower_code}
                    </a>
                )
            },
        },
        {
            field: "element_code",
            headerName: "Product Code SBR Element",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <a href={`${RouteAdmin.PRODUCT_MANAGEMENT.path}?code=${params.element_code}`} target="_blank">
                        {params.element_code}
                    </a>
                )
            },
        }
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
        lowerCode: "",
        elementCode: ""
    }
}
