import {Grid} from "@mui/material";
import InputButton from "../../../../../components/Inputs/InputButton";
import {useEffect, useState} from "react";
import {apiListElements} from "../../../../../services/User/product";
import {Preview} from "@mui/icons-material";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import {useTranslation} from "react-i18next";

export default function SelectProduct(props) {

    const {
        unionElementsEnd,
        elementLowerTemp,
        elementLowerPrepare,
        setElementLowerPrepare,
        confirmLower,
        setMsgError,
    } = props
    const {t, i18n} = useTranslation()

    const [totalPositions, setTotalPositions] = useState(0)
    const [totalPositionsAffected, setTotalPositionsAffected] = useState(0)
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)

    const resetVariables = () => {
        setTotalPositions(0)
        setTotalPositionsAffected(0)
        setElementLowerPrepare([])
        setProducts([])
    }

    // Buscamos los productos que se pueden añadir a la parte inferior del elemento seleccionado
    useEffect(() => {
        if (unionElementsEnd) {
            setLoading(true)

            resetVariables()

            apiListElements({
                lowerCode: elementLowerTemp.code,
                isActive: true
            }, i18n.resolvedLanguage).then(resp => {
                setLoading(false)

                // Reconvertimos los datos para el configurador
                let product = resp.data?.items.map(doc => {
                    return {
                        position: doc.element_position,
                        productCode: doc.element_code,
                        width: doc.element_width,
                        id: doc.element_id,
                        imgLower2d: doc.element_imgLower2d,
                        fileTechnicalSheets: doc.fileTechnicalSheets
                    }
                })

                setProducts(product)

                // Buscamos el numero total de pociones
                let totalPositionsTemp = 0
                product.forEach(product => {
                    if (product.position > totalPositionsTemp) {
                        totalPositionsTemp = product.position
                    }
                })

                setTotalPositions(totalPositionsTemp)
            }).catch(err => {
                setLoading(false)
                setMsgError([err.response?.data?.msg])
            })
        }
    }, [unionElementsEnd])

    const clickElementLower = (element) => {
        // Buscamos si elementLowerPrepare ya tiene la poción seleccionada
        const indexActual = elementLowerPrepare.findIndex(elementLower => (elementLower.position === element.position && elementLower.productCode === element.productCode))
        const indexNew = elementLowerPrepare.findIndex(elementLower => (elementLower.position === element.position && elementLower.productCode !== element.productCode))

        // Si ya existe un elemento en la posición seleccionada lo eliminamos
        if (indexActual !== -1) {
            elementLowerPrepare.splice(indexActual, 1)
        }
        if (indexNew !== -1) {
            elementLowerPrepare.splice(indexNew, 1)
        }

        // Añadimos el elemento
        elementLowerPrepare.push(element)

        // Ordenamos por posicion
        elementLowerPrepare.sort((a, b) => a.position - b.position)

        setElementLowerPrepare([...elementLowerPrepare])

    }

    // Comprobamos que todos los elementos de la parte inferior esten seleccionados
    useEffect(() => {
        let totalPositionsAffectedTemp = []
        elementLowerPrepare.forEach(element => {
            if (!totalPositionsAffectedTemp.includes(element.position)) {
                totalPositionsAffectedTemp.push(element.position)
            }
        })

        setTotalPositionsAffected(totalPositionsAffectedTemp.length)
    }, [elementLowerPrepare])

    const existInElementLowerPrepare = (element) => {
        return elementLowerPrepare.find(elementLower => (elementLower.productCode === element.productCode))
    }

    const confirmLowerElement = () => {
        resetVariables()
        confirmLower()
    }

    return (
        <>
            {(unionElementsEnd) && (
                <>
                    <h3>{t('composer.Select the items at the bottom')}</h3>

                    <LoadingSpinner show={loading}/>

                    <div style={{display: 'flex'}}>
                        {products.map((element, index) => {
                            return (
                                <>
                                    <Product
                                        key={index}
                                        element={element}
                                        onClick={clickElementLower}
                                        existInElementLowerPrepare={existInElementLowerPrepare}
                                    />
                                </>
                            )
                        })}
                    </div>

                    <br/>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <PreviewLower elementLowerPrepare={elementLowerPrepare} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <span style={{paddingTop: 9, display: 'block'}}>Position {totalPositionsAffected} / {totalPositions}</span>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={`${t('general.Confirm')}`}
                                onClick={confirmLowerElement}
                                disabled={(totalPositionsAffected === totalPositions && !loading) ? false : true }
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}

function Product(props) {

    const {element, key, onClick, existInElementLowerPrepare} = props

    const {t} = useTranslation()

    const maxWidth = element?.width < 800 ? 60 : 90
    const maxHeight= element?.width < 800 ? 90 : 90

    const border = existInElementLowerPrepare(element) ? '2px solid rgba(234, 12, 31, 1)' : '2px solid #dadada'

    return (
        <div key={key} style={{display: 'block', cursor: 'pointer'}} onClick={() => onClick(element)}>
            <div
                style={{
                    width: maxWidth,
                    height: maxHeight,
                    background: '#fff',
                    borderTop: border,
                    borderLeft: border,
                    borderRight: border,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginRight: 20,
                    padding: 5
                }}
            >
                <img
                    alt=""
                    src={element?.imgLower2d}
                    style={{width: '100%', maxHeight: maxHeight}}
                />
            </div>

            <div
                style={{
                    width: maxWidth,
                    background: '#fff',
                    borderBottom: border,
                    borderLeft: border,
                    borderRight: border,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    marginRight: 20,
                    padding: 5
                }}
            >
                <span style={{fontSize: 12, width: '100%', maxHeight: 150}}>
                    {t('general.Position')} {element?.position}
                </span>
            </div>

        </div>
    )
}

function PreviewLower (props){
    const {elementLowerPrepare} = props
    const {t} = useTranslation()

    return (
        <>
            {elementLowerPrepare.length > 0 && (
                <>
                <h4 style={{marginTop: 0}}>{t('general.Preview')}</h4>
                <div style={{display: 'flex'}}>
                    <div className={`kitchen-400`} style={{width: 10, background: '#fff', height: 101}}></div>
                    {elementLowerPrepare.map((element, index) => (
                        <div key={index}>
                            <img
                                alt=""
                                src={element?.imgLower2d}
                                className={`kitchen-${element?.width}`}
                                style={{margin: 'auto', paddingTop: 10, paddingBottom: 10, background: '#fff'}}
                            />
                        </div>
                    ))}
                    <div className={`kitchen-400}`} style={{width: 10, background: '#fff', height: 101}}></div>
                </div>
                </>
            )}
        </>
    )
}
