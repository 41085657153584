import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import InputButton from "../../../components/Inputs/InputButton";
import {VscChromeClose} from "react-icons/vsc";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {apiCreate} from "../../../services/User/transaction";
import MsgError from "../../../components/MsgError";
import Formulario from "../Composer/Modal/Quotation/Formulario";
import ShowBntPdf from "../Composer/Modal/Quotation/ShowBntPdf";
import InputButtonLoading from "../../../components/Inputs/InputButtonLoading";

export default function ModalQuotation(props) {
    const {
        open, setOpen,
        catalogueProducts
    } = props
    const {t, i18n} = useTranslation()
    const [state, setState] = React.useState(initialState())
    const [loading, setLoading] = React.useState(false)
    const [msgError, setMsgError] = React.useState([])
    const [msgSuccess, setMsgSuccess] = React.useState([])
    const [urlPdf, setUrlPdf] = React.useState(null)
    const [urlExcel, setUrlExcel] = React.useState(null)
    const [showForm, setShowForm] = React.useState(true)
    const [transactionCode, setTransactionCode] = React.useState(null)

    const handleClose = () => {
        setOpen(false)
        setMsgSuccess([])
        setMsgError([])
        setShowForm(true)
    }

    const openFrom = () => {
        setShowForm(true)
        setMsgError([])
        setMsgSuccess([])
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)
        setOpen(true)
        setUrlPdf(null)
        setTransactionCode(null)
        setUrlExcel(null)
        setShowForm(false)

        const data = {
            ...state,
            discountRosinox: state.discountRosinox ? parseFloat(state.discountRosinox) : null,
            discountFriginox: state.discountFriginox ? parseFloat(state.discountFriginox) : null,
            catalogueProducts: catalogueProducts,
        }
        apiCreate(data, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setUrlPdf(resp.data?.urlPdf)
            setUrlExcel(resp.data?.urlExcel)
            setTransactionCode(resp.data?.transactionCode)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            setShowForm(true)
        })
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter:' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={1} style={{margin: 0}}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>{t('quotation.Generate quotation')}</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>
                        {showForm && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Formulario
                                    state={state}
                                    setState={setState}
                                    loading={loading}
                                />
                            </Grid>
                        )}

                        {!showForm && (
                            <ShowBntPdf
                                urlPdf={urlPdf}
                                urlExcel={urlExcel}
                                loading={loading}
                                transactionCode={transactionCode}
                            />
                        )}

                        <Grid item xs={12} md={12} lg={12}>
                            {/*<MsgSuccess msg={msgSuccess}/>*/}
                            <MsgError msg={msgError}/>
                        </Grid>

                        {showForm && (
                            <>
                                <Grid item xs={12} md={6} lg={6}>
                                    <InputButton
                                        style={{width: '100%'}}
                                        text="Cancel"
                                        onClick={handleClose}
                                        color="secondary"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <InputButtonLoading
                                        onClick={handleSubmit}
                                        loading={loading}
                                        label={t('quotation.Generate quotation')}
                                        style={{minWidth: '100%'}}
                                    />
                                </Grid>
                            </>
                        )}

                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

function initialState() {
    const dateNow = moment().format('X')
    return {
        serieId: 1,
        dateQuotation: dateNow,
        companyName: "",
        clientContact: "",
        clientAddress: "",
        clientLocation: "",
        name: "",
        text: "",
        gasType: "",
        discountRosinox: "",
        discountRosinoxType: "%",
        discountFriginox: "",
        discountFirginoxType: "%",
        vatActive: true,
    }
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
