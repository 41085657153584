import {Button, Chip, Grid} from "@mui/material";
import {VscGitCompare} from "react-icons/vsc";
import {GoTrashcan} from "react-icons/go";
import {useEffect, useState} from "react";
import ModalRemove from "../Modal/ModalRemove";
import ModalQuotation from "../Modal/Quotation/ModalQuotation";
import Tooltip from '@mui/material/Tooltip';
import {useTranslation} from "react-i18next";
import currencyFormatterPrice from "../../../../utils/currencyFormatterPrice";
import Modal from "../../../../components/Modal";

export default function BoxAction(props) {

    const {
        handleChangeSide,
        side,
        elementSelected,
        kitchenElements,
        setKitchenElements,
        kitchenElementsSecondarySide,
        setKitchenElementsSecondarySide,
        setElementSelected,
        catalogueProducts,
        customProducts,
        totalIncomplete,
        incompleteLoading,
        setLoadingAutoSave,
        setShowMenu,
        accessories
    } = props

    const {t} = useTranslation()

    const [openModalRemove, setOpenModalRemove] = useState(false)
    const [openModalRemoveAll, setOpenModalRemoveAll] = useState(false)
    const [openModalQuotation, setOpenModalQuotation] = useState(false)
    const [length, setLength] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [lengthSecondarySide, setLengthSecondarySide] = useState(0)

    useEffect(() => {
        // Length
        setLength(currencyFormatterLength(kitchenElements.reduce((total, element) => {
            return total + element.totalWidth
        }, 0)))
        setLengthSecondarySide(currencyFormatterLength(kitchenElementsSecondarySide.reduce((total, element) => {
            return total + element.totalWidth
        }, 0)))

        // Total price
        let total = 0
        let total1 = 0
        let total2 = 0
        let total3 = 0
        let total4 = 0
        let total5 = 0
        let total6 = 0
        let total7 = 0

        total1 += kitchenElements.reduce((total, element) => {
            let totalIn = element.price
            if (element.upper.length > 1) {
                // Si es más grande que 1 significa que es un SBR, por lo que debemos contar el precio de los upper
                totalIn += element.upper.reduce((total, item) => {
                    return total + item.price
                }, 0)
            }
            return total + totalIn
        }, 0)
        total2 += kitchenElementsSecondarySide.reduce((total, element) => {
            let totalIn = element.price
            if (element.upper.length > 1) {
                // Si es más grande que 1 significa que es un SBR, por lo que debemos contar el precio de los upper
                totalIn += element.upper.reduce((total, item) => {
                    return total + item.price
                }, 0)
            }
            return total + totalIn
        }, 0)

        // Total price options
        total3 += kitchenElements.reduce((total, element) => {
            return total + element.options.reduce((total, option) => {
                if(option.finalQuantity != undefined){ return total + (option.price  * option.finalQuantity)}
                else{ return total + (option.price * 1)}
            }, 0)
        }, 0)
        total4 += kitchenElementsSecondarySide.reduce((total, element) => {
            return total + element.options.reduce((total, option) => {
                if(option.finalQuantity != undefined){ return total + (option.price  * option.finalQuantity)}
                else{ return total + (option.price * 1)}
            }, 0)
        }, 0)

        // Calculate total price
        total5 += catalogueProducts.reduce((total, product) => {
            return total + (product.price * product.quantity)
        }, 0)

        // Custom products
        total6 += customProducts.reduce((total, product) => {
            return total + (product.price * product.quantity)
        }, 0)


        // Accessories
        total7 += accessories.reduce((total, product) => {
            return total + (product.price * product.quantity)
        }, 0)

        total = total1 + total2 + total3 + total4 + total5 + total6 + total7

        setTotalPrice(currencyFormatterPrice(total))
    }, [setLengthSecondarySide, kitchenElements, catalogueProducts, customProducts, accessories])

    function currencyFormatterLength(value) {
        const formatter = new Intl.NumberFormat('de-DE')
        return formatter.format(value)
    }

    const handleClickGenerateQuotation = () => {
        setLoadingAutoSave(true)
        setElementSelected(null)
        if (side === 2) {
            handleChangeSide()
        }
        setOpenModalQuotation(true)
    }

    const handleClickRemoveAll = () => {
        setKitchenElements([])
        setElementSelected(null)
        setOpenModalRemoveAll(false)
    }

    return (
        <div id="box-actions">
            <ModalRemove
                open={openModalRemove}
                setOpen={setOpenModalRemove}
                kitchenElements={kitchenElements}
                elementSelected={elementSelected}
                setKitchenElements={setKitchenElements}
                setElementSelected={setElementSelected}
            />

            <ModalQuotation
                open={openModalQuotation}
                setOpen={setOpenModalQuotation}
                kitchenElements={kitchenElements}
                setKitchenElements={setKitchenElements}
                kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                setKitchenElementsSecondarySide={setKitchenElementsSecondarySide}
                side={side}
                catalogueProducts={catalogueProducts}
                customProducts={customProducts}
                totalIncomplete={totalIncomplete}
                incompleteLoading={incompleteLoading}
                setShowMenu={setShowMenu}
            />

            <Modal
                open={openModalRemoveAll}
                setOpen={setOpenModalRemoveAll}
                title={t('general.Remove All')}
                btnOk={t('general.Remove')}
                btnCancel={t('general.Cancel')}
                handleClick={handleClickRemoveAll}
            >
                <p>{t('general.Are you sure you want to remove all the elements?')}</p>
            </Modal>

            <Grid container spacing={1} style={{padding: '0px 15px'}}>
                <Grid item xs={4}>
                    <div style={{display: 'flex'}}>
                        <Button
                            variant="outlined"
                            startIcon={<VscGitCompare />}
                            size={"small"}
                            color="error"
                            onClick={handleChangeSide}
                        >
                            {t('composer.Side')} {side}
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<GoTrashcan />}
                            onClick={() => setOpenModalRemoveAll(true)}
                            disabled={kitchenElements.length === 0}
                            size={"small"}
                            style={{marginLeft: 10}}
                            color="error"
                        >
                            {t('general.Remove All')}
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<GoTrashcan />}
                            onClick={() => setOpenModalRemove(true)}
                            disabled={!elementSelected}
                            size={"small"}
                            style={{marginLeft: 10}}
                            color="error"
                        >
                            {t('general.Remove')}
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <div style={{float: 'right'}}>
                        {(length !== lengthSecondarySide &&  lengthSecondarySide > 0) ? (
                            <Chip label={<div><strong>{t('general.Length')}: </strong>{length} mts<strong> | {t('composer.Side')} {side === 1 ? 2 : 1}: </strong>{lengthSecondarySide} mts</div>} />
                        ) : (
                            <Chip label={<div><strong>{t('general.Length')}: </strong>{length} mts</div>} />
                        )}

                        <Chip label={<div><strong>{t('general.Total')}: </strong>{totalPrice} €</div>} style={{ marginLeft: 10 }}/>

                        <Button
                            variant="outlined"
                            onClick={handleClickGenerateQuotation}
                            size={"small"}
                            style={{marginLeft: 10}}
                            color="error"
                        >
                            {t('quotation.Generate quotation')}
                        </Button>

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
