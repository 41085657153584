import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import InputButton from "../../../../components/Inputs/InputButton";
import View from "../View";
import {VscChromeClose} from "react-icons/vsc";
import {useTranslation} from "react-i18next";

export default function ModalRemove(props) {
    const {
        open, setOpen,
        kitchenElements,
        elementSelected,
        setKitchenElements,
        setElementSelected
    } = props

    const {t} = useTranslation()

    const handleClose = () => {
        setOpen(false)
    }

    // Remove JSON element
    const handleClickRemoveElement = () => {
        const newElements = kitchenElements.filter(element => element.id !== elementSelected)
        setKitchenElements(newElements)
        setElementSelected(null)
        setOpen(false)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter:' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>Remove element</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#fff',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{display: 'flex', background: '#fff', padding: 10, borderRadius: 5}}>
                                {kitchenElements.map((element, index) => {
                                    if (elementSelected === element.id) {
                                        return (
                                            <View element={element} elementSelected={null} setElementSelected={null}/>
                                        )
                                    }
                                })}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={t('general.Cancel')}
                                onClick={handleClose}
                                color="secondary"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={t('general.Remove')}
                                onClick={handleClickRemoveElement}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#f6f6f6',
}
