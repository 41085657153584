import React from "react";
import {Button, Fade, IconButton, ListItemIcon, Menu, MenuItem} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {RouteUser} from "../../../routes/RouteUser";

export default function MenuAccount() {

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const {logout} = useAuth()
    const {auth} = useAuth()
    const idUser = auth ? auth?.primaryUser?.idUser : null
    const name = auth ? auth?.primaryUser?.name : null
    const surnames = auth ? auth?.primaryUser?.surnames : null
    const rola = auth ? auth?.primaryUser?.role : null

    const navigate = useNavigate()
    const {t} = useTranslation()

    const myAccount = () => {
        if (rola === "ROLE_ADMIN") {
            navigate(`${RouteAdmin.USER_EDIT.path}/${idUser}`)
        } else {
            navigate(`${RouteUser.USER_EDIT.path}/${idUser}`)
        }
    }
    return (
        <div className="" >
            <IconButton
                onClick={handleClick}
            >
                <AccountCircleIcon style={{color: "#545969"}}/>
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div className="menu-name">
                    <span style={{display: 'block', fontSize: 20, fontFamily: 'AdminFontMedium'}}>{name ? name : ""} {surnames ? surnames : ""}</span>
                    <span style={{display: 'block', fontSize: 13, fontFamily: 'AdminFontLight'}}>{auth?.primaryUser?.roleName}</span>
                </div>

                <MenuItem onClick={myAccount}>{t('my_account')}</MenuItem>
                <MenuItem onClick={logout}>{t('logout')}</MenuItem>
            </Menu>
        </div>
    )
}
