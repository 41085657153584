import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/media`;

export function apiList(data, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let onlyShow = ""
    if (data.onlyShow) {
        onlyShow = `&only-show=${data.onlyShow}`
    }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?limit=${data.limit}&offset=${data.offset}${onlyShow}`, config)
}

export function apiUpload(data, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/upload`, data, config)
}

export function apiDelete(id, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/delete/${id}`, config)
}
