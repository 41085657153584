import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../../components/Inputs/InputText";
import InputSelect from "../../../../../../components/Inputs/InputSelect";
import {apiList as apiListCategory} from "../../../../../../services/User/category";
import {LoadingButton} from "@mui/lab";
import {VscExport} from "react-icons/vsc";
import InputSwitch from "../../../../../../components/Inputs/InputSwitch";
import InputButtonLoading from "../../../../../../components/Inputs/InputButtonLoading";

export default function Filters(props) {

    const {
        inpoutDisabled,
        state,
        setState,
        disabledExport,
        totalRows,
        handleClickExport,
        loadingExport
    } = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        state["offset"] = 0
        setState({...state})
    }

    return (
        <>

            <Grid item xs={6}>
                <InputText
                    disabled={inpoutDisabled}
                    label="Product Code"
                    name="productCode"
                    value={state.productCode}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    disabled={inpoutDisabled}
                    label="Options Code"
                    name="optionsCode"
                    value={state.optionsCode}
                    onChange={handleChange}
                />
            </Grid>


        </>
    )
}
