import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import { SnackbarProvider, useSnackbar } from 'notistack';

export default function PushNotifications(props) {

    const {msgError} = props

    return(
        <>
            <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MyApp msgError={msgError} />
            </SnackbarProvider>
        </>
    )
}

function MyApp(props) {
    const {msgError} = props

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        msgError.forEach((error) => {
            enqueueSnackbar(error, { variant: 'error' })
        })
    }, [msgError])

    return (<></>)
}
