import React, {useEffect, useState} from "react";
import {apiList as apiListProducts} from "../../../services/User/product";
import {apiGetSerie} from "../../../services/User/serie";
import {apiListOptions} from "../../../services/User/options";
import {Button, Divider, Grid, IconButton, InputAdornment, List, ListItem, TextField, Tooltip} from "@mui/material";
import Table from "../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../utils/constants";
import '../../../scss/Composer/index.scss';
import {VscBook, VscSettings} from "react-icons/vsc";
import TableColumns from "./TableColumns";
import {useTranslation} from "react-i18next";
import Filters from "./Filters";
import RightBar from "./RightBar";
import ModalQuotation from "./ModalQuotation";
import ModalCatalogue from "./ModalCatalogue";
import InputButton from "../../../components/Inputs/InputButton";

export default function CatalogueView(props) {

    const {
        isModal,
        product,
        setProduct,
        showCart,
    } = props

    const isModalIn = isModal ? isModal : false

    const {t, i18n} = useTranslation()

    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [btnShowCart, setBtnShowCart] = useState(showCart ? showCart : false)
    const [openModal, setOpenModal] = useState(false)
    const [openModalCatalogue, setOpenModalCatalogue] = useState(false)
    const [showCartModal, setShowCartModal] = useState(false)
    const [stateCart, setStateCart] = useState({offset: 0, sort: {field: "null", sort: "desc"} })

    useEffect(() => {
        setLoading(true)
        setRows([])
        setRowCount(0)
        const timeOutId = setTimeout(() => {
            if (state.typeQuery === "products") {
                getItemsDataProduct()
            } else {
                getItemsDataOptions()
            }
        }, 1000)
        return () => clearTimeout(timeOutId)
    }, [state])

    useEffect(() => {
        // Cuando cerramos el modal, realizamos la consulta para añadir las cantidades de los productos ya seleccionados
        setLoading(true)
        setRows([])
        setRowCount(0)
        if (!openModalCatalogue) {
            if (state.typeQuery === "products") {
                getItemsDataProduct()
            } else {
                getItemsDataOptions()
            }
        }
    }, [openModalCatalogue])


    const getItemsDataProduct = () => {
        setMsgError([])
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
        }

        if (state.productCode) {
            params.code = state.productCode
        }
        if (state.type) {
            params.type = [state.type]
        }
        if (state.category) {
            params.category = state.category
        }
        if (state.serie) {
            params.serie = state.serie
        }

        if (state.width) {
            params.width = state.width
        }

        if (state.description) {
            params.description = state.description
        }

        if (state.company) {
            params.company = state.company
        }

        params.isActive = true
        params.catalogue = true

        apiListProducts(params, 'post', i18n.resolvedLanguage).then(resp => {
            setRowCount(resp.data?.totalItems)


            let temp = resp.data.items?.map(doc => {
                let image = null

                if (doc.imgLower3d) {
                    image = doc.imgLower3d
                } else if (doc.imgUpper3d) {
                    image = doc.imgUpper3d
                } else if (doc.imgLower2d) {
                    image = doc.imgLower2d
                } else if (doc.imgUpper2d) {
                    image = doc.imgUpper2d
                }

                // Obtenemos la quantity del producto en caso de que exista
                doc.quantity = product.find(item => (item.code === doc.code && item.typeQuery === "products"))?.quantity ?? null

                return {
                    ...doc,
                    image: image,
                    typeQuery: "products"
                }
            }) ?? []
            setRows(temp)
            setLoading(false)

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    const getItemsDataOptions = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
        }

        if (state.optionsCode) {
            params.code = state.optionsCode
        }

        if (state.productCode) {
            params.productCode = state.productCode
        }

        if (state.type) {
            params.type = state.type
        }
        if (state.category) {
            params.category = state.category
        }
        if (state.serie) {
            params.serie = state.serie
        }

        if (state.description) {
            params.description = state.description
        }

        params.isActive = true

        apiListOptions(params, 'post', i18n.resolvedLanguage).then(resp => {
            setRowCount(resp.data?.totalItems)

            let temp = resp.data.items?.map(doc => {

                // Obtenemos la quantity del producto en caso de que exista
                doc.quantity = product.find(item => (item.code === doc.code && item.typeQuery === "options"))?.quantity ?? null

                return {
                    ...doc,
                    typeQuery: "options"
                }
            }) ?? []

            setRows(temp)
            setLoading(false)

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    const handleChangeCell = (value, element) => {

        let code = element.code
        let quantity = value

        // Detectamos si el id existe en el array y lo añádimos o actualizamos array
        if (product.find(item => item.code === code)) {
            // Actualizamos quantity en el array
            product.map(item => {
                if (item.code === code) {
                    item.quantity = quantity
                }
            })

            // Eliminamos en el caso de que el quantity sea 0
            if (!quantity || quantity === '0' || quantity === 0) {
                product.splice(product.findIndex(item => item.code === code), 1)
            }
            setProduct([...product])
        } else if (quantity > 0) {
            let temp = {
                typeQuery: element.typeQuery,
                quantity: quantity,
                element: element
            }
            // Merge objects temp + element
            setProduct([...product, {...element, ...temp}])
        }

        // Recorremos la Row y actualizamos la cantidad
        rows.map(item => {
            if (item.code === code) {
                item.quantity = quantity
            }
        })

    }

    const handleClickCode = (code) => {
        state.productCode = code
        state.optionsCode = ""
        state.typeQuery = "options"
        setState({...state})
    }

    return (
        <div style={{width: '100%', overflowY: 'auto', height: isModalIn ? '80vh' : 'calc(100vh - 45px)'}}>
            <div style={{float: 'left', width: isModalIn ? '100%' : 'calc(100% - 401px)', overflowY: 'auto', height: isModalIn ? '100%' : 'calc(100vh - 45px)'}}>
                <div style={{padding: isModalIn ? 0 : '40px 40px 0px 40px', marginBottom: isModalIn ? 0 : 100}}>
                    <Grid container>

                        {!isModalIn && (
                            <Grid item md={12}>
                                <h1 style={{marginTop: 0, marginBottom: 30}}>
                                    <VscBook style={{fontSize: 30, marginBottom: '-5px'}}/> {t('catalogue.Catalogue')}
                                </h1>
                            </Grid>
                        )}

                        {!btnShowCart && (
                            <>
                                <Grid item md={12}>
                                    <HeaderSelectQuery
                                        state={state}
                                        setState={setState}
                                    />
                                </Grid>

                                <Grid item md={12}>
                                    <Filters
                                        state={state}
                                        setState={setState}
                                        products={product}
                                        btnShowCart={btnShowCart}
                                        setBtnShowCart={setBtnShowCart}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Table
                                        columns={TableColumns(`${state?.typeQuery}`, handleClickCode)}
                                        rows={rows}
                                        handleChangeCell={handleChangeCell}
                                        rowCount={rowCount}
                                        filter={state}
                                        setFilter={setState}
                                        loading={loading}
                                    />
                                </Grid>
                            </>
                        )}

                        {btnShowCart && (
                            <Grid item md={12}>
                                <Table
                                    columns={TableColumns('cart')}
                                    rows={product}
                                    handleChangeCell={handleChangeCell}
                                    rowCount={product.length}
                                    filter={stateCart}
                                    setFilter={setStateCart}
                                    loading={loading}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            </div>

            {!isModalIn && (
                <>
                    <div id="box-right" style={{float: 'right', overflowY: 'auto', height: 'calc(100vh - 45px)'}}>
                        <div style={{display: 'block', marginBottom: 200}}>
                            <RightBar
                                loading={loading}
                                product={product}
                                handleClickCode={handleClickCode}
                                handleChangeCell={handleChangeCell}
                                editable={false}
                            />
                        </div>

                        <div style={{padding: 20, position: 'absolute', bottom: 0, width: 360, background: '#f6f7f9'}}>
                            <Button
                                variant="outlined"
                                style={{width: '100%'}}
                                color="error"
                                size={'small'}
                                onClick={() => {
                                    setOpenModalCatalogue(true)
                                    setShowCartModal(true)
                                }}
                            >
                                {t('general.Show details')}
                            </Button>

                            <InputButton
                                style={{width: '100%', marginTop: 20}}
                                text={t('quotation.Generate quotation')}
                                onClick={() => setOpenModal(true)}
                                color="primary"
                            />
                        </div>
                    </div>
                </>
            )}

            <ModalQuotation
                open={openModal}
                setOpen={setOpenModal}
                catalogueProducts={product}
            />

            <ModalCatalogue
                title={t('general.Show details')}
                showCart={showCartModal}
                open={openModalCatalogue}
                setOpen={setOpenModalCatalogue}
                catalogueProducts={product}
                setCatalogueProducts={setProduct}
            />

        </div>

    )
}

function initialValues() {
    return {
        offset: 0,
        sort: {field: "code", sort: "desc"},
        productCode: "",
        optionsCode: "",
        serie_id: "",
        company: "",
        typeQuery: "products",
        type: "",
        description: "",
        width: []
    }
}

function HeaderSelectQuery(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    return (
        <div style={{
            backgroundColor: '#f6f7f9',
            padding: 5,
            borderRadius: 10,
            marginBottom: 20,
            display: 'flex'
        }}>
            <div
                style={{
                    width: '50%',
                    textAlign: 'center',
                    background: state.typeQuery === 'products' ? '#fff' : 'transparent',
                    boxShadow: state.typeQuery === 'products' ? '0px 0px 5px rgba(0, 0, 0, 0.2)' : 'none',
                    color: state.typeQuery === 'products' ? '#ea0c1f' : '#677788',
                    padding: '10px 20px',
                    borderRadius: 10,
                    cursor: state.typeQuery === 'products' ? 'default' : 'pointer',
                }}
                onClick={() => {
                    state.typeQuery = "products"
                    setState({...state})
                }}
            >
                {t('catalogue.Products')}
            </div>
            <div
                style={{
                    width: '50%',
                    textAlign: 'center',
                    background: state.typeQuery === 'options' ? '#fff' : 'transparent',
                    boxShadow: state.typeQuery === 'options' ? '0px 0px 5px rgba(0, 0, 0, 0.2)' : 'none',
                    color: state.typeQuery === 'options' ? '#ea0c1f' : '#677788',
                    padding: '10px 20px',
                    borderRadius: 10,
                    cursor: state.typeQuery === 'options' ? 'default' : 'pointer',
                }}
                onClick={() => {
                    state.typeQuery = "options"
                    setState({...state})
                }}
            >
                {t('catalogue.Options')}
            </div>
        </div>
    )
}
