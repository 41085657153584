import View from "../../View";
import {Grid} from "@mui/material";
import InputButton from "../../../../../components/Inputs/InputButton";
import {useEffect, useState} from "react";
import {apiCheckUpper} from "../../../../../services/User/incompatibility";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import MsgError from "../../../../../components/MsgError";
import {useTranslation} from "react-i18next";

export default function ElementConcerned(props){
    const {
        msgError,
        handleClose,

        unionElementsEnd,
        setUnionElementsEnd,

        kitchenElements,
        elementsAffected,
        elementSelected,
        elementLowerTemp,
    } = props

    const {t, i18n} = useTranslation()

    const [loading, setLoading] = useState(false)
    const [msgErrorIn, setMsgErrorIn] = useState([])

    useEffect(() => {
        setLoading(true)
        setMsgErrorIn([])
        const timeOutId = setTimeout(() => {
            checkIncompatibility()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [elementsAffected])

    // Unimos los elementos afectados con el elemento seleccionado
    // Solo partes superiores las inferiores desapareceran
    const confirmUnionModuleUpper = () => {
        const indexSelect = kitchenElements.findIndex(element => element.id === elementSelected)
        kitchenElements[indexSelect].productCode = elementLowerTemp.code
        kitchenElements[indexSelect].totalWidth = elementLowerTemp.width
        kitchenElements[indexSelect].companyId = elementLowerTemp.companyId
        kitchenElements[indexSelect].price = elementLowerTemp.price
        kitchenElements[indexSelect].typeId = elementLowerTemp.type_id
        kitchenElements[indexSelect].typeName = elementLowerTemp.typeName
        kitchenElements[indexSelect].img = elementLowerTemp.imgLower3d
        kitchenElements[indexSelect].description = elementLowerTemp.description

        // Añadimos los elementos afectados
        elementsAffected.forEach((elementId, indexAffected) => {
            if (elementId !== kitchenElements[indexSelect].id) {
                kitchenElements[indexSelect].upper.push(kitchenElements.find(elementExport => elementExport.id === elementId).upper[0])
            }
        })

        // Eliminamos los elementos afectados
        elementsAffected.forEach(element => {
            const indexAffect = kitchenElements.findIndex(elementExport => elementExport.id === element)
            if (indexAffect !== indexSelect) {
                kitchenElements.splice(indexAffect, 1)
            }
        })

        setUnionElementsEnd(true)
    }

    const checkIncompatibility = () => {
        if (elementsAffected.length > 0) {
            let productCodeUpper = []
            let productCodeLower = elementLowerTemp?.code

            kitchenElements.forEach(element => {
                if (elementsAffected.includes(element.id)) {
                    productCodeUpper.push(element?.upper?.[0].productCode)
                }
            })

            apiCheckUpper({
                productCodeUpper,
                productCodeLower
            }).then(res => {
                setLoading(false)
            }).catch(err => {
                if (err.response?.data?.numErrors > 0) {
                    setMsgErrorIn(err.response.data.msg)
                }
                setLoading(false)
            })
        }
    }

    return (
        <>
            {(msgError.length === 0 && !unionElementsEnd) && (
                <>
                    {/* Recorremos kitchenElements en busca de los elementos afectados */}
                    <h3>{t('composer.Elements concerned')}</h3>

                    <LoadingSpinner show={loading} />

                    <MsgError msg={msgErrorIn} />

                    {!loading && (
                        <div style={{display: 'flex', background: '#fff', padding: 10, borderRadius: 5}}>
                            {kitchenElements.map((element, index) => {
                                if (elementsAffected.includes(element.id)) {
                                    return (
                                        <View element={element} elementSelected={null} setElementSelected={null}/>
                                    )
                                }
                            })}
                        </div>
                    )}

                    <br/>
                    <br/>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={t('general.Cancel')}
                                onClick={handleClose}
                                color="secondary"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <InputButton
                                style={{width: '100%'}}
                                text={`${t('general.Confirm and next')} →`}
                                disabled={loading || msgErrorIn.length > 0}
                                onClick={confirmUnionModuleUpper}
                            />
                        </Grid>
                    </Grid>

                </>
            )}
        </>
    )
}
