import { BASE_PATH, BASE_PATH_EXTENSION } from "../../utils/constants";
import axios from 'axios';
import {getToken} from "./token";

const baseUrl = `${BASE_PATH_EXTENSION}/auth`;

export function apiLogin(data, lang="en") {
    return axios.post(`${BASE_PATH}/api/login-check`, data)
}

export function apiRegister(data, lang="en") {
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/register`, data)
}

export function apiSendRecoveryEmail(data, lang="en") {
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/recovery-email`, data)
}

export function apiRecoverPassword(data, token, lang="en") {
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/change-password/${token}/${data.password}`, {})
}

export function apiImpersonate(id, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/impersonate/${id}`, config)
}

export function apiSmsSend(token, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/sms`, config)
}

export function apiSmsCheck(token, code, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/sms-check/${code}`, config)
}

