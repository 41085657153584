import React, {useEffect, useState} from "react";
import {Button, Chip, Grid, IconButton, Tooltip} from "@mui/material";
import ButtonFile from "../../../../components/Inputs/ButtonFile";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {apiExport} from "../../../../services/Admin/export";
import MsgError from "../../../../components/MsgError";
import {LoadingButton} from "@mui/lab";
import {apiImport} from "../../../../services/Admin/import";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import MsgSuccess from "../../../../components/MsgSuccess";
import {useTranslation} from "react-i18next";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

export default function Management() {
    const {i18n} = useTranslation()
    const [loadingExport, setLoadingExport] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)

    const [type, setType] = useState(null)

    const [msgSuccess, setMsgSuccess] = useState([])
    const [msgErrorExport, setMsgErrorExport] = useState([])
    const [msgErrorImport, setMsgErrorImport] = useState([])

    const [fileType, setFileType] = useState(null)
    const [file, setFile] = useState(null)

    const optionsType = [
        {id: 12, name: "Category", type: "category"},
        {id: 1, name: "Product - Upper", type: "upper"},
        {id: 2, name: "Product - Lower", type: "lower"},
        {id: 3, name: "Product - SBR Element", type: "lower-element"},
        {id: 4, name: "Product - Module", type: "module"},
        {id: 5, name: "Product - Module Compact", type: "module-compact"},
        {id: 6, name: "Product - Incompatibility", type: "incompatibility"},
        {id: 11, name: "Product - Incompatibility SBR", type: "incompatibility-sbr"},
        {id: 7, name: "Options", type: "options"},
        {id: 8, name: "Relationships | Product - Option", type: "product-option"},
        {id: 9, name: "Relationships | Product - SBR Element", type: "product-lower-element"},
    ]

    const exportTemplate = (event) => {
        setMsgErrorExport([])
        setLoadingExport(true)
        apiExport(type, i18n.resolvedLanguage).then(resp => {
            setLoadingExport(false)
            // Downloader file
            const link = document.createElement('a')
            link.href = resp.data.url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click()
            link.remove()

        }).catch(err => {
            setLoadingExport(false)
            setMsgErrorExport(err.response.data?.msg)
        })
    }

    const importFile = (event) => {
        setFile(event.target.value[0])
        setLoadingFile(true)
        setMsgErrorImport([])
        setFileType(null)
        setMsgSuccess([])

        let responseType = detectTypeFile(event.target.value[0].name)

        if (responseType){
            const type = event.target.value[0].name.split(".")
            const typeFile = optionsType.find(item => item.type === type[0])

            let f = new FormData()
            f.append("file[]", event.target.value[0]?.binary)


            apiImport(typeFile?.id, f, i18n.resolvedLanguage).then(resp => {
                setLoadingFile(false)
                setFile(null)
                setFileType(null)
                setMsgSuccess(resp.data?.msg)
            }).catch(err => {
                setLoadingFile(false)
                setMsgErrorImport(err.response.data?.msg)
                setFile(null)
            })
        } else {
            setLoadingFile(false)
        }
    }

    const detectTypeFile = (file) => {
        const type = file.split(".")

        // Comprobamos el formato del fichero
        if (type[type.length - 1] !== 'xlsx') {
            setMsgErrorImport(['The file format is not valid (.xlsx)'])
            return false
        }

        // Buscamos el tipo de archivo
        const typeFile = optionsType.find(item => item.type === type[0])

        if (typeFile) {
            setFileType(typeFile?.name)
            return true
        } else {
            setMsgErrorImport(['The file name is not valid'])
            setFileType(null)
            return false
        }
    }

    return (
        <>
            <HeaderPage title="Import manager"/>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <p className="w-600">
                        Remember that to carry out an import, it is necessary that the file you are going to upload has
                        the same structure as the template file.
                    </p>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 300}}>
                        <InputSelect
                            label="Type"
                            name="type"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                            options={optionsType}
                        />
                        <div style={{paddingTop: 10}}>
                            <InputButtonLoading
                                onClick={exportTemplate}
                                loading={loadingExport}
                                label={`Get Template / Export`}
                                style={{width: '100%'}}
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 600}}>
                        <MsgError msg={msgErrorExport}/>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <br/>
                    <br/>
                    <p className="w-600">
                        If you are sure the file is correct, use the button below to import. Remember to keep the name
                        of the template.
                    </p>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 300}}>

                        {fileType && (<Chip label={`Type: ${fileType}`} variant="outlined" style={{width: '100%', marginBottom: 20}}/>)}
{}
                        <ButtonFile
                            label="Select File"
                            loading={loadingFile}
                            name="newImage"
                            onChange={importFile}
                            multiple={true}
                            value={file}
                            style={{width: '100%'}}
                        />

                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 600}}>
                        <MsgError msg={msgErrorImport}/>
                        <MsgSuccess msg={msgSuccess}/>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

