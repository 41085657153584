import React, {useEffect, useState} from "react";
import {apiLogin} from "../../../services/Auth/login";
import useAuth from "../../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {TextField} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MsgError from "../../../components/MsgError";
import {LoadingButton} from "@mui/lab";
import {RouteAuth} from "../../../routes/RouteAuth";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import MsgSuccess from "../../../components/MsgSuccess";
import jwtDecode from "jwt-decode";
import {RouteUser} from "../../../routes/RouteUser";
import logo from '../../../assets/images/logo-scaled.png'
import FA2 from "../Login/FA2";
import pjson from "../../../../package.json";
import MenuLanguage from "../../../components/Layouts/Admin/MenuLanguage";
import {useTranslation} from "react-i18next";

const theme = createTheme();

export default function LoginForm() {

    const {login} = useAuth()
    const {i18n, t} = useTranslation()
    const navigate = useNavigate()
    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    return (
        <>
            <ThemeProvider theme={theme}>
                <div style={{minHeight: '100vh', display: 'flex', alignItems: 'center'}}>
                    <Container component="main" maxWidth="xs"
                               style={{padding: '48px 40px 36px', border: '1px solid #dadce0', borderRadius: '8px'}}>
                        <CssBaseline/>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <img style={{width: 150}} src={logo}/>
                            <br/>
                            <Typography component="h1" variant="h5">
                                {t('PAGE_LOGIN.Thank you for registering!')}
                            </Typography>

                            <Box component="form" noValidate sx={{mt: 2}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <p style={{textAlign: 'center', marginBottom: 25}}>{t('PAGE_LOGIN.We have received your registration and will be in touch with you as soon as possible.')}</p>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <Link to={RouteAuth.LOGIN.path} style={{
                                            textDecoration: 'none',
                                            fontFamily: 'AdminFontBold',
                                            fontSize: '14px',
                                            color: color,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            display: 'table'
                                        }}>
                                            {t('PAGE_LOGIN.Log In')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </div>
            </ThemeProvider>

            <div style={{
                color: "gray",
                position: 'absolute',
                bottom: 20,
                left: 20,
                fontSize: 15,
            }}><MenuLanguage showLang={true} /></div>
        </>
    )
}

function initialValues() {
    return {
        username: "", password: ""
    }
}

function validationSchema(schema, t) {

    let error = []

    if (!schema.username) {
        error.push(t('PAGE_LOGIN.The email is required'))
    }
    if (!schema.password) {
        error.push(t('PAGE_LOGIN.The password is required'))

    }

    return error
}
