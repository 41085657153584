import * as React from 'react';
import Debugger from "../Components/Debugger";
import Info from "../Components/Info";
import Options from "../Components/Options";
import Accessories from "../Components/Accessories";
import CustomProducts from "../Components/CustomProducts";
import Catalogue from "../Components/Catalogue";
import Incompatibility from "../Components/Incompatibility";
import Notifications from "../Components/Notifications";

export default function BoxRight(props) {
    const {
        showMenu,
        setShowMenu,
        kitchenElements,
        setKitchenElements,
        kitchenElementsSecondarySide,
        elementSelected,
        lastElementAdd,
        menuRightSelected,
        openModal,
        categoriesElementLower,
        state,
        elementLowerTemp,
        notifications,
        setNotifications,
        availableLength,
        accessories,
        setAccessories,
        catalogueProducts,
        setCatalogueProducts,
        customProducts,
        setCustomProducts,
        side,
        totalIncomplete,
        setTotalIncomplete,
        incompleteLoading,
        setIncompleteLoading
    } = props

    return (
        <div style={{display: showMenu !== '' ? 'block' : 'none'}}>
            <div id="box-right" style={{overflowY: 'auto', height: 'calc(100vh - 40px)'}}>

                <InfoOptions
                    showMenu={showMenu}
                    kitchenElements={kitchenElements}
                    setKitchenElements={setKitchenElements}
                    elementSelected={elementSelected}
                />

                <Accessories
                    showMenu={showMenu}
                    accessories={accessories}
                    setAccessories={setAccessories}
                    kitchenElements={kitchenElements}
                    kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                />

                <CustomProducts
                    showMenu={showMenu}
                    customProducts={customProducts}
                    setCustomProducts={setCustomProducts}
                />

                <Catalogue
                    showMenu={showMenu}
                    catalogueProducts={catalogueProducts}
                    setCatalogueProducts={setCatalogueProducts}
                />

                <Incompatibility
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    kitchenElements={kitchenElements}
                    kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                    side={side}
                    totalIncomplete={totalIncomplete}
                    setTotalIncomplete={setTotalIncomplete}
                    incompleteLoading={incompleteLoading}
                    setIncompleteLoading={setIncompleteLoading}
                />

                <Notifications
                    showMenu={showMenu}
                    kitchenElements={kitchenElements}
                    kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                    notifications={notifications}
                    setNotifications={setNotifications}
                />

                <Debugger
                    showMenu={showMenu}
                    kitchenElements={kitchenElements}
                    elementSelected={elementSelected}
                    lastElementAdd={lastElementAdd}
                    menuRightSelected={menuRightSelected}
                    openModal={openModal}
                    categoriesElementLower={categoriesElementLower}
                    state={state}
                    elementLowerTemp={elementLowerTemp}
                    availableLength={availableLength}
                    accessories={accessories}
                    kitchenElementsSecondarySide={kitchenElementsSecondarySide}
                    catalogueProducts={catalogueProducts}
                    customProducts={customProducts}
                />

            </div>
        </div>
    )
}

function InfoOptions(props) {

    const {
        showMenu,
        kitchenElements,
        setKitchenElements,
        elementSelected,
    } = props

    return (
        <>
            <Info
                showMenu={showMenu}
                kitchenElements={kitchenElements}
                elementSelected={elementSelected}
            />
            <Options
                showMenu={showMenu}
                kitchenElements={kitchenElements}
                setKitchenElements={setKitchenElements}
                elementSelected={elementSelected}
            />
        </>
    )
}
