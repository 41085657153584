import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import logo from "../../../assets/images/logo-scaled.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MsgError from "../../../components/MsgError";
import {Link} from "react-router-dom";
import {RouteAuth} from "../../../routes/RouteAuth";
import {LoadingButton} from "@mui/lab";
import React, {useEffect} from "react";
import {apiSmsCheck, apiSmsSend} from "../../../services/Auth/login";
import LoadingSpinner from "../../../components/LoadingSpinner";
import Cronometro from "../../../components/Auth/Cronometro";
import {useTranslation} from "react-i18next";

export default function FA2(props) {

    const {
        token,
        setFa2,
        setInitSession
    } = props
    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
    const {i18n, t} = useTranslation()

    const [loading, setLoading] = React.useState(false)
    const [loadingInit, setLoadingInit] = React.useState(true)
    const [msgError, setMsgError] = React.useState([])
    const [phone, setPhone] = React.useState(null)

    const [time, setTime] = React.useState(null)
    const [code, setCode] = React.useState({
        code1: null,
        code2: null,
        code3: null,
        code4: null,
        code5: null,
        code6: null,
    })

    useEffect(() => {
        sendCode()
    }, [token])

    const sendCode = () => {
        setMsgError([])
        if (token) {
            setLoadingInit(true)
            setTime(null)
            apiSmsSend(token, i18n.resolvedLanguage).then((res) => {
                setPhone(res.data.phone)
                setTime(res.data.time)
                setLoadingInit(false)
            }).catch((err) => {
                setPhone(err.response.data.phone)
                setMsgError(err.response.data.msg)
                setLoadingInit(false)
            })
        } else {
            setFa2(false)
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        setMsgError([])
        let tempCode = `${code.code1 ?? 0}${code.code2 ?? 0}${code.code3 ?? 0}${code.code4 ?? 0}${code.code5 ?? 0}${code.code6 ?? 0}`

        apiSmsCheck(token, tempCode, i18n.resolvedLanguage).then((res) => {
            resetCode()
            setInitSession(true)
        }).catch((err) => {
            resetCode()
            setLoading(false)
            setMsgError(err.response.data.msg)
        })
    }

    const resetCode = () => {
        setCode({
            code1: null,
            code2: null,
            code3: null,
            code4: null,
            code5: null,
            code6: null,
        })
        document.getElementsByName('code1')[0].focus()
    }

    const handleChange = (e) => {
        const {name, value} = e.target

        // Si pulsamos la tecla remove (backspace)
        if (e.key === 'Backspace') {
            // Valor una pocicion por detras
            if (name === 'code2') {
                setCode({...code, ['code1']: null})
                document.getElementsByName('code1')[0].focus()
            }
            if (name === 'code3') {
                setCode({...code, ['code2']: ''})
                document.getElementsByName('code2')[0].focus()
            }
            if (name === 'code4') {
                setCode({...code, ['code3']: null})
                document.getElementsByName('code3')[0].focus()
            }
            if (name === 'code5') {
                setCode({...code, ['code4']: null})
                document.getElementsByName('code4')[0].focus()
            }
            if (name === 'code6') {
                setCode({...code, ['code5']: null})
                document.getElementsByName('code5')[0].focus()
            }
            return
        }

        // Si el valor es mayor que 9, no hacemos nada
        if (value > 9) {
            return
        }

        // Si el valor es menor que 0, no hacemos nada
        if (value < 0) {
            return
        }

        setCode({...code, [name]: value})
    }

    useEffect(() => {
        // Detectamos si el code1 estalleno y si lo esta, pasamos el focus al code2
        if (code.code1) {
            document.getElementsByName('code2')[0].focus()
        }
        if (code.code2) {
            document.getElementsByName('code3')[0].focus()
        }
        if (code.code3) {
            document.getElementsByName('code4')[0].focus()
        }

        if (code.code4) {
            document.getElementsByName('code5')[0].focus()
        }
        if (code.code5) {
            document.getElementsByName('code6')[0].focus()
        }
        if (code.code6) {
            document.getElementsByName('code6')[0].blur()
            handleSubmit()
        }

    }, [code])

    return (
        <div style={{minHeight: '100vh', display: 'flex', alignItems: 'center'}}>
            <Container component="main" style={{
                padding: '48px 40px 36px',
                border: '1px solid #dadce0',
                borderRadius: '8px',
                width: 550
            }}>
                <CssBaseline/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <img style={{width: 150}} src={logo}/>
                    <Typography component="h1" variant="h5" style={{marginTop: 20, fontWeight: 700}}>
                        {t('PAGE_SMS.Two-step authentication')}
                    </Typography>

                    <Box component="form" noValidate sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <MsgError msg={msgError}/>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            {loadingInit && (
                                <Grid item xs={12}>
                                    <LoadingSpinner show={loadingInit}/>
                                </Grid>
                            )}
                            {!loadingInit && (
                                <>
                                    <Grid item xs={12}>
                                        {t('PAGE_SMS.To continue, write the 6-digit verification code sent to your phone that ends in')}
                                        <span style={{fontWeight: 'bold'}}>{phone}</span>.
                                        <br/>
                                        <br/>
                                        {t('PAGE_SMS.Didn t receive a code?')}
                                        <span
                                            style={{color: color, fontWeight: 'bold', padding: '0 5px', cursor: 'pointer'}}
                                            onClick={sendCode}
                                        >
                                            {t('PAGE_SMS.Resend')}
                                        </span>
                                        <br/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Cronometro time={time} setTime={setTime}/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div style={{
                                            display: 'block',
                                            marginRight: 'auto',
                                            marginLeft: 'auto',
                                            width: 335
                                        }}>
                                            <InputCode
                                                name="code1"
                                                value={code?.code1}
                                                onChange={handleChange}
                                                borderTopLeftRadius={true}
                                                borderBottomLeftRadius={true}
                                            />
                                            <InputCode
                                                name="code2"
                                                value={code?.code2}
                                                onChange={handleChange}
                                            />
                                            <InputCode
                                                name="code3"
                                                value={code?.code3}
                                                onChange={handleChange}
                                                borderTopRightRadius={true}
                                                borderBottomRightRadius={true}
                                            />

                                            <span style={{
                                                fontSize: 30,
                                                padding: 10,
                                                color: '#dadce0',
                                                fontWeight: 'bold'
                                            }}>-</span>

                                            <InputCode
                                                name="code4"
                                                value={code?.code4}
                                                onChange={handleChange}
                                                borderTopLeftRadius={true}
                                                borderBottomLeftRadius={true}
                                            />
                                            <InputCode
                                                name="code5"
                                                value={code?.code5}
                                                onChange={handleChange}
                                            />
                                            <InputCode
                                                name="code6"
                                                value={code?.code6}
                                                onChange={handleChange}
                                                borderTopRightRadius={true}
                                                borderBottomRightRadius={true}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                        <LoadingButton
                                            type="button"
                                            loading={loading}
                                            loadingPosition="start"
                                            variant="contained"
                                            fullWidth
                                            onClick={handleSubmit}
                                            sx={{mt: 1, mb: 0}}
                                        >
                                            {t('general.Continue')}
                                        </LoadingButton>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <br/>
                                        <Link
                                            to={RouteAuth.LOGIN.path}
                                            style={{
                                                textDecoration: 'none',
                                                fontFamily: 'AdminFontBold',
                                                fontSize: '14px',
                                                color: color,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                display: 'table'
                                            }}
                                            onClick={() => {
                                                setFa2(false)
                                            }}
                                        >
                                            {t('PAGE_SMS.Back to Login')}
                                        </Link>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

function InputCode(props) {

    const {
        value,
        name,
        onChange,
        borderTopRightRadius,
        borderTopLeftRadius,
        borderBottomRightRadius,
        borderBottomLeftRadius,
    } = props

    return (
        <input
            autoFocus={name === 'code1'}
            type="text"
            value={value || ''}
            name={name}
            onChange={onChange}
            onKeyUp={(e) => {
                if (e.key === 'Backspace') {
                    onChange(e)
                }
            }}
            style={{
                width: 50,
                height: 60,
                textAlign: 'center',
                fontSize: 30,
                border: '1px solid #dadce0',
                borderRight: ((borderTopRightRadius && borderBottomRightRadius) || (!borderTopRightRadius && !borderTopLeftRadius && !borderBottomRightRadius && !borderBottomLeftRadius)) ? '1px solid #dadce0' : 'none',
                borderLeft: ((borderTopLeftRadius && borderBottomLeftRadius) || (!borderTopRightRadius && !borderTopLeftRadius && !borderBottomRightRadius && !borderBottomLeftRadius)) ? '1px solid #dadce0' : 'none',
                borderTopRightRadius: borderTopRightRadius ? 10 : 0,
                borderTopLeftRadius: borderTopLeftRadius ? 10 : 0,
                borderBottomRightRadius: borderBottomRightRadius ? 10 : 0,
                borderBottomLeftRadius: borderBottomLeftRadius ? 10 : 0,
            }}
        />
    )
}
