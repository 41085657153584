import React from "react";
import {VscSettings} from "react-icons/vsc";
import {IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function TableColumns(type, handleClickCode) {

    if (type === 'products') {
        return Type1(handleClickCode)
    }
    if (type === 'options') {
        return Type2()
    }
    if (type === 'cart') {
        return Cart()
    }
}

function Type1(handleClickCode) {
    const {t} = useTranslation()

    return [
        {
            field: "image",
            headerName: t('general.Image'),
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                        <img src={params.image} style={{width: "100%", maxHeight: "70px"}}/>
                    </div>
                )
            },
        },
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
        },
        {
            field: "serie_name",
            headerName: t('general.Serie'),
            sortable: false,
            flex: 1,
        },
        {
            field: "description",
            headerName: t('general.Description'),
            sortable: false,
            flex: 1
        },
        {
            field: "quantity",
            headerName: t('general.Quantity'),
            sortable: false,
            number: true,
            flex: 1
        },
        {
            field: "Options",
            headerName: t('general.Options'),
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <span style={{paddingLeft: 20}}>
                        <IconButton
                            size="small"
                            onClick={() => handleClickCode(`${params.code}`)}

                        >
                            <VscSettings fontSize="inherit" size="25"/>
                        </IconButton>
                    </span>

                )
            },
        },
    ]
}

function Type2() {
    const {t} = useTranslation()

    return [
        {
            field: "code",
            headerName: "CODE",
            sortable: true,
            flex: 1,
        },
        {
            field: "description",
            headerName: t('general.Description'),
            sortable: false,
            flex: 1
        },
        {
            field: "quantity",
            headerName: t('general.Quantity'),
            sortable: false,
            number: true,
            flex: 1
        }
    ]
}

function Cart() {
    const {t} = useTranslation()

    return [
        {
            field: "typeQuery",
            headerName: "Query",
            sortable: false,
            flex: 1,
        },
        {
            field: "image",
            headerName: t('general.Image'),
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "70px"}}>
                        <img src={params.image} style={{width: "100%", maxHeight: "70px"}}/>
                    </div>
                )
            },
        },
        {
            field: "code",
            headerName: "CODE",
            sortable: false,
            flex: 1,
        },
        {
            field: "serie_name",
            headerName: t('general.Serie'),
            sortable: false,
            flex: 1,
        },
        {
            field: "description",
            headerName: t('general.Description'),
            sortable: false,
            flex: 1
        },
        {
            field: "quantity",
            headerName: t('general.Quantity'),
            sortable: false,
            number: true,
            flex: 1
        },
    ]
}
