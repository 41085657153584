import React, {useState, useEffect} from "react";
import DndElements from "../../../../components/DndElements";

export default function BoxKitchen(props) {

    const {kitchenElements, setKitchenElements, isDragDisabled} = props

    return (
        <>
            <DndElements
                elementDnd={kitchenElements}
                setElementDnd={setKitchenElements}
                isDragDisabled={isDragDisabled}
            />
        </>
    )
}
