import React, {useEffect, useState} from "react";
import {apiList} from "../../../../../services/User/product";
import {Grid} from "@mui/material";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import Table from "../../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../../utils/constants";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import Filters from "./Filters";
import {apiListOptions} from "../../../../../services/User/options";
import {useTranslation} from "react-i18next";
import InputButtonLoading from "../../../../../components/Inputs/InputButtonLoading";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingExport, setLoadingExport] = React.useState(false)

    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])
    const params = new URLSearchParams(window.location.search)
    const {i18n} = useTranslation()

    useEffect(() => {
        if (params.get("code")) {
            setState({...state, type: null, code: params.get("code")})
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        setRowCount(0)

        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = (isExport) => {
        setMsgError([])
        if (isExport) {
            setLoadingExport(true)
        }

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            isExport: isExport ? true : false
        }


        if (state.code) {
            params.code = state.code
        }
        if (state.type) {
            params.type = state.type
        }
        if (state.category) {
            params.category = state.category
        }
        if (state.serie) {
            params.serie = state.serie
        }

        if (state.productCode) {
            params.productCode = state.productCode
        }

        if (state.description) {
            params.description = state.description
        }

        params.isActive = state.isActive

        apiListOptions(params, 'post', i18n.resolvedLanguage).then(resp => {
            if (isExport) {
                // Downloader file
                const link = document.createElement('a')
                link.href = resp.data.url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click()
                link.remove()

            } else {
                setRowCount(resp.data?.totalItems)

                let temp = resp.data.items?.map(doc => {
                    return {
                        ...doc
                    }
                })
                setRows(temp)
            }

            setLoadingExport(false)
            setLoading(false)

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExport(false)
            if (!isExport) {
                setRows([])
            }
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }

    }

    return (
        <>
            <HeaderPage title="Options">
                <InputButtonLoading
                    onClick={() => getItemsData('export')}
                    loading={loadingExport}
                    label={`Export results ${rowCount}`}
                    variant="outlined"
                />
            </HeaderPage>

            <Grid container spacing={2}>

                <Filters
                    inpoutDisabled={loadingExport}
                    state={state}
                    setState={setState}
                    totalRows={rowCount}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function tableColumns() {
    return [
        {
            field: "code",
            headerName: "code",
            sortable: false,
            flex: 1,
        },
        {
            field: "price",
            headerName: "price",
            sortable: false,
            flex: 1,
        },
        {
            field: "text_en_description",
            headerName: "description en",
            sortable: false,
            flex: 1,
        },
        {
            field: "text_fr_description",
            headerName: "description fr",
            sortable: false,
            flex: 1,
        }
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
        code: "",
        productCode: "",
        description: "",
        isActive: true,
    }
}
