import React from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Inputs/InputText";
import InputSwitch from "../../../../../components/Inputs/InputSwitch";
import {useTranslation} from "react-i18next";
import InputCheckbox from "../../../../../components/Inputs/InputCheckbox";

export default function Filters(props) {

    const {
        inpoutDisabled,
        state,
        setState,
    } = props

    const {i18n} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        state["offset"] = 0
        setState({...state})
    }

    return (
        <>

            <Grid item xs={8}>
                <InputText
                    disabled={inpoutDisabled}
                    label="Optional Code"
                    placeholder="code1, code2, code3, ..."
                    name="code"
                    value={state.code}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputText
                    disabled={inpoutDisabled}
                    label="Product Code"
                    name="productCode"
                    value={state.productCode}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={8}>
                <InputText
                    disabled={inpoutDisabled}
                    label={`Description ( ${i18n.resolvedLanguage} )`}
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <div style={{float: 'right'}}>
                    <InputCheckbox
                        label="is Active"
                        name="isActive"
                        checked={state?.isActive}
                        onChange={(e) => {
                            state.isActive = e.target.checked
                            setState({...state})
                        }}
                    />
                </div>
            </Grid>

        </>
    )
}
