import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/options`;

export function apiListProductOptions(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.isExport) { query += `&isExport=${props.isExport}`; }
    if (props.limit) { query += `&limit=${props.limit}`; }
    if (props.offset) { query += `&offset=${props.offset}`; }
    if (props.field) { query += `&field=${props.field}`; }
    if (props.sort) { query += `&sort=${props.sort}`; }
    if (props.productCode) { query += `&productCode=${props.productCode}`; }
    if (props.optionsCode) { query += `&optionsCode=${props.optionsCode}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?1=1${query}`, config)
}
