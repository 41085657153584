import React, {useEffect, useState} from "react";
import {apiLogin} from "../../../services/Auth/login";
import useAuth from "../../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {TextField} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MsgError from "../../../components/MsgError";
import {LoadingButton} from "@mui/lab";
import {RouteAuth} from "../../../routes/RouteAuth";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import MsgSuccess from "../../../components/MsgSuccess";
import jwtDecode from "jwt-decode";
import {RouteUser} from "../../../routes/RouteUser";
import logo from '../../../assets/images/logo-scaled.png'
import FA2 from "./FA2";
import pjson from "../../../../package.json";
import MenuLanguage from "../../../components/Layouts/Admin/MenuLanguage";
import {useTranslation} from "react-i18next";
import ModalNewPlatform from "./ModalNewPlatform";

const theme = createTheme();

export default function LoginForm() {

    const {login} = useAuth()
    const {i18n, t} = useTranslation()
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    // 2FA
    const [fa2, setFa2] = useState(false)
    const [initSession, setInitSession] = useState(false)

    const pjson = require("../../../../package.json")

    // Lo usamos para el 2FA
    useEffect(() => {
        if (initSession) {
            startLogin(token)
        }

        if (!fa2) {
            setLoading(false)
        }
    }, [initSession, fa2])

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        setLoading(true)
        setModalOpen(false)
        setToken(null)
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state, t)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return
        }

        apiLogin(state, i18n.resolvedLanguage).then(resp => {
            setToken(resp.data?.token)
            setInitSession(false)
            // Fecha actual timestamp
            let currentDate = new Date().getTime()
            if (jwtDecode(resp.data?.token).isSmsExpiration) {
                //setFa2(true)
                startLogin(resp.data?.token)
            } else {
                startLogin(resp.data?.token)
            }

        }).catch(err => {
            if (err.response?.data?.msg) {
                setMsgError(err.response?.data?.msg)
            } else {
                setMsgError(["Parece que algo ha ido mal, inténtalo más tarde o contacta con el administrador."])
            }
            setLoading(false)
            setModalOpen(true)
        })
    }

    const startLogin = async (token) => {
        if (jwtDecode(token).roles[0] === "ROLE_ADMIN") {
            await login({
                primaryUser: token,
                secondaryUser: token,
                initial: true
            })
            navigate(RouteAdmin.USER_MANAGEMENT.path)
            setLoading(false)

        } else {
            await login({
                primaryUser: token,
                initial: true
            })
            navigate(RouteUser.ROOT.path)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    return (
        <>
            <ModalNewPlatform
                open={modalOpen}
                setOpen={setModalOpen}
            />
            <ThemeProvider theme={theme}>
                {fa2 && (
                    <FA2
                        token={token}
                        setFa2={setFa2}
                        setInitSession={setInitSession}
                    />
                )}
                <div style={{minHeight: '100vh', display: fa2 ? 'none' : 'flex', alignItems: 'center'}}>
                    <Container component="main" maxWidth="xs"
                               style={{padding: '48px 40px 36px', border: '1px solid #dadce0', borderRadius: '8px'}}>
                        <CssBaseline/>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <img style={{width: 150}} src={logo} alt={""}/>
                            <Typography component="h1" variant="h5">
                                {t('PAGE_LOGIN.Log In')}
                            </Typography>

                            <Box component="form" noValidate sx={{mt: 3}}>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <MsgError msg={msgError}/>
                                        <MsgSuccess msg={msgSuccess}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            id="username"
                                            label={t('PAGE_LOGIN.Email Address')}
                                            value={state.username || ''}
                                            name="username"
                                            autoComplete="email"
                                            onChange={handleChange}
                                            autoFocus
                                            InputLabelProps={{shrink: true}}
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: color,
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: color,
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover fieldset': {
                                                        borderColor: color,
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: color,
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            value={state.password || ''}
                                            fullWidth
                                            name="password"
                                            label={t('PAGE_LOGIN.Password')}
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            onChange={handleChange}
                                            InputLabelProps={{shrink: true}}
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: color,
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: color,
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover fieldset': {
                                                        borderColor: color,
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: color,
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs sx={{mb: 3}} display="flex" justifyContent="flex-end">
                                        <Link to={RouteAuth.RECOVER_PASSWORD.path} style={{
                                            textDecoration: 'none',
                                            fontFamily: 'AdminFontBold',
                                            fontSize: '14px',
                                            color: color
                                        }}>
                                            {t('PAGE_LOGIN.Forgot password?')}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Link to={RouteAuth.SIGNUP.path} style={{
                                            textDecoration: 'none',
                                            fontFamily: 'AdminFontBold',
                                            fontSize: '14px',
                                            color: color
                                        }}>
                                            {t('PAGE_LOGIN.Sign Up')}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LoadingButton
                                            type="button"
                                            loading={loading}
                                            loadingPosition="start"
                                            variant="contained"
                                            fullWidth
                                            onClick={handleSubmit}
                                            sx={{mt: 1, mb: 0}}
                                        >
                                            {t('PAGE_LOGIN.Log In')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </div>
            </ThemeProvider>

            <div style={{
                color: "gray",
                position: 'absolute',
                bottom: 20,
                left: 20,
                fontSize: 15,
            }}><MenuLanguage showLang={true} /></div>

            <div style={{
                color: "gray",
                position: 'absolute',
                bottom: 20,
                right: 20,
                fontSize: 15
            }}>v.{pjson.version}</div>
        </>
    )
}

function initialValues() {
    return {
        username: "", password: ""
    }
}

function validationSchema(schema, t) {

    let error = []

    if (!schema.username) {
        error.push(t('PAGE_LOGIN.The email is required'))
    }
    if (!schema.password) {
        error.push(t('PAGE_LOGIN.The password is required'))

    }

    return error
}
