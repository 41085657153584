import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import {VscChromeClose} from "react-icons/vsc";
import {LoadingButton} from "@mui/lab";
import {apiSendRecoveryEmail} from "../../../services/Auth/login";
import InputButton from "../../../components/Inputs/InputButton";
import {RouteAuth} from "../../../routes/RouteAuth";
import {useTranslation} from "react-i18next";

export default function ModalNewPlatform(props) {
    const {
        open,
        setOpen,
    } = props

    const {i18n, t} = useTranslation()

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        setMsgSuccess([])
        setMsgError([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return false;
        }

        apiSendRecoveryEmail(state, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter: ' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={1} style={{margin: 0}}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5, marginBottom: 0}}>{t('PAGE_LOGIN.Attention')}</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 26,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <p>
                                {t('PAGE_LOGIN.We are pleased to inform you that the Rosinox platform has been updated and improved to provide a better user experience. However, in order to access the new platform, it is necessary for you to reset your password. If you have not yet reset your password, you will need to do so before being able to access the new platform.')}
                            </p>

                            <p>
                                {t('PAGE_LOGIN.If you have not yet reset your password, you will need to do so before being able to access the new platform.')}
                            </p>
                        </Grid>

                        <Grid item xs={6}>
                            <InputButton
                                variant="outlined"
                                style={{width: '100%'}}
                                onClick={handleClose}
                                text={t('PAGE_LOGIN.Log In')}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <InputButton
                                style={{width: '100%'}}
                                href={RouteAuth.RECOVER_PASSWORD.path}
                                text={t('PAGE_LOGIN.Forgot password?')}
                            />
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
