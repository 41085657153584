import React from "react";
import useAuth from "../../../hooks/useAuth";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

export default function AdminBar() {
    const {auth, login} = useAuth()

    const handleExitUser = async (e) => {
        await login({
            primaryUser: auth?.secondaryUser,
            secondaryUser: auth?.secondaryUser,
            backImpersonate: true
        })
    }

    if (auth?.secondaryUser?.role !== "ROLE_ADMIN") {return(<></>)}

    return (
        <div style={{width: "100vw", background: "black", position: "fixed", bottom: "0px", zIndex: "9999"}}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{backgroundColor: "#232323"}}>
                    <Toolbar style={{minHeight: 30, borderTop: '1px solid rgba(255, 255, 255, 0.5)'}}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } , fontSize: 15}}
                        >
                            Admin
                            {auth?.secondaryUser.name && (
                                ` | ${auth?.secondaryUser.name}`
                            )}
                        </Typography>

                        <Button
                            color="inherit"
                            variant="outlined"
                            onClick={handleExitUser}
                            style={{marginLeft: "20px", fontSize: 13, padding: 0, border: 0}}
                        >Cerrar Sesión de {auth?.primaryUser?.name}</Button>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}
