import * as React from 'react';
import Header from "./Header";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {apiListAccesiries} from "../../../../services/User/accessories";
import NoData from "../../../../components/User/NoData";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {Checkbox, Divider, FormGroup, List, ListItem} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Accessories(props) {
    const {
        showMenu,
        accessories,
        setAccessories,
        kitchenElements,
        kitchenElementsSecondarySide
    } = props

    const {t, i18n} = useTranslation()
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        setAccessories([])
        getData()
    }, [showMenu, kitchenElements, kitchenElementsSecondarySide])

    const getData = () => {
        setLoading(true)
        apiListAccesiries({
            kitchenElements: kitchenElements,
            kitchenElementsSecondarySide: kitchenElementsSecondarySide,
        }, i18n.resolvedLanguage).then(resp => {
            setAccessories(resp.data)
            setLoading(false)
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    return (
        <div style={{maxWidth: 400}}>
            {showMenu === 'ACCESSORIES' && (
                <>
                    <Header title={t(`composer.Accessories`)}/>

                    <NoData show={(accessories.length == 0 && !loading)}/>
                    <LoadingSpinner show={loading}/>

                    {(!loading && accessories.length > 0) && (
                        <List>
                            <ListItem style={{width: '100%', display: 'flex', fontFamily: 'AdminFontBold'}}>
                                <span style={{width: '60%', fontSize: 14}}>Code</span>
                                <span style={{
                                    width: '40%',
                                    fontSize: 14,
                                    textAlign: 'right'
                                }}>{t('general.Quantity')}</span>
                            </ListItem>
                            <Divider component="li"/>
                            {accessories.map((item, index) => (
                                <span key={index}>
                                 <ListItem style={{
                                     width: '100%',
                                     display: 'flex',
                                 }}>
                                    <span style={{width: '60%', fontSize: 14}}>{item?.description}</span>
                                    <span style={{width: '40%', textAlign: 'right'}}>
                                        <span>{item.quantity}</span>
                                    </span>
                                </ListItem>

                                <Divider component="li"/>
                            </span>
                            ))}

                        </List>
                    )}

                </>
            )}
        </div>
    )
}
