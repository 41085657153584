import React, {useEffect} from "react";
import useAuth from "../../../hooks/useAuth";

export default function Logout() {
    const {logout} = useAuth()

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        logout({
            msgError: params.get("msg-error")
        })
    }, [])


    return (<></>)
}

