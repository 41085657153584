import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Inputs/InputText";
import InputSelect from "../../../../../components/Inputs/InputSelect";
import {apiList as apiListCategory} from "../../../../../services/User/category";
import InputSwitch from "../../../../../components/Inputs/InputSwitch";
import {useTranslation} from "react-i18next";
import {optionsCompany} from "../../../../../utils/optionsSelectors/optionsCompany";
import {optionsType} from "../../../../../utils/optionsSelectors/optionsType";
import {optionsSerie} from "../../../../../utils/optionsSelectors/optionsSerie";
import InputCheckbox from "../../../../../components/Inputs/InputCheckbox";

export default function Filters(props) {

    const {
        inpoutDisabled,
        state,
        setState,
    } = props
    const [optionsCategory, setOptionsCategory] = React.useState([])
    const {i18n} = useTranslation()

    useEffect(() => {
        getCategoryList()
    }, [state.type])

    const getCategoryList = () => {
        apiListCategory({
            type: state.type
        }, i18n.resolvedLanguage).then(resp => {
            let temp = resp.data?.map(doc => {
                return {...doc}
            })
            setOptionsCategory(temp)
        }).catch(err => {
            setOptionsCategory([])
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        state["offset"] = 0
        setState({...state})
    }

    return (
        <>
            <Grid item xs={12}>
                <InputText
                    disabled={inpoutDisabled}
                    label="Code"
                    placeholder="code1, code2, code3, ..."
                    name="code"
                    value={state.code}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputSelect
                    disabled={inpoutDisabled}
                    label="Brand"
                    name="company"
                    onChange={handleChange}
                    value={state?.company}
                    options={optionsCompany}
                />
            </Grid>

            <Grid item xs={4}>
                <InputSelect
                    disabled={inpoutDisabled}
                    label="Type"
                    name="type"
                    onChange={handleChange}
                    value={state?.type}
                    options={optionsType}
                />
            </Grid>

            <Grid item xs={4}>
                <InputSelect
                    disabled={inpoutDisabled}
                    label="Category"
                    name="category"
                    onChange={handleChange}
                    value={state?.category}
                    options={optionsCategory}
                />
            </Grid>

            <Grid item xs={4}>
                <InputText
                    disabled={inpoutDisabled}
                    label={`Description ( ${i18n.resolvedLanguage} )`}
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={4}>
                <InputSelect
                    disabled={inpoutDisabled}
                    label="Serie"
                    name="serie"
                    onChange={handleChange}
                    value={state?.serie}
                    options={optionsSerie}
                />
            </Grid>

            <Grid item xs={4}>
                <div style={{float: 'right'}}>
                    <InputCheckbox
                        label="is Active"
                        name="isActive"
                        checked={state?.isActive}
                        onChange={(e) => {
                            state.isActive = e.target.checked
                            setState({...state})
                        }}
                    />
                </div>
            </Grid>


        </>
    )
}
