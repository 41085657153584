import {BASE_PATH, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_USER}/incompatibility`;

export function apiCheck(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/check`, {productsCodes: props.productsCodes, kitchenId: props.kitchenId}, config)
}

export function apiCheckUpper(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/check-upper`, props, config)
}
