import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de contraseña
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputPassword(props) {

    const {label, name, value, onChange, required} = props

    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    return (
        <TextField
            type="password"
            variant="outlined"
            className="form-control"
            size="small"
            required={required}
            fullWidth
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{
                autoComplete: "autocomplete=\"ÑÖcompletes\"",
                form: {
                    autoComplete: "off",
                },
                style: {
                    padding: '7px 12px',
                    fontSize: '14px',
                }
            }}
            sx={{
                '& label.Mui-focused': {
                    color: color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: color,
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                        borderColor: color,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: color,
                    },
                },
            }}
        />
    )
}
