import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import {VscChromeClose} from "react-icons/vsc";
import CatalogueView from "./CatalogueView";

export default function ModalCatalogue(props) {
    const {
        title,
        open,
        setOpen,
        catalogueProducts,
        setCatalogueProducts,
        showCart
    } = props

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter:' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>{title}</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>

                        <CatalogueView
                            showCart={showCart ? showCart : false}
                            isModal={true}
                            product={catalogueProducts}
                            setProduct={setCatalogueProducts}
                        />
                    </Grid>
                </Box>
            </Modal>


        </div>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    padding: '20px 10px 30px 30px',
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
