import React, {useEffect} from "react";
import {Divider, IconButton, List, ListItem} from "@mui/material";
import {VscSettings} from "react-icons/vsc";
import NoData from "../../../components/User/NoData";
import {useTranslation} from "react-i18next";

export default function RightBar(props) {

    const {
        product,
        handleClickCode,
        handleChangeCell,
        editable
    } = props

    const {t} = useTranslation()

    const [includeOptions, setIncludeOptions] = React.useState(false)
    const [includeProducts, setIncludeProducts] = React.useState(false)

    useEffect(() => {
        setIncludeOptions(false)
        setIncludeProducts(false)
        if (product.length > 0) {
            product.map((item, index) => {
                if (item.typeQuery === 'options') {
                    setIncludeOptions(true)
                }
            })
        }

        if (product.length > 0) {
            product.map((item, index) => {
                if (item.typeQuery === 'products') {
                    setIncludeProducts(true)
                }
            })
        }
    }, [product])

    return (
        <List>
            <ListItem style={{width: '100%', display: 'flex', fontFamily: 'AdminFontBold'}}>
                <span style={{width: '60%', fontSize: 14}}>Code</span>
                <span style={{
                    width: editable ? '20%' : '40%',
                    fontSize: 14,
                    textAlign: editable ? 'left' : 'right'
                }}>{t('general.Quantity')}</span>
                {editable && (<span style={{width: '20%', fontSize: 14, textAlign: 'right'}}>{t('general.Options')}</span>)}
            </ListItem>
            <Divider component="li"/>

            {includeProducts && (
                <>
                    <ListItem style={{
                        width: '100%',
                        display: 'flex',
                        background: '#f0edeb'
                    }}>
                        {t('general.Products')}
                    </ListItem>
                    <Divider component="li"/>
                </>
            )}

            {product.map((item, index) => (
                <>
                    {item.typeQuery === 'products' && (
                        <span key={index}>
                             <ListElement
                                 item={item}
                                 handleClickCode={handleClickCode}
                                 handleChangeCell={handleChangeCell}
                                 editable={editable}
                             />
                        </span>
                    )}
                </>
            ))}

            {includeOptions && (
                <>
                    <Divider
                        component="li"
                        style={{
                            marginTop: 40,
                        }}
                    />
                    <ListItem style={{
                        width: '100%',
                        display: 'flex',
                        background: '#f0edeb'
                    }}>
                        Options
                    </ListItem>
                    <Divider component="li"/>
                </>
            )}

            {product.map((item, index) => (
                <>
                    {item.typeQuery === 'options' && (
                        <span key={index}>
                            <ListElement
                                item={item}
                                handleClickCode={handleClickCode}
                                handleChangeCell={handleChangeCell}
                                editable={editable}
                            />
                        </span>
                    )}
                </>
            ))}

            <NoData show={(product.length === 0)}/>

        </List>
    )
}

function ListElement(props) {

    const {
        item,
        handleClickCode,
        handleChangeCell,
        editable
    } = props

    return (
        <>
            <ListItem style={{
                width: '100%',
                display: 'flex',
            }}>
                <span style={{width: '60%', fontSize: 14}}>{item?.code}</span>
                <span style={{width: editable ? '20%' : '40%', textAlign: 'right'}}>
                {editable ? (
                    <input
                        type="number"
                        id={item.id}
                        value={item.quantity}
                        onChange={(e) => {
                            handleChangeCell(e, item)
                        }}
                        style={{
                            width: "100%",
                            padding: '5px 10px'
                        }}
                    />
                ) : (
                    <span>{item.quantity}</span>
                )}
            </span>

                {editable && (
                    <span style={{width: '20%', textAlign: 'right'}}>
                        {item.typeQuery === 'products' && (
                            <IconButton
                                size="small"
                                onClick={() => handleClickCode(`${item?.element?.code}`)}
                            >
                                <VscSettings fontSize="inherit" size="25"/>
                            </IconButton>
                        )}
                    </span>
                )}
            </ListItem>

            <Divider component="li"/>
        </>
    )
}
