import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/export`;

export function apiExport(typeId, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/by-type/${typeId}`, config)
}

export function apiListFile(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    let query = "?1=1";
    if (props.type) {
        query += `&type=${props.type}`;
    }
    if (props.format) {
        query += `&format=${props.format}`;
    }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list-file${query}`, config)
}

export function apiDeleteFile(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    let query = "?1=1";
    if (props.type) {
        query += `&type=${props.type}`;
    }
    if (props.format) {
        query += `&format=${props.format}`;
    }
    if (props.fileName) {
        query += `&fileName=${props.fileName}`;
    }

    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/delete-file${query}`, config)
}
