import * as React from 'react';
import Grid from '@mui/material/Grid';
import MenuAccount from './MenuAccount'
import MenuHeaderLeft from './MenuHeaderLeft';
import MenuHeaderRight from './MenuHeaderRight';
import MenuLeft from './MenuLeft';
import logo from '../../../assets/images/logo-scaled.png'
import "../../../scss/Admin/index.scss"
import MenuLanguage from "./MenuLanguage";

export default function Basic(props){

    const {menu, menuChild} = props;

    return(
        <>
            <div id="admin-header">
                <div className="first-menu">
                    <Grid container className="screen-max-admin">
                        <Grid item xs={8} sx={{
                            paddingBottom: "8px"
                        }}>
                            <img id="logo" src={logo} />
                        </Grid>
                        <Grid item xs={4} sx={{
                            paddingBottom: "8px"
                        }}>
                            <MenuAccount />
                            <MenuLanguage />
                        </Grid>
                    </Grid>
                </div>
                <div className="second-menu">
                    <Grid container className="screen-max-admin">
                        <Grid item xs={6}>
                            <MenuHeaderLeft
                                menu={menu}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MenuHeaderRight
                                menu={menu}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            
            <div id="admin-content" style={{padding: 20}}>
                <Grid container className="screen-max-admin">
                    <Grid item xs={menuChild !== "" ? 2 : 0}>
                        <MenuLeft
                            menu={menu}
                            menuChild={menuChild}
                        />
                    </Grid>

                    <Grid item xs={menuChild === "" ? 12 : 10} style={{marginBottom: 80}}>
                        {props.children}
                    </Grid>
                </Grid>
            </div>

        </>
    )
}
