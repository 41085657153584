import React from "react";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";

export default function MenuHeaderLeft(props) {
    const {t} = useTranslation()
    const {menu} = props

    return (
        <>
            <MenuLink type="log" menu={menu} to={RouteAdmin.LOG_REPORT.path} name="Logs"/>
            <MenuLink type="doc" menu={menu} to={RouteAdmin.DOC_INSTALL.path} name="Doc"/>
        </>
    )
}

function MenuLink(props) {
    const {to, name, menu, type} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }

    return (
        <>
            <div style={{marginLeft: '25px', float: 'right'}}>
                <Link
                    to={to}
                    className={isActive ? 'active' : ''}
                >
                    {name}
                </Link>
            </div>
        </>
    )
}
