import useAuth from "../../hooks/useAuth";
import {useEffect} from "react";
import {hasExpiredToken} from "../../services/Auth/token";
import {RouteAuth} from "../../routes/RouteAuth";
import {useNavigate} from "react-router-dom";

export default function CheckRole(props) {

    const {roles} = props
    const navigate = useNavigate()
    const {auth} = useAuth()

    useEffect(() => {
        if (roles) {
            if (auth && hasExpiredToken()) {
                navigate(RouteAuth.LOGOUT.path)
            }
            if (!roles.includes("ROLE_PUBLIC")) {
                if (!roles.includes(auth?.primaryUser?.role) && !roles.includes(auth?.secondaryUser?.role)) {
                    navigate(RouteAuth.LOGOUT.path)
                }
            }

        }
    })

    return(<></>)
}
