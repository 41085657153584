import React, {useEffect, useState} from "react";
import {apiList, apiGetUrlTransaction} from "../../../services/User/transaction";
import {Button, Grid, IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import Table from "../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../utils/constants";
import InputText from "../../../components/Inputs/InputText";
import InputSelect from "../../../components/Inputs/InputSelect";
import {useTranslation} from "react-i18next";
import {HiFilter, HiOutlineFilter} from "react-icons/hi";
import DatePickerSimple from "../../../components/Inputs/DatePickerSimple";
import moment from "moment/moment";
import {BsFileEarmarkSpreadsheet, BsFileEarmarkText} from "react-icons/bs";
import currencyFormatterPrice from "../../../utils/currencyFormatterPrice";
import downloaderFile from "../../../utils/downloaderFile";
import {optionsSerie} from "../../../utils/optionsSelectors/optionsSerie";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [filter, setFilter] = useState(false)
    const {t, i18n} = useTranslation()

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        let params = {
            limit: 20,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.code) {
            params.code = state.code
        }
        if (state.serie) {
            params.serie = state.serie
        }
        if (state.dateFrom) {
            params.dateFrom = state.dateFrom
        }
        if (state.dateTo) {
            params.dateTo = state.dateTo
        }

        apiList(params, i18n.resolvedLanguage).then(resp => {
            setRowCount(resp.data?.totalItems)

            const moment1 = moment(new Date().getTime());

            let temp = resp.data.items?.map(doc => {
                let dateQuotation = moment.unix(doc.dateQuotation).format("D/M/Y HH:mm")
                const moment2 = moment(doc.dateQuotation * 1000);
                if (moment1.isSame(moment2, 'day') && moment1.isSame(moment2, 'month') && moment1.isSame(moment2, 'year')) {
                    dateQuotation = moment.unix(doc.dateQuotation).format("HH:mm")
                }

                return {
                    ...doc,
                    dateQuotation: dateQuotation,
                    price: currencyFormatterPrice(doc.price) + ' €',
                    discountRosinox: doc.discountRosinox ? doc.discountRosinox  + ' %' : '-',
                    discountFriginox: doc.discountFriginox ? doc.discountFriginox  + ' %' : '-',
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
            setRowCount(0)
        })
    }

    const getUrlTransaction = (id, type) => {
        apiGetUrlTransaction(id, type).then(resp => {
            downloaderFile(resp.data?.url, resp.data?.name)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <>

            <Grid item md={10}>
                <h1 style={{marginTop: 20}}>{t('quotation.Quotation')}</h1>
            </Grid>

            <Grid item md={2}>
                <Button
                    variant="outlined"
                    endIcon={filter ? <HiFilter/> : <HiOutlineFilter/>}
                    size={"small"}
                    style={{marginTop: 22, width: '100%'}}
                    color="error"
                    onClick={() => setFilter(!filter)}
                >
                    {t('general.Filter')}
                </Button>
            </Grid>

            <Grid item md={12} style={{display: filter ? 'inline' : 'none'}}>
                <Filters
                    state={state}
                    setState={setState}
                />
            </Grid>

            <Grid item md={12}>
                <Table
                    columns={tableColumns(t, getUrlTransaction)}
                    rows={rows}
                    rowCount={rowCount}
                    filter={state}
                    setFilter={setState}
                    loading={loading}
                    limit={20}
                />
            </Grid>
        </>
    );
}

function initialValues() {
    return {
        offset: 0,
        sort: {field: "code", sort: "desc"},
        code: "",
        serie: "",
        dateFrom: "",
        dateTo: "",
    }
}

function tableColumns(t, getUrlTransaction) {
    return [
        {
            field: "code",
            headerName: t('quotation.Quotation Code'),
            sortable: true,
            flex: 1
        },
        {
            field: "text",
            headerName: t('quotation.Quotation Name'),
            sortable: true,
            flex: 1
        },
        {
            field: "companyName",
            headerName: t('quotation.Company Name'),
            sortable: true,
            flex: 1
        },
        {
            field: "dateQuotation",
            headerName: t('quotation.Date'),
            sortable: true,
            flex: 1
        },
        {
            field: "price",
            headerName: t('quotation.Total Public Price'),
            sortable: true,
            flex: 1
        },
        {
            field: "discountRosinox",
            headerName: t('quotation.Discount Rosinox'),
            sortable: true,
            flex: 1
        },
        {
            field: "discountFriginox",
            headerName: t('quotation.Discount Friginox'),
            sortable: true,
            flex: 1
        },
        // {
        //     field: "price",
        //     headerName: t('quotation.Total Général H.T.'),
        //     sortable: true,
        //     flex: 1
        // },
        {
            field: "actions",
            headerName: t('general.actions'),
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params?.filePdf && (
                            <Tooltip title="PDF" arrow>
                                <IconButton size="small" onClick={() => getUrlTransaction(params?.id, 'pdf')}>
                                    <BsFileEarmarkText fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        )}

                        {params?.fileExcel && (
                            <Tooltip title="Excel" arrow>
                                <IconButton size="small" onClick={() => getUrlTransaction(params?.id, 'xlsx')}>
                                    <BsFileEarmarkSpreadsheet fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={6}>
                <InputText
                    label="Code"
                    name="code"
                    value={state.code}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputSelect
                    label={t('general.Serie')}
                    name="serie"
                    onChange={handleChange}
                    value={state?.serie}
                    options={optionsSerie}
                />
            </Grid>

            <Grid item xs={6}>
                <DatePickerSimple
                    label={t('general.Date from')}
                    name="dateFrom"
                    value={state?.dateFrom}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <DatePickerSimple
                    label={t('general.Date to')}
                    name="dateTo"
                    value={state?.dateTo}
                    onChange={handleChange}
                />
            </Grid>

        </Grid>
    )
}
