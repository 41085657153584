import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fr from './translations/fr.json'
import en from './translations/en.json'

i18n
    // Detect user language
    .use(LanguageDetector)
    // Pass the instance i18n to react react-i18n
    .use(initReactI18next)
    // init i18n
    .init({
        debug: true,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            fr: {
                translation: fr
            },
            en: {
                translation: en
            }
        }
    });

export default i18n;
