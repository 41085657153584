import * as React from 'react';
import Header from "./Header";
import {useEffect} from "react";
import {apiListOptions} from "../../../../services/User/options";
import {useTranslation} from "react-i18next";
import {Checkbox, Divider, FormGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import NoData from "../../../../components/User/NoData";
import LoadingSpinner from "../../../../components/LoadingSpinner";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Options(props) {
    const {
        showMenu,
        kitchenElements,
        setKitchenElements,
        elementSelected
    } = props

    const {t, i18n} = useTranslation()
    const [options, setOptions] = React.useState([])
    const [optionsUpper, setOptionsUpper] = React.useState([])
    const [optionsLower, setOptionsLower] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    

    useEffect(() => {
        setOptions([])
        setOptionsUpper([])
        setOptionsLower([])
        if (elementSelected != null && showMenu == 'INFO-OPTIONS') {
            getData()
        }
    }, [elementSelected, showMenu])

    const getData = () => {
        setLoading(true)
        const element = kitchenElements.find(element => element.id === elementSelected)

        let upperCode = element?.upper.map(item => item?.productCode) || []
        let lowerCode = element?.lower.map(item => item?.productCode) || []

        let code = [...upperCode, ...lowerCode]

        let productCode = new Set(code);
        productCode = [...productCode];

        // array to string
        productCode = productCode.join(',')

        getOptions(productCode)
    }

    const getOptions = (code) => {
        apiListOptions({
            productCode: code,
            isActive: true
        }, 'post', i18n.resolvedLanguage).then(resp => {

            let temp = resp.data.items?.map(doc => {

                let checked = false
                let finalQuantity=0
                // Buscamos en KitchenElements si existe el codigo del opcional
                let element = kitchenElements.find(element => element.id === elementSelected)
                if (element) {
                    element.options.forEach(option => {
                        if (option.code === doc.code) {
                            checked = option.checked
                            finalQuantity = option.finalQuantity
                        }
                    })
                }

                let optionsCodeIncompatible = []
                if (doc.optionsCodeIncompatible) {
                    optionsCodeIncompatible = doc.optionsCodeIncompatible.split(',')
                }

                let optionsCodeRequiered = []
                if (doc.optionsCodeRequiered) {
                    optionsCodeRequiered = doc.optionsCodeRequiered.split(',')
                }

                return {
                    ...doc,
                    checked: checked,
                    finalQuantity: finalQuantity,
                    optionsCodeIncompatible: optionsCodeIncompatible,
                    optionsCodeRequiered: optionsCodeRequiered
                }
            }) ?? []

            // Buscamos de los elementos que estan checkeados los que son incompatibles con el seleccionado
            let optionsCodeIncompatible = []
            let optionsCodeRequiered = []
            temp.forEach(option => {
                if (option.checked) {
                    optionsCodeIncompatible = [...optionsCodeIncompatible, ...option.optionsCodeIncompatible]
                    optionsCodeRequiered = [...optionsCodeRequiered, ...option.optionsCodeRequiered]
                }
            })

            // Incompatibles (disabled)
            // Los que estan dentro del array de incompatibles los deshabilitamos
            temp = temp.map(option => {
                let disabled = false
                let checked = option.checked
                if (optionsCodeIncompatible.includes(option.code)) {
                    disabled = true
                    checked = false
                }

                return {
                    ...option,
                    disabled: disabled,
                    checked: checked
                }
            })
            // END Incompatibles (disabled)

            // Requeridos (checked)
            // Los que están dentro del array de requeridos los habilitamos
            temp = temp.map(option => {
                let disabled = option.disabled
                let checked = option.checked
                let required = false
                if (optionsCodeRequiered.includes(option.code)) {
                    disabled = false
                    checked = true
                    required = true
                }

                return {
                    ...option,
                    disabled: disabled,
                    checked: checked,
                    required: required
                }
            })

            setOptions(temp)

            setLoading(false)
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    const handleClick = async (codeSelected) => {
        if (codeSelected) {

            // Marcamos el check actual
            let selected = {}
            let temp = options.map(option => {
                let checked = option.checked
                if (option.code === codeSelected) {
                    checked = !option.checked
                    selected = {
                        ...option,
                        checked: checked,
                    }
                }
                return {
                    ...option,
                    checked: checked,
                    finalQuantity: checked? option.finalQuantity || 1 : 0
                }
            })

            // Buscamos de los elementos que estan checkeados los que son incompatibles con el seleccionado
            let optionsCodeIncompatible = []
            let optionsCodeRequiered = []
            temp.forEach(option => {
                if (option.checked) {
                    optionsCodeIncompatible = [...optionsCodeIncompatible, ...option.optionsCodeIncompatible]
                    optionsCodeRequiered = [...optionsCodeRequiered, ...option.optionsCodeRequiered]
                }
            })

            // Incompatibles (disabled)
            // Los que estan dentro del array de incompatibles los deshabilitamos
            temp = temp.map(option => {
                let disabled = false
                let checked = option.checked

                if (optionsCodeIncompatible.includes(option.code)) {
                    disabled = true
                    checked = false
                }

                return {
                    ...option,
                    disabled: disabled,
                    checked: checked
                }
            })
            // END Incompatibles (disabled)

            // Requeridos (checked)
            // Los que están dentro del array de requeridos los habilitamos
            temp = temp.map(option => {
                let disabled = option.disabled
                let checked = option.checked
                let required = false
                

                if (optionsCodeRequiered.includes(option.code)) {
                    disabled = false
                    checked = true
                    required = true
                   
                }

                return {
                    ...option,
                    disabled: disabled,
                    checked: checked,
                    required: required,
                }
            })
            setOptions(temp)
            // END Requeridos (checked)

            save(temp)

        }
    }

    const handleClickQuantity = async (e,codeSelected) => {


        if (codeSelected && e.target.value > 0) {

             // Cambiamos la cantidad actual
            
             let temp = options.map(option => {
                 let finalQuantity
                 if (option.code === codeSelected) {
                    
                    finalQuantity = + e.target.value
                    if(finalQuantity > + option.quantity){ finalQuantity = option.quantity}
                    else if (finalQuantity == 0) {finalQuantity=1}
                     
                 }
                 return {
                     ...option,
                     finalQuantity: finalQuantity,
                 }
             })

             setOptions(temp)
             save(temp)
        }
    }

    // Guaramos los cambios
    const save = (optionsSave) => {
        let temp = optionsSave.filter(option => option.checked)
        let tempKitchenElements = kitchenElements.map(element => {
            if (element.id === elementSelected) {
                return {
                    ...element,
                    options: temp
                }
            }
            return element
        })
        setKitchenElements(tempKitchenElements)
    }

    return (
        <div style={{maxWidth: 400}}>
            {showMenu === 'INFO-OPTIONS' && (
                <>
                    <Header title={t('catalogue.Options')}/>

                    <div className="content">

                        <NoData show={(options.length == 0 && !loading)}/>
                        <LoadingSpinner show={loading}/>

                        {options.map((option, index) => {
                            return (
                                <div key={index}>
                                    {option.description && (
                                        <>
                                            <div className="inputCheckbox">
                                                <label className="container" style={{color: option.disabled ? '#c7c7c7' : 'black'}}>
                                                    {option.description}
                                                    <input
                                                        type="checkbox"
                                                        name="topping"
                                                        checked={option.checked}
                                                        value={option.code}
                                                        disabled={option.disabled}
                                                        onClick={(e) => handleClick(`${option.code}`)}
                                                    />
                                                {(option.quantity > 1  && option.checked)  && (
                                                    <span>
                                                        <input
                                                        type="number"
                                                        name="qqtopping"
                                                        value={option.finalQuantity}
                                                        //min={1}
                                                        max={option.quantity}
                                                        step={1}
                                                        disabled={option.disabled}
                                                        onChange={(e) => handleClickQuantity(e,`${option.code}`)}
                                                        style={{marginLeft:'8px', opacity: 1, height: 'auto', width: '50px'}}
                                                        />
                                                    </span>
                                                )}
                                                    <span className="checkmark" style={{background: option.required ? '#e7bcc0' : ''}}></span>
                                                </label>
                                            </div>
                                            <Divider/>
                                        </>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}
