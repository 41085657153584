import * as React from 'react';
import Header from "./Header";
import ModalCatalogue from "../../Catalogue/ModalCatalogue";
import {Button, Divider, ListItem} from "@mui/material";
import NoData from "../../../../components/User/NoData";
import RightBar from "../../Catalogue/RightBar";
import SimpleListProducts from "./SimpleListProducts";
import {useTranslation} from "react-i18next";

export default function Catalogue(props) {
    const {
        showMenu,
        catalogueProducts,
        setCatalogueProducts
    } = props

    const {t} = useTranslation()

    const [modalOpen, setModalOpen] = React.useState(false)
    const [showCart, setShowCart] = React.useState(false)

    return (
        <div style={{maxWidth: 400}}>
            {showMenu === 'CATALOGUE' && (
                <>
                    <Header title={t('catalogue.Catalogue')}>
                        <button
                            className="btn-panel"
                            style={{width: '100%'}}
                            onClick={() => {
                                setModalOpen(true)
                                setShowCart(false)
                            }}
                        >
                            + {t('general.Add')}
                        </button>
                    </Header>

                    <div style={{display: 'block', marginBottom: 70}}>
                        <RightBar
                            product={catalogueProducts}
                            editable={false}
                        />
                    </div>

                    <div style={{padding: 20, position: 'absolute', bottom: 0, width: 360, background: '#f6f7f9'}}>
                        <Button
                            variant="outlined"
                            style={{width: '100%'}}
                            color="error"
                            size={'small'}
                            onClick={() => {
                                setModalOpen(true)
                                setShowCart(true)
                            }}
                        >
                            {t('general.Show details')}
                        </Button>
                    </div>

                </>
            )}

            <ModalCatalogue
                title={t('catalogue.Catalogue')}
                showCart={showCart}
                open={modalOpen}
                setOpen={setModalOpen}
                catalogueProducts={catalogueProducts}
                setCatalogueProducts={setCatalogueProducts}
            />
        </div>
    )
}
