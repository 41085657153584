import {BASE_PATH, BASE_PATH_EXTENSION_USER} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_USER}/composer`;

export function apiSave(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save`, props, config)
}

export function apiGetComposer(serie, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-composer/${serie}`, config)
}
