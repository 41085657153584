import React, {useState, useEffect, useRef} from "react";
import DndElements from "../../../components/DndElements";
import EditorText from "../../../components/EditorText";
import html2canvas from 'html2canvas';
// https://blog.logrocket.com/export-react-components-as-images-html2canvas/
export default function Testing() {
    const [text, setText] = useState(textInit)
    const [elements, setElementDnd] = useState(elementsInit)

    const exportAsImage = async (element, imageFileName) => {
        const html = document.getElementsByTagName("html")[0];
        const body = document.getElementsByTagName("body")[0];
        let htmlWidth = html.clientWidth;
        let bodyWidth = body.clientWidth;
        const newWidth = element.scrollWidth - element.clientWidth;
        if (newWidth > element.clientWidth) {
            htmlWidth += newWidth;
            bodyWidth += newWidth;
        }
        html.style.width = htmlWidth + "px";
        body.style.width = bodyWidth + "px";
        const canvas = await html2canvas(element, {
            logging: true,
            letterRendering: 1,
            allowTaint: false,
            useCORS: true,
            scale: '1',
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight
        });
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
        html.style.width = null;
        body.style.width = null;
    }

        const downloadImage = (blob, fileName) => {
            const fakeLink = window.document.createElement("a");
            fakeLink.style = "display:none;";
            fakeLink.download = fileName;

            fakeLink.href = blob;

            document.body.appendChild(fakeLink);
            fakeLink.click();
            document.body.removeChild(fakeLink);

            fakeLink.remove();
        };

        const exportRef = useRef();

        return (
            <div style={{padding: "20px"}}>
                <h1>TESTING</h1>
                <button type="button" onClick={() => exportAsImage(exportRef.current, "test")}>aaa</button>

                <div ref={exportRef} style={{overflowX: 'scroll', width: 400, display: 'flex'}}>
                {/*<div ref={exportRef}>*/}
                    a
                    <img src="https://picsum.photos/id/237/200/300" style={{height: "100%", width: "200px"}}/>
                    <input type="text"/>
                    <img src="https://picsum.photos/id/237/200/300" style={{height: "100%", width: "200px"}}/>
                    <img src="https://picsum.photos/id/237/200/300" style={{height: "100%", width: "200px"}}/>
                    <img src="https://picsum.photos/id/237/200/300" style={{height: "100%", width: "200px"}}/>
                    b
                </div>
                {/*<h2>DnD - Drag and Drop</h2>*/}
                {/*<DndElements*/}
                {/*    elementDnd={elements}*/}
                {/*    setElementDnd={setElementDnd}*/}
                {/*/>*/}

                {/*<h2>Editor</h2>*/}
                {/*<EditorText*/}
                {/*    content={text}*/}
                {/*    setContent={setText}*/}
                {/*/>*/}

            </div>
        )
    }

    const textInit = `<p>Esto es el texto del editor</p>
<img src="https://source.unsplash.com/8xznAGy4HcY/800x400" />
`

    const elementsInit = [
        {
            id: "1",
            content: <img src="https://picsum.photos/id/237/200/300" style={{height: "100%", width: "200px"}}/>
        },
        {
            id: "2",
            content: <img src="https://picsum.photos/seed/picsum/200/300" style={{height: "100%", width: "200px"}}/>
        },
        {
            id: "3",
            content: <img src="https://picsum.photos/200/300?grayscale" style={{height: "100%", width: "200px"}}/>
        }
    ]
