import React from "react";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";

export default function MenuHeaderLeft(props) {
    const {t} = useTranslation()
    const {menu} = props

    return (
        <>
            <MenuLink type="user" menu={menu} to={RouteAdmin.USER_MANAGEMENT.path} name="Users"/>
            <MenuLink type="product" menu={menu} to={RouteAdmin.PRODUCT_MANAGEMENT.path} name="Products"/>
            {/*<MenuLink type="accessories" menu={menu} to={RouteAdmin.ACCESSORIES_MANAGEMENT.path} name="Accessories"/>*/}
            <MenuLink type="transaction" menu={menu} to={RouteAdmin.TRANSACTION_MANAGEMENT.path} name="Quotation"/>
            <MenuLink type="import" menu={menu} to={RouteAdmin.IMPORT_INDEX.path} name="Import"/>
        </>
    )
}

function MenuLink(props) {
    const {to, name, menu, type} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }

    return (
        <>
            <div style={{marginRight: '25px'}}>
                <Link
                    to={to}
                    className={isActive ? 'active' : ''}
                >
                    {name}
                </Link>
            </div>
        </>
    )
}
